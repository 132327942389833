import React from 'react';
import FoodItems from './fooditems';
import { DealsFooter } from './footer';
import TopMenu from './topmenu';

function Deals() {
  return (
    <>
      <TopMenu />
      <FoodItems />
      <DealsFooter />
    </>
  );
}

export default Deals;

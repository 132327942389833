import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { RenderIf } from 'helpers';
import { ThemeTabs } from 'components/tabs';
import { NutritionFacts } from 'components/nutritional';
import styles from './index.module.css';
import { Client } from 'db';
import { useCart } from 'react-use-cart';
import { PrimaryButton } from 'components/buttons/primary';
import { Toasts } from 'components/toasts';
import { ModalComponent } from 'components/modal';
import { SecondaryButton } from 'components/buttons/secondary';
import { RestaurantStore } from 'store';
import { OrderStore } from 'store';
import { Icon } from 'assets/icons';

const calculatePercentage = (value, percentage) => {
  return (value * percentage) / 100;
};

export const MenuDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation().state;

  const branch = location?.branch;
  const item = location?.item;
  const parentBranch = location?.parentBranch;

  const { restaurantData } = RestaurantStore((state) => state);
  const primary = restaurantData?.theme?.global?.style?.button?.primary;
  const { deliveryType } = OrderStore((state) => state);
  const [menuItem, setMenuItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedModifier, setSelectedModifier] = useState([]);
  const [selectedItemCost, setSelectedItemCost] = useState(0);
  const [selectedItemQuantity, setSelectedItemQuantity] = useState(1);
  const { items, addItem, updateItemQuantity } = useCart();

  useEffect(() => {
    getMenuData();
  }, [branch]);

  const getMenuData = async () => {
    const menuItemData = (await Client.get(`/items/getItemDetails/${id}`))?.data
      ?.itemDetails;

    // const mainCost = menuItemData?.price;
    // const modifierCost = selectedModifier?.reduce((acc, curr) => {
    //   return acc + curr.additionalPrice;
    // }, 0);
    // let cost = mainCost + modifierCost;
    // if (branch?.markup) {
    //   cost = cost + (cost * branch?.markup) / 100;
    // }

    // menuItemData.price = cost;

    setMenuItem(menuItemData);
  };

  const handleSelectModifiers = (modifier) => {
    // commenting for single modifier selection for kulchay
    // const { checked } = e.target;
    // if (checked) {
    //   setSelectedModifier([...selectedModifier, modifier]);
    // } else {
    //   setSelectedModifier(selectedModifier.filter((item) => item !== modifier));
    // }
    setSelectedModifier([modifier]);
  };

  useEffect(() => {
    if (menuItem) {
      const mainCost = menuItem?.price;
      const modifierCost = selectedModifier?.reduce((acc, curr) => {
        return acc + curr.additionalPrice;
      }, 0);
      let cost = mainCost + modifierCost;
      if (branch?.markup) {
        cost = cost + calculatePercentage(cost, branch?.markup);
      }

      setSelectedItemCost(cost);
    }
  }, [branch, menuItem, selectedModifier]);

  const handleAddToCart = () => {
    const item = {
      id: menuItem?.menuId,
      ...menuItem,
      modifiers: selectedModifier,
      cost: selectedItemCost,
      branchId: branch?._id,
      brandId: branch?.brand,
      parentBranch: parentBranch,
      orderType: deliveryType,
      markup: branch?.markup,
      branchTax: branch?.tax,
      quantity: selectedItemQuantity
    };

    const existingItem = items?.find(
      (it) => JSON.stringify(it) === JSON.stringify(item)
    );

    if (existingItem) {
      addItem(item);
      Toasts.success('Item quantity updated successfully.');
    } else {
      item.id = item?.menuId + item?.modifiers?.map((m) => m?.modifierId);
      addItem(item);
      Toasts.success('Item added in cart successfully.');
    }
  };

  const addToCartWithoutModifiers = (item, branch) => {
    console.log('🚀 ~ addToCartWithoutModifiers ~ item:', item);
    const mainCost = item?.price * selectedItemQuantity;
    let cost = mainCost;
    if (branch?.markup) {
      cost = cost + (cost * branch?.markup) / 100;
    }

    const itemObj = {
      id: item?.menuId,
      ...item,
      modifiers: [],
      cost: cost,
      branchId: branch?._id,
      brandId: branch?.brand,
      parentBranch: branch?.parentBranch,
      orderType: deliveryType,
      markup: branch?.markup,
      branchTax: branch?.branchTax,
      quantity: selectedItemQuantity
    };
    addItem(itemObj, selectedItemQuantity);
  };

  const selectedItem = (item_id) =>
    items?.filter((it) => it?._id === item_id)?.[0];

  return (
    <>
      <RenderIf isTrue={menuItem?.item}>
        <article className={styles.article}>
          <div className={`box3`}>
            <div className={styles.wrap_1}>
              <div className={styles.img_bx}>
                <picture>
                  <source srcSet={menuItem?.image} />
                  <img src={menuItem?.image} alt={menuItem?.item} />
                </picture>
              </div>
              <div className={styles.content}>
                <h2>{menuItem?.item}</h2>
                <div style={{ marginTop: 10 }}></div>
                <h4>
                  ${` `}
                  {item?.price +
                    calculatePercentage(item?.price, branch?.markup)}
                </h4>
                <RenderIf isTrue={menuItem?.description}>
                  <div style={{ marginTop: 10 }}></div>
                  <p>{menuItem?.description}</p>
                </RenderIf>
                <div style={{ marginTop: 16 }}></div>
                <label>
                  <strong>Quantity:</strong>
                </label>
                <div style={{ marginTop: 6 }}></div>
                <div className={styles.itemQuantity}>
                  <button
                    type={`button`}
                    onClick={() => {
                      updateItemQuantity(
                        selectedItem(item?._id)?.id,
                        selectedItem(item?._id)?.quantity - 1
                      );
                    }}
                    disabled={
                      (item?.groups
                        ?.map((group) => {
                          return group?.modifiers;
                        })
                        ?.flat(1)
                        .filter((modifier) => modifier?.modifier !== 'BASE')
                        ?.length > 0
                        ? items?.reduce((a, b) => {
                            if (b?._id === item?._id) {
                              a = a + b?.quantity;
                            }
                            return a;
                          }, 0)
                        : items?.reduce((a, b) => {
                            if (b?._id === item?._id) {
                              a = a + b?.quantity;
                            }
                            return a;
                          }, 0)) > 0
                        ? false
                        : true
                    }
                    style={{
                      borderRadius: `${primary?.roundedCorners}px`
                    }}
                  >
                    <Icon name={`minus`} fillColor={`#FFFFFF`} />
                  </button>
                  <div
                    style={{
                      borderRadius: `${primary?.roundedCorners}px`
                    }}
                  >
                    {item?.groups
                      ?.map((group) => {
                        return group?.modifiers;
                      })
                      ?.flat(1)
                      .filter((modifier) => modifier?.modifier !== 'BASE')
                      ?.length > 0
                      ? items?.reduce((a, b) => {
                          if (b?._id === item?._id) {
                            a = a + b?.quantity;
                          }
                          return a;
                        }, 0)
                      : items?.reduce((a, b) => {
                          if (b?._id === item?._id) {
                            a = a + b?.quantity;
                          }
                          return a;
                        }, 0) || '0'}
                  </div>
                  <button
                    type={`button`}
                    onClick={() => {
                      let modifiers = 0;
                      let tempItem = item;
                      modifiers = tempItem?.groups?.map((group) => {
                        return group?.modifiers;
                      });
                      modifiers = modifiers?.length ? modifiers?.flat(1) : [];

                      modifiers = modifiers?.filter(
                        (modifier) => modifier?.modifier !== 'BASE'
                      );
                      if (modifiers?.length === 0) {
                        addToCartWithoutModifiers(item, branch);
                        Toasts.success('Item added in cart successfully.');
                      } else {
                        setModalIsOpen(true);
                      }
                    }}
                    style={{
                      borderRadius: `${primary?.roundedCorners}px`
                    }}
                  >
                    <Icon name={`plus`} fillColor={`#FFFFFF`} />
                  </button>
                </div>
                <div style={{ marginTop: 16 }}></div>
                {/* <RenderIf isTrue={true}>
                  <PrimaryButton
                    buttonFull={true}
                    handleClick={() => {
                      handleAddToCart();
                    }}
                  >
                    Add to cart
                  </PrimaryButton>
                </RenderIf> */}
                {/* <RenderIf isTrue={!selectedItem(branches?.[0]?._id)}>
                  <PrimaryButton
                    handleClick={() => {
                      let modifiers = 0;
                      modifiers = branches?.[0]?.groups?.map((group, i) => {
                        return group?.modifiers;
                      });
                      modifiers = modifiers?.length ? modifiers?.flat(1) : [];

                      modifiers = modifiers?.filter(
                        (modifier) => modifier?.modifier !== 'BASE'
                      );

                      if (modifiers?.length === 0) {
                        addToCartWithoutModifiers(menuItem, singleBranchData);
                        Toasts.success('Item added in cart successfully.');
                      } else {
                        setSelectedItemCost(menuItem?.price);
                        setModalIsOpen(true);
                      }
                    }}
                    buttonFull={true}
                  >
                    Add to cart
                  </PrimaryButton>
                </RenderIf> */}

                <RenderIf isTrue={menuItem?.groups?.length > 0}>
                  <div style={{ marginTop: 12 }}></div>
                  <div className={styles.groups}>
                    {menuItem?.groups?.map((group) => (
                      <div className={styles.group_bx}>
                        <RenderIf isTrue={group?.groupName !== 'BASE'}>
                          <h6 className={`t_up fsemi`}>
                            {group?.groupName} Variations
                          </h6>
                        </RenderIf>
                        <div className={styles.modi_bx}>
                          <RenderIf isTrue={group?.modifiers?.length > 0}>
                            {group?.modifiers
                              ?.filter(
                                (mod) => mod?.modifier?.toLowerCase() !== 'base'
                              )
                              ?.map((modifier) => (
                                <div className={styles.modi_title}>
                                  {modifier?.modifier}
                                </div>
                              ))}
                          </RenderIf>
                        </div>
                      </div>
                    ))}
                  </div>
                </RenderIf>
                <div className={styles.more_info}>
                  <ThemeTabs
                    tabs={[
                      { label: `Nutrition Facts` },
                      { label: `Reviews (0)` }
                    ]}
                    panels={[
                      {
                        content: (
                          <NutritionFacts
                            menuItem={menuItem}
                            itemDetailPage={true}
                          />
                        )
                      },
                      { content: `Reviews` }
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      </RenderIf>
      <ModalComponent
        modal={modalIsOpen}
        handleModal={() => {
          setModalIsOpen(false);
        }}
        rightModal={false}
        modalMiddle={true}
      >
        <div className={styles.modalBx}>
          <div className={styles.modal_header}>
            <div>
              <h4>{menuItem?.item}</h4>
              <div className={`h5`} style={{ color: `var(--primary)` }}>
                <strong>$ {selectedItemCost}</strong>
              </div>
            </div>
            <div>
              <p>{menuItem?.description}</p>
            </div>
          </div>
          {menuItem?.groups
            ?.filter((g) => g?.groupName !== 'BASE')
            ?.map((group, i) => {
              return (
                <div key={i}>
                  <h5>
                    <span>{group?.groupName}</span>
                  </h5>
                  <div className={`mt_5`}></div>
                  <div className={`table1`}>
                    {group?.modifiers?.map((item, index) => {
                      return (
                        <div className={styles.addITr} key={index}>
                          <div className={styles.check_name}>
                            <input
                              name={'radio'}
                              type="radio"
                              onChange={() => {
                                handleSelectModifiers(item);
                              }}
                              defaultChecked={index === 0 ? true : false}
                            />
                            <div>
                              <div className={`h6`}>
                                <strong>{item?.modifier}</strong>
                              </div>
                              <RenderIf isTrue={item?.description}>
                                <p>{item?.description}</p>
                              </RenderIf>
                            </div>
                          </div>
                          <div>
                            <strong>
                              ${' '}
                              {parseFloat(
                                item?.additionalPrice +
                                  calculatePercentage(
                                    item?.additionalPrice,
                                    branch?.markup
                                  ) + menuItem?.price
                              )?.toFixed(2)}
                            </strong>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          <div className={styles.modal_footer}>
            <div className={`flex item_space_btn`}>
              <PrimaryButton handleClick={handleAddToCart}>
                Add to Cart(
                {items?.reduce((a, b) => a + b?.quantity, 0)})
              </PrimaryButton>
              <RenderIf isTrue={items?.length > 0}>
                <SecondaryButton
                  handleClick={() => navigate('/cart')}
                  disabled={items?.length === 0}
                >
                  Go to cart ($
                  {items
                    ?.reduce((a, b) => a + b?.quantity * b?.cost, 0)
                    ?.toFixed(0)}
                  )
                </SecondaryButton>
              </RenderIf>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

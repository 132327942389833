import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'helpers';
import { OrderStore } from 'store';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { AccordionEle } from 'components/accordion';
import styles from './index.module.css';
import { PrimaryButton } from 'components/buttons/primary';
import { SecondaryButton } from 'components/buttons/secondary';
import { RestaurantStore } from 'store';

export const HomeFeatured = () => {
  const navigate = useNavigate();
  const { setDeliveryType } = OrderStore((state) => state);
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  // const { homeFeaturedItems, homeFeaturedSection } =
  //   resData?.theme?.homeFeatured;
  // const { cloudBrands } = restaurantData?.brand?.branches[0];

  const cloudBrands = restaurantData?.brand?.branches[0]?.cloudBrands;

  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataToRender, setDataToRender] = useState(null);
  const [selectedBrandTab, setSelectedBrandTab] = useState(null);
  const [selectedTypeLink, setSelectedTypeLink] = useState('');

  useMemo(() => {
    const Object = {
      cloudBrandsInfo: cloudBrands?.map((cloudBrand) => ({
        branch: {
          ...cloudBrand?.branches?.[0]?.branch
        }
      }))
    };
    setDataToRender(Object);
    setSelectedBrandTab(Object?.cloudBrandsInfo[0]?.branch?.branchId);
    // setSelectedTypeLink(
    //   Object.cloudBrandsInfo[0].branch.categories[0].categoryTypes[0]
    //     .categoryTypeName
    // );
  }, [cloudBrands]);

  const handleNavigation = () => {
    if (restaurantData?.brand?.brandType === 'CLOUD') {
      navigate('/cloud-brand-branches');
    } else {
      navigate('/brand-orders', {
        state: {
          branches: restaurantData?.brand?.branches?.map((b) => ({
            ...b,
            markup: 0,
            parentBranch: b?._id,
            branchTax: b?.tax
          }))
        }
      });
    }
  };
  return (
    <>
      <article className={styles.article}>
        <RenderIf isTrue={restaurantData?.brand?.brandType !== 'CLOUD'}>
          <div>
            <h3 style={{ textAlign: 'center' }}>
              <span className="t_up">brand type is not cloud</span>
            </h3>
          </div>
        </RenderIf>
        <RenderIf isTrue={restaurantData?.brand?.brandType === 'CLOUD'}>
          <div className={styles.box}>
            <div className={styles.featuredContent}>
              <div className={`ofx_auto`}>
                <div
                  className={styles.brand_xwrap}
                  style={{
                    width: `calc(164px * ${
                      dataToRender?.cloudBrandsInfo?.length + 1
                    })`
                  }}
                >
                  {dataToRender?.cloudBrandsInfo?.map((cloudBrand, index) => (
                    <>
                      <button
                        key={index}
                        type={`button`}
                        className={
                          selectedBrandTab === cloudBrand?.branch?.branchId
                            ? `${styles.brand_tab} ${styles.active}`
                            : styles.brand_tab
                        }
                        onClick={() =>
                          setSelectedBrandTab(cloudBrand?.branch?.branchId)
                        }
                      >
                        <RenderIf isTrue={cloudBrand?.brand?.brandImage}>
                          <picture>
                            <source srcSet={cloudBrand?.brand?.brandImage} />
                            <img
                              src={cloudBrand?.branch?.branchImage}
                              alt={cloudBrand?.branch?.branchName}
                            />
                          </picture>
                        </RenderIf>

                        <div>
                          <span className={`fsemi`}>
                            {cloudBrand?.branch?.branchName}
                          </span>
                        </div>
                      </button>
                    </>
                  ))}
                </div>
              </div>
              <div>
                <RenderIf isTrue={selectedBrandTab}>
                  {dataToRender?.cloudBrandsInfo
                    ?.filter(
                      (cloudBrand) =>
                        cloudBrand?.branch?.branchId === selectedBrandTab
                    )
                    ?.map((cloudBrand, index) => {
                      return cloudBrand?.branch?.categories?.map(
                        (category, i) => (
                          <div key={i}>
                            <RenderIf
                              isTrue={category?.categoryTypes?.length > 0}
                            >
                              <div className={styles.scroll_links}>
                                {category?.categoryTypes?.map(
                                  (categoryType, idx) => (
                                    <div
                                      className={
                                        selectedTypeLink ===
                                        categoryType?.categoryTypeName
                                          ? `${styles.scroll_link_item} ${styles.active}`
                                          : styles.scroll_link_item
                                      }
                                      key={idx}
                                      onClick={() => {
                                        // setSelectedTypeLink(
                                        //   categoryType?.categoryTypeName
                                        // );
                                      }}
                                    >
                                      {categoryType?.categoryTypeName}
                                    </div>
                                  )
                                )}
                              </div>
                            </RenderIf>
                          </div>
                        )
                      );
                    })}
                </RenderIf>
              </div>
            </div>
            <RenderIf isTrue={selectedBrandTab}>
              {dataToRender?.cloudBrandsInfo
                ?.filter(
                  (cloudBrand) =>
                    cloudBrand?.branch?.branchId === selectedBrandTab
                )
                .map((cloudBrand, index) => (
                  <div key={index}>
                    {cloudBrand?.branch?.categories?.map((category, ind) => (
                      <div key={ind}>
                        <CategoryButton
                          category={category}
                          selectedTypeLink={selectedTypeLink}
                          setSelectedTypeLink={setSelectedTypeLink}
                        />
                      </div>
                    ))}
                  </div>
                ))}
            </RenderIf>
            <div className="mt_40"></div>
            <div className={styles.order_div}>
              <div className={styles.order_txt}>
                <h1 className="t_up">
                  <b>Order Now</b>
                </h1>
                <p>
                  <strong>Lorem ipsum dolor set ...</strong>
                </p>
              </div>
              <div className="mt_40"></div>

              <div className={styles.btn_div}>
                {/* <PrimaryButton
                  handleClick={() => {
                    setDeliveryType('delivery');
                    handleNavigation();
                  }}
                >
                  Delivery
                </PrimaryButton> */}
                <SecondaryButton
                  handleClick={() => {
                    // setDeliveryType('pickup');
                    // handleNavigation();

                    setDeliveryType('pickup');
                    // navigate('/order-type');
                    handleNavigation();
                  }}
                >
                  Pick up
                </SecondaryButton>
              </div>
            </div>
          </div>
        </RenderIf>
      </article>
    </>
  );
};

const CategoryButton = ({
  category,
  selectedTypeLink,
  setSelectedTypeLink
}) => {
  return (
    <>
      {category?.categoryTypes?.map((categoryType, index) => {
        return (
          <div key={index}>
            <div className={`mt_40`}></div>
            <AccordionEle
              title={categoryType?.categoryTypeName}
              selectedTypeLink={selectedTypeLink}
              setSelectedTypeLink={setSelectedTypeLink}
            >
              <div className={styles.grid_menu}>
                {categoryType?.items?.map((item, i) => (
                  <div key={i} className={styles.col_menu}>
                    <div className={styles.menu_item}>
                      <picture>
                        <source srcSet={item?.image} />
                        <img src={item?.image} alt={``} />
                      </picture>
                      <div className={styles.menu_item_content}>
                        <h4 className={`t_up`}>{item?.item}</h4>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <></>
              </div>
            </AccordionEle>
          </div>
        );
      })}
    </>
  );
};

import { RenderIf } from 'helpers';
import { useCart } from 'react-use-cart';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/buttons/primary';
import styles from './index.module.css';

export const YourOrder = () => {
  const navigate = useNavigate();

  const { items, removeItem } = useCart();

  return (
    <>
      <h5 className={styles.title}>YOUR ORDER</h5>
      <RenderIf isTrue={items.length > 0}>
        <div className={styles.orderBx}>
          <div className={styles.order_list}>
            {items.map((item, index) => (
              <div key={index}>
                <div className={styles.item_container}>
                  <div className={styles.item_header}>
                    <div>
                      <img
                        src={
                          item?.image ||
                          'https://zewst-backend-media-bucket.s3.amazonaws.com/95mfnpaijcf9gu3tbovq78MLNaanizza.webp'
                        }
                        alt="naanizza"
                      />
                      <div className={styles.item_header_title}>
                        {item?.item}
                      </div>
                    </div>
                    <div className={styles.item_header_price}>
                      $ {(item?.cost * item?.quantity)?.toFixed(2)}
                    </div>
                  </div>
                  <div className={styles.item_info}>
                    <div className={styles.item_info_title}>
                      Qty: <span>{item?.quantity}</span>
                    </div>
                    <div className={styles.item_info_title}>
                      Type: <span>{item?.orderType}</span>
                    </div>
                  </div>

                  {item?.modifiers?.map((mod, index) => {
                    return (
                      <div className={styles.modifiers}>
                        <div className={styles.modifiers_heading}>
                          {mod?.modifier}:
                        </div>
                        <div className={styles.modifiers_price}>
                          $ {mod?.additionalPrice} addon
                        </div>
                      </div>
                    );
                  })}

                  <div>
                    <button
                      type={`button`}
                      className={styles.remove_button}
                      onClick={() => removeItem(item?.id)}
                    >
                      remove item
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.checkout_button_container}>
          <PrimaryButton handleClick={() => navigate('/cart')}>
            Go to Checkout
          </PrimaryButton>
        </div>
      </RenderIf>
      <RenderIf isTrue={items.length === 0}>
        <div className={styles.empty_state}>
          You have not selected any food item yet!
        </div>
      </RenderIf>
    </>
  );
};

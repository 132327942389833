import axios from 'axios';
import { useEffect, useState } from 'react';
import { Client as ConversationsClient } from '@twilio/conversations';
import { RenderIf } from 'helpers';
import { Conversation } from './conversation';
import { Comment } from 'react-loader-spinner';
import { AppStore } from 'store';
import { RestaurantStore } from 'store';

export const Chat = () => {
  const { user, openChatModal, setOpenChatModal, clearMessages } = AppStore(
    (state) => state
  );
  const { restaurantData } = RestaurantStore((state) => state);

  const [conversationsClient, setConversationsClient] = useState(null);
  const [token, setToken] = useState(null);
  const [statusString, setStatusString] = useState('');
  const [status, setStatus] = useState('');
  const [conversationsReady, setConversationsReady] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [fetchedConversation, setFetchedConversation] = useState(null);

  const [loggedIn, setLoggedIn] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const initiateConversations = async () => {
    try {
      setStatus('default');
      const tokenResponse = (
        await axios.post(
          `https://zewst-chat-service-backend-dev-665efe395b86.herokuapp.com/twilio/createToken`,
          {
            customerId: user?._id
          }
        )
      )?.data;
      const myToken = tokenResponse?.token;
      setToken(myToken);

      const conversationResponse = (
        await axios.post(
          `https://zewst-chat-service-backend-dev-665efe395b86.herokuapp.com/twilio/conversationCreate`,
          {
            customerId: user?._id,
            brandId: restaurantData?.brand?._id,
            medium: 'webchat'
          }
        )
      )?.data;

      const conversationsClient = new ConversationsClient(myToken);

      setConversationsClient(conversationsClient);
      setStatusString('Connecting to Twilio…');

      conversationsClient.on('connectionStateChanged', (state) => {
        console.log('🚀 ~ conversationsClient.on ~ state:', state);
        if (state === 'connecting') {
          setStatusString('Connecting to Twilio…');
          setStatus('default');
        }

        if (state === 'connected') {
          setStatusString('You are connected.');
          setStatus('success');
        }
        if (state === 'disconnecting') {
          setStatusString('Disconnecting from Twilio…');
          setStatus('default');
          setConversationsReady(false);
        }

        if (state === 'disconnected') {
          setStatusString('Disconnected.');
          setStatus('warning');
          setConversationsReady(false);
        }

        if (state === 'denied') {
          setStatusString('Failed to connect.');
          setStatus('error');
          setConversationsReady(false);
        }
      });

      conversationsClient.on('conversationJoined', async (conversation) => {
        setConversations([...conversations, conversation]);

        const smth = await conversationsClient?.getConversationBySid(
          conversationResponse?.conversationData?.conversationSid
        );
        setFetchedConversation(smth);
      });

      conversationsClient.on('conversationLeft', (thisConversation) => {
        console.log('conversationLeft', thisConversation);
        setOpenChatModal(false);
        clearMessages();
      });
    } catch (error) {
      // console.log(error);
      setStatus('error');
    }
  };

  useEffect(() => {
    initiateConversations();

    return () => {
      setToken(null);
      setStatusString('');
      setStatus('');
      setConversationsReady(false);
      setConversations([]);
      setLoggedIn(false);
      setMessages([]);
      setNewMessage('');
      clearMessages();
      conversationsClient?.shutdown();
    };
  }, []);

  return (
    <>
      <RenderIf isTrue={status === 'default'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Comment
            visible={true}
            height="100"
            width="100"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="#fff"
            backgroundColor="var(--primary)"
          />
        </div>
      </RenderIf>
      <RenderIf isTrue={status === 'error'}>
        <div>Cant connect to chat at the moment.</div>
      </RenderIf>
      <RenderIf isTrue={status === 'success'}>
        <Conversation conversationsClient={fetchedConversation} />
      </RenderIf>
    </>
  );
};

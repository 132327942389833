// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { RenderIf } from 'helpers';
import { useFetchWindowDimensions } from 'hooks/windowdimensions/useFetchWindowDimensions';
import Carousel, { CarouselItem } from 'components/carousel';
import styles from './index.module.css';
import { RestaurantStore } from 'store';

export const HomeCarousel = ({ title }) => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);

  // const { content, style } = restaurantData?.theme?.landing?.carouselSections?.filter(
  //   (sec) => sec?.title === title
  // )[0];

  const content = restaurantData?.theme?.landing?.carouselSections?.filter(
    (sec) => sec?.title === title
  )[0]?.content;

  const style = restaurantData?.theme?.landing?.carouselSections?.filter(
    (sec) => sec?.title === title
  )[0]?.style;

  const { width } = useFetchWindowDimensions();

  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage:
            style?.backgroundImage !== ``
              ? `url(${style?.backgroundImage})`
              : ``,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``,
            paddingRight: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
          >
            <Carousel
              showIndicators={width > 991 ? true : false}
              showPageLabels={width > 991 ? true : false}
              indicateHorizontalCenter={true}
              pageLVerticalCenter={true}
              indicateRight={`0`}
              indicateBottom={`24px`}
              indicateLeft={`0`}
              pageLTop={`0`}
              pageLRight={`var(--contBxHPadding)`}
              pageLBottom={`0`}
            >
              {content?.slides?.map((item, index) => (
                <CarouselItem key={index}>
                  <div className={styles.slide}>
                    <RenderIf isTrue={content?.contentOverlay}>
                      <div
                        className={styles.overlay}
                        style={{
                          background: style?.overlayBackgroundColor,
                          opacity: style?.overlayOpacity / 100
                        }}
                      ></div>
                    </RenderIf>
                    <picture>
                      <source srcSet={item?.media} />
                      <img src={item?.media} alt={item?.title} />
                    </picture>
                    <div className={styles.content}>
                      <div
                        className={!content?.contentInContainer ? `contBx` : ``}
                        style={{
                          paddingLeft: !content?.contentInContainer
                            ? `var(--contBxHPadding)`
                            : ``,
                          paddingRight: !content?.contentInContainer
                            ? `var(--contBxHPadding)`
                            : ``
                        }}
                      >
                        <div
                          className={styles.contentInner}
                          style={{ color: style?.foregroundColor }}
                        >
                          <RenderIf isTrue={item?.title}>
                            <h1 style={{ whiteSpace: `pre-line` }}>
                              {item?.title}
                            </h1>
                          </RenderIf>
                          <RenderIf isTrue={item?.description}>
                            <div style={{ whiteSpace: `pre-line` }}>
                              {item?.description}
                            </div>
                          </RenderIf>
                        </div>
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </Carousel>
          </div>
        </div>
      </article>
    </>
  );
};

import { JSONData } from 'store/pagesjson';
import styles from './index.module.css';
import { RestaurantStore } from 'store';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';

export const PrimaryButton = ({
  type = `button`,
  buttonReverse = false,
  buttonFull = false,
  handleClick = () => {},
  children,
  disabled
}) => {
  // const { resData } = useFetchRestaurantHook();


  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);

  //const resData = JSONData;
  const primary = restaurantData?.theme?.global?.style?.button?.primary;

  return (
    <button
      type={type}
      className={styles.button}
      onClick={handleClick}
      style={{
        width: buttonFull ? `100%` : ``,
        backgroundColor: buttonReverse
          ? primary?.textColor
          : primary?.backgroundColor,
        color: buttonReverse ? primary?.backgroundColor : primary?.textColor,
        borderRadius: `${primary?.roundedCorners}px`
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

import React, { useEffect, useState } from 'react';
import { ConversationList } from '../conversationlist';
import { RenderIf } from 'helpers';
import { Comment } from 'react-loader-spinner';
import { AppStore } from 'store';
import { Client } from 'db';

export const Conversation = ({ conversationsClient }) => {
  const { messages, setMessages } = AppStore((state) => state);
  const [newMessage, setNewMessage] = useState('');
  const [loadingState, setLoadingState] = useState('initializing');
  const [boundConversations, setBoundConversations] = useState(new Set());
  console.log(
    '🚀 ~ file: index.js:11 ~ Conversation ~ boundConversations:',
    conversationsClient
  );

  const loadMessages = async (conversationsClient) => {
    // let chatHistory = (
    //   await Client.get(
    //     `https://zewst-phone-backend-11f86ed5f348.herokuapp.com/conversation/findByConversationSid/CH79a85c0c285649a78687fcb3edec7814`
    //   )
    // )?.data;

    // chatHistory = chatHistory?.conversations?.messages;
    // //chatHistory = chatHistory?.filter((m) => m?.attributes?.sender);
    // console.log(
    //   '🚀 ~ file: index.js:24 ~ loadMessages ~ chatHistory:',
    //   chatHistory
    // );

    // chatHistory?.forEach((m) => {
    //   setMessages({
    //     index: m.index,
    //     body: m.body,
    //     dateCreated: m.dateCreated,
    //     author: m.author,
    //     attributes: m.attributes
    //   });
    // });

    conversationsClient
      ?.getMessages()
      ?.then((messagePaginator) => {
        if (conversationsClient) {
          let tempMessages = messagePaginator.items?.map((m) => ({
            index: m.index,
            body: m.body,
            dateCreated: m.dateCreated,
            author: m.author,
            attributes: m.attributes
          }));

          tempMessages?.forEach((m) => {
            setMessages(m);
          });
          setLoadingState('ready');

          setTimeout(() => {
            const element = document.getElementsByClassName('conversation');
            element[0].scrollTop = 1000000;
          }, 1000);

          // const element = document.getElementsByClassName('conversation');
          // console.log('🚀 ~ file: index.js:36 ~ ?.then ~ element:', element);
          // element[0].scrollTop = element[0].scrollHeight;
        }
      })
      ?.catch((err) => {
        setLoadingState('failed');
      });
  };

  useEffect(() => {
    if (conversationsClient) {
      loadMessages(conversationsClient);
      if (!boundConversations.has(conversationsClient)) {
        let newConversation = conversationsClient;
        newConversation.on('messageAdded', (m) => {
          messageAdded(
            {
              index: m.index,
              body: m.body,
              dateCreated: m.dateCreated,
              attributes: m.attributes,
              author: m.author
            },
            newConversation
          );
        });
        setBoundConversations(
          new Set([...boundConversations, newConversation])
        );
      }
    }
  }, [conversationsClient]);

  const messageAdded = (message, targetConversation) => {
    if (targetConversation === conversationsClient) {
      setMessages(message);
      const element = document.getElementsByClassName('conversation');

      // element[0].scrollIntoView(false);
      // console.log('🚀 ~ file: index.js:72 ~ messageAdded ~ element:', element);
    }
  };

  const sendMessage = async (event) => {
    console.log('🚀 ~ file: index.js:85 ~ sendMessage ~ event:', newMessage);
    const message = newMessage;
    await conversationsClient.sendMessage(message, {
      medium: 'webchat',
      sender: 'customer'
    });
    setNewMessage('');
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `space-between`
      }}
      className="conversation"
    >
      <ConversationList messages={messages} />
      <RenderIf isTrue={loadingState !== 'ready'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Comment
            visible={true}
            height="100"
            width="100"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="#fff"
            backgroundColor="var(--primary)"
          />
        </div>
      </RenderIf>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          gap: 8
        }}
      >
        <input
          placeholder={'Type your message here...'}
          type={'text'}
          name={'message'}
          autoComplete={'off'}
          disabled={loadingState !== 'ready'}
          onChange={(event) => setNewMessage(event.target.value)}
          value={newMessage}
          style={{
            borderRadius: 8,
            border: '2px solid var(--primary)',
            width: 'calc(100% - 90px)',
            padding: 10,
            margin: 0
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              sendMessage();
            }
          }}
        />
        <button
          onClick={sendMessage}
          disabled={loadingState !== 'ready'}
          style={{
            backgroundColor: 'var(--primary)',
            color: 'var(--accentForeGround)',
            width: 82,
            padding: 10,
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: 8
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

import { Outlet } from 'react-router-dom';
import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { Header } from 'components/header';
import { RenderIf } from 'helpers';
import { useEffect, useRef, useState } from 'react';
import { RestaurantStore } from 'store';
import { AppStore } from 'store';
import { ModalComponent } from 'components/modal';
import { AccountPage } from 'pages/account';
import { Fab } from 'react-tiny-fab';
import { Chat } from 'pages/chat';
import { Footer } from 'components/footer';
import { AuthPageStore } from 'store';
import { useFetchWindowDimensions } from 'hooks/windowdimensions/useFetchWindowDimensions';

const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 576 512"
      fill="var(--accentForeGround)"
    >
      <path d="M284.046,224.8a34.114,34.114,0,1,0,34.317,34.113A34.217,34.217,0,0,0,284.046,224.8Zm-110.45,0a34.114,34.114,0,1,0,34.317,34.113A34.217,34.217,0,0,0,173.6,224.8Zm220.923,0a34.114,34.114,0,1,0,34.317,34.113A34.215,34.215,0,0,0,394.519,224.8Zm153.807-55.319c-15.535-24.172-37.31-45.57-64.681-63.618-52.886-34.817-122.374-54-195.666-54a405.975,405.975,0,0,0-72.032,6.357,238.524,238.524,0,0,0-49.51-36.588C99.684-11.7,40.859.711,11.135,11.421A14.291,14.291,0,0,0,5.58,34.782C26.542,56.458,61.222,99.3,52.7,138.252c-33.142,33.9-51.112,74.776-51.112,117.337,0,43.372,17.97,84.248,51.112,118.148,8.526,38.956-26.154,81.816-47.116,103.491a14.284,14.284,0,0,0,5.555,23.34c29.724,10.709,88.549,23.147,155.324-10.2a238.679,238.679,0,0,0,49.51-36.589A405.972,405.972,0,0,0,288,460.14c73.313,0,142.8-19.159,195.667-53.975,27.371-18.049,49.145-39.426,64.679-63.619,17.309-26.923,26.07-55.916,26.07-86.125C574.394,225.4,565.634,196.43,548.326,169.485ZM284.987,409.9a345.65,345.65,0,0,1-89.446-11.5l-20.129,19.393a184.366,184.366,0,0,1-37.138,27.585,145.767,145.767,0,0,1-52.522,14.87c.983-1.771,1.881-3.563,2.842-5.356q30.258-55.68,16.325-100.078c-32.992-25.962-52.778-59.2-52.778-95.4,0-83.1,104.254-150.469,232.846-150.469s232.867,67.373,232.867,150.469C517.854,342.525,413.6,409.9,284.987,409.9Z" />
    </svg>
  );
};

export const Layout = () => {
  const {
    restaurantData,
    setRestaurantData,
    setOpenedRewardModal,
    openedRewardModal
  } = RestaurantStore((state) => state);
  const { width } = useFetchWindowDimensions();

  const content = restaurantData?.theme?.global?.content;
  const style = restaurantData?.theme?.global?.style;
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const { page } = AuthPageStore((state) => state);

  const timeout = useRef(null);

  const { user, guestUser, openChatModal, setOpenChatModal } = AppStore(
    (state) => state
  );

  const { resLoading } = useFetchRestaurantHook('restaurant', false);

  useEffect(() => {
    if (!openedRewardModal) {
      if (!resLoading && restaurantData) {
        if (user || guestUser) {
          setOpenSignupModal(false);
        } else {
          timeout.current = setTimeout(() => {
            setOpenSignupModal(true);
            setOpenedRewardModal(true);
          }, 3000);
        }
      }
    }

    return () => clearTimeout(timeout.current);
  }, [user, guestUser, resLoading, restaurantData, openedRewardModal]);

  useEffect(() => {
    if (restaurantData?.theme) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href =
        content?.favicon || 'https://i.ibb.co/7VTzBGc/favicon-32x32.png';

      document.title = content?.title ? content?.title : 'Welcome';
    }
  }, [restaurantData?.theme, content]);

  useEffect(() => {
    document.body.style.fontFamily = style?.bodyFont || `'Poppins', sans-serif`;
    document.body.style.backgroundColor = style?.backgroundColor;
    document.body.style.backgroundImage = `url(${style?.backgroundImage})`;
    document.body.style.color = style?.foregroundColor;

    return () => {
      document.body.style.backgroundColor = null;
      document.body.style.backgroundImage = null;
      document.body.style.color = null;
      document.body.style.fontFamily = null;
    };
  }, [style]);

  return (
    <>
      <div
        style={{
          position: `relative`,
          zIndex: `calc(var(--headerIndex) + 10)`
        }}
      >
        <Fab
          onClick={(e) => {
            if (!user) {
              e.preventDefault();
              setOpenSignupModal(!openSignupModal);
            }
            if (user) {
              setOpenChatModal(!openChatModal);
            }
          }}
          icon={<ChatIcon />}
          mainButtonStyles={{
            backgroundColor: 'var(--primary)'
          }}
          style={{ bottom: width < 992 ? 14 : 14, right: 14 }}
        ></Fab>
        <RenderIf isTrue={openChatModal}>
          <>
            <div
              style={{
                position: `fixed`,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
                background: `rgba(0, 0, 0, 0.5)`
              }}
              onClick={() => {
                setOpenChatModal(false);
              }}
            ></div>
            <div
              style={{
                position: `fixed`,
                right: 15,
                bottom: 80,
                width: `calc(100vw - 28px)`,
                height: `calc(100vh - 200px)`,
                maxWidth: `460px`,
                padding: `12px`,
                background: `rgba(255, 255, 255, 1)`,
                borderRadius: 16,
                boxShadow: `0px 0px 14px rgba(0, 0, 0, 0.25)`,
                marginRight: 0,
                marginLeft: 0
              }}
            >
              <Chat />
            </div>
          </>
        </RenderIf>
      </div>
      <RenderIf isTrue={resLoading && !restaurantData}>
        <div
          style={{
            width: `100vw`,
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            backgroundColor: `rgba(255, 255, 255, 0.9)`
          }}
        >
          <div className="loading1"></div>
        </div>
      </RenderIf>
      <RenderIf isTrue={restaurantData?.theme || restaurantData}>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </RenderIf>

      <RenderIf isTrue={page === 'createAccount' || page === 'login'}>
        <ModalComponent
          modal={openSignupModal}
          handleModal={() => setOpenSignupModal(false)}
          rightModal={false}
          closeOver={true}
          maxWidth={444}
        >
          <AccountPage rewardsPopup={true} />
        </ModalComponent>
      </RenderIf>
    </>
  );
};

import { RenderIf } from 'helpers';
import { Icon } from 'assets/icons';
import styles from './index.module.css';

export const Aqcuired = ({ userPointDetails }) => {

    return (
        <article className={styles.article}>
            <RenderIf isTrue={userPointDetails?.totalCustomerPoints?.points > 0}>
                {userPointDetails?.customerOrdersWithBranches?.map((points) => (
                    <div className={styles.item}>
                        <div>
                            <h5 className={`fsemi`}>{points?.brand?.[0]?.name}</h5>
                            <div style={{ marginTop: 4, }}></div>
                            <div className={styles.subTitle}> Branch name {points?.branch?.[0]?.name}</div>
                        </div>
                        <div className={styles.zewardsCol}>
                            <div>
                                <h4 color="primary"><b>{points?.points}</b></h4>
                                <div style={{ marginTop: 4, }}></div>
                                <div className={styles.aquiredOn}>Aquired On: {points?.createdAt?.split('T')?.[0]}</div>
                            </div>
                            <Icon name={`zeward_coin`} />
                        </div>
                    </div>
                ))}
            </RenderIf>
            <RenderIf isTrue={userPointDetails?.totalCustomerPoints?.points === 0}>
                <div className={styles.item}>
                    <div className={`no_data`}>No zewards have been aquired yet.</div>
                </div>
            </RenderIf>
        </article>
    );
}
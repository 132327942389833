import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCart } from 'react-use-cart';
import { AXIOS_INSTANCE_ZEWARDS, Client } from 'db';
import { Toasts } from 'components/toasts';
import {
  AppStore,
  AuthPageStore,
  OrderStore,
  RestaurantStore,
  ClientOrderStore
} from 'store';
import {
  RenderIf,
  calculateTip,
  fetchDeliveryEnabled,
  fetchDeliveryPrice
} from 'helpers';
import { ModalComponent } from 'components/modal';
import { PrimaryButton } from 'components/buttons/primary';
import { AccountPage } from 'pages/account';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { TextFieldWithoutHookForm } from 'components/input';
import Select from 'react-select';
import { PhoneFieldWithoutHookForm } from 'components/phone';

const EmbedMap = ({ selectedBranch }) => {
  let addr = ``;
  if (selectedBranch) {
    addr =
      selectedBranch?.branchName +
      `, ` +
      selectedBranch?.address?.addressLine1?.line1 +
      `, ` +
      selectedBranch?.address?.addressLine1?.line2 +
      `, ` +
      selectedBranch?.address?.city;
  }
  const src = `https://maps.google.com/maps?&q="+${addr}"&output=embed`;
  const Embed = () => {
    return (
      <div>
        <iframe
          title={`Branch address`}
          width="100%"
          height="214"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          src={src}
        ></iframe>
      </div>
    );
  };

  return (
    <>
      <Embed />
    </>
  );
};

export const CartPage = () => {
  const { user, guestUser } = AppStore((state) => state);
  const { page, setPage } = AuthPageStore((state) => state);
  const { orderType, setOrderType, orderAddress, setOrderAddress } =
    ClientOrderStore((state) => state);
  const { items, updateItemQuantity, removeItem, emptyCart } = useCart();
  const { restaurantData, deliveryFee, setDeliveryFee, setDeliveryEnabled } =
    RestaurantStore((state) => state);

  const [deliveryAdditionalFields, setDeliveryAdditionalFields] = useState({
    pickup_additional_notes: '',
    dropoff_additional_notes: '',
    customerDeliveryNumber: '',
    customerDeliveryName: ''
  });
  const [totalCost, setTotalCost] = useState(0);
  const [checkingoutLoading, setCheckingoutLoading] = useState(false);
  const [showDeliveryTypeModal, setShowDeliveryTypeModal] = useState(false);
  const [tip, setTip] = useState(0);

  useEffect(() => {
    if (items?.length === 0) return;
    fetchDeliveryEnabled(items?.[0]?.parentBranch, setDeliveryEnabled);
    const totalCost = items.reduce((acc, curr) => {
      return acc + curr.cost * curr.quantity;
    }, 0);
    setTotalCost(totalCost);
  }, [items]);

  useEffect(() => {
    if (orderType === 'Delivery') {
      if (orderAddress) {
        fetchDeliveryPrice(
          orderAddress,
          totalCost,
          items?.[0]?.parentBranch,
          user?.contact?.[0]?.contactNumber,
          setDeliveryFee,
          items?.map((item) => ({
            name: item?.item,
            quantity: item?.quantity
          })),
          setDeliveryEnabled
        );
        setTip(calculateTip(totalCost));
      }
    }
  }, [orderType, totalCost, items, orderAddress]);

  const zewst_charges = +parseFloat(
    ((totalCost + deliveryFee) * (3 / 100))?.toFixed(2)
  );

  const tax =
    items?.[0]?.branchTax > 0
      ? +parseFloat(
          (
            (totalCost + zewst_charges + deliveryFee) *
            (items?.[0]?.branchTax / 100)
          )?.toFixed(2)
        )
      : 0;

  const total =
    items?.[0]?.branchTax > 0
      ? Number(
          parseFloat(
            (
              totalCost +
              deliveryFee +
              tax +
              zewst_charges +
              Number(tip)
            )?.toFixed(2)
          )
        )
      : Number(
          parseFloat(
            totalCost + deliveryFee + zewst_charges + Number(tip)
          )?.toFixed(2)
        );

  const handleCheckout = async (ordType) => {
    setCheckingoutLoading(true);
    let typeOfOrder = '';
    typeOfOrder =
      orderType === 'Delivery'
        ? 'DELIVERY'
        : orderType === 'Pickup'
        ? 'TAKE_AWAY'
        : 'DINE_IN';

    try {
      const itemsArr = items.map((item) => ({
        itemId: item?._id,
        quantity: item?.quantity,
        unitPrice: item?.cost,
        total: item?.cost * item?.quantity,
        modifiers: item?.modifiers?.map((modifier) => ({
          modifierId: modifier?._id,
          quantity: 1,
          unitPrice: modifier?.additionalPrice,
          total: modifier?.additionalPrice
        })),
        itemOrigin:
          restaurantData?.brand?.brandType === 'CLOUD'
            ? {
                brand: item?.brandId,
                branch: item?.branchId
              }
            : null,
        itemOriginMarkup: items?.[0]?.markup
      }));

      const bodyObj = {
        subTotal: +parseFloat(totalCost).toFixed(2),
        discount: 0,
        tax: tax,
        total: total,
        brand: restaurantData?.brand?._id,
        branch: items?.[0]?.parentBranch,
        customer: user?._id || guestUser?._id,
        orderVia: 'ZEWST_ONLINE',
        type: typeOfOrder,
        orderRoute:
          restaurantData?.brand?.brandType !== 'CLOUD'
            ? 'BRANCH'
            : 'CLOUD_KITCHEN',
        items: itemsArr,
        storeUrl: window.location.protocol + '//' + window.location.host,
        tax_charges: tax,
        zewst_charges: zewst_charges
      };

      if (!user) {
        bodyObj.customer = '';
        bodyObj.isGuestCheckout = true;
      }
      if (orderType === 'Delivery') {
        bodyObj.customerDeliveryAddress = orderAddress;
        bodyObj.deliveryFee = +deliveryFee;
        bodyObj.pickup_additional_notes =
          deliveryAdditionalFields.pickup_additional_notes;
        bodyObj.dropoff_additional_notes =
          deliveryAdditionalFields.dropoff_additional_notes;
        bodyObj.customerDeliveryNumber =
          deliveryAdditionalFields.customerDeliveryNumber;
        bodyObj.customerDeliveryName =
          deliveryAdditionalFields.customerDeliveryName;
        bodyObj.tip = tip ? +tip : 0.1;
        bodyObj.total = Number(Number(bodyObj.total).toFixed(2));
      }

      console.log('🚀 ~ handleCheckout ~ bodyObj:', bodyObj);

      let response = await Client.post('/orders/checkout', bodyObj, user);
      response = response.data;

      if (user) {
        await handleZewardsCheckout();
      }

      setDeliveryFee(0);
      // open link in same window
      window.location.href = response?.url;
      emptyCart();
      setTimeout(() => {
        setCheckingoutLoading(false);
      }, 3000);
    } catch (error) {
      console.log('🚀 ~ handleCheckout ~ error:', error);
      setCheckingoutLoading(false);
      Toasts.error('Unable to process your order. Please try again later.');
    }
  };

  const handleZewardsCheckout = async () => {
    try {
      const zewardsUser = (
        await AXIOS_INSTANCE_ZEWARDS.post('/customer/signin', {
          email: user?.email
        })
      )?.data;
      if (zewardsUser?.error === 'Customer not found') {
        await AXIOS_INSTANCE_ZEWARDS.post('/customer/signup', {
          name: user?.fullName,
          email: user?.email,
          phone: user?.contact?.[0]?.contactNumber,
          clientId: restaurantData?.theme?.buisnessId,
          externalId: user?._id,
          brandId: restaurantData?.theme?.brandId,
          branchId: restaurantData?.theme?.branchId
        });
      }

      // setZewardsData({
      //   customerId: user?._id,
      //   brandId: restaurantData?.brand?.brandId,
      //   branchId: restaurantData?.brand?.branches?.find(
      //     (branch) => branch?._id === items?.[0]?.parentBranch
      //   )?.branchId,
      //   loyaltyId: restaurantData?.theme?.theme?.loyaltyId
      // });
    } catch (error) {
      Toasts.error('Unable to process your order. Please try again later.');
    }
  };

  const selectedBranch = restaurantData?.brand?.branches?.filter(
    (branch) => branch?._id === items?.[0]?.parentBranch
  )?.[0];

  const handleAdditonalFields = (e) => {
    setDeliveryAdditionalFields({
      ...deliveryAdditionalFields,
      [e?.target?.name]: e?.target?.value
    });
  };
  const handleCustomerDeliveryNumber = (e) => {
    setDeliveryAdditionalFields({
      ...deliveryAdditionalFields,
      customerDeliveryNumber: e
    });
  };

  var US_PHONE_NUMBER_REGEX = new RegExp(
    /^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/
  );

  return (
    <article className={styles.article}>
      <header
        className={styles.header}
        style={{
          backgroundImage: `url(https://static-content.owner.com/locations/banner-images/j2cq4SKBPn2l.jpg?v=0)`
        }}
      >
        <div className={`box3`}>
          <div className={styles.rowNor}>
            <div>
              <h4 className={`t_up`}>Cart</h4>
            </div>
            <div></div>
          </div>
        </div>
      </header>
      <div className={`box3`}>
        <RenderIf isTrue={items.length !== 0}>
          <div className={styles.pageRw}>
            <div>
              <div className={styles.userBx}>
                <RenderIf isTrue={items.length !== 0 && (user || guestUser)}>
                  <div className={styles.titleBx}>
                    <h2>
                      <RenderIf isTrue={user}>
                        Welcome! {user?.fullName?.replaceAll(':', '')}
                      </RenderIf>
                      <RenderIf isTrue={guestUser}>Your order detail</RenderIf>
                    </h2>
                    <h6>Please click the checkout in order to process.</h6>
                  </div>
                </RenderIf>
                <RenderIf isTrue={items.length !== 0 && !user && !guestUser}>
                  <div className={styles.titleBx}>
                    <h2>Please login or create account</h2>
                    <h6>Login to access your Credits and Zewards</h6>
                  </div>
                  <div className={styles.logTabs}>
                    <button
                      type={`button`}
                      onClick={() => {
                        setPage(`login`);
                      }}
                      className={page === `login` ? styles.active : ``}
                    >
                      Login
                    </button>
                    <button
                      type={`button`}
                      onClick={() => {
                        setPage(`createAccount`);
                      }}
                      className={page === `createAccount` ? styles.active : ``}
                    >
                      Sign Up
                    </button>
                  </div>
                  <AccountPage rewardsPopup={true} />
                </RenderIf>
              </div>
            </div>
            <div></div>
            <div>
              <RenderIf isTrue={items?.length}>
                <div className={styles.pickupBx}>
                  <div>
                    <h6>{selectedBranch?.branchName}</h6>
                  </div>
                  <div>
                    <div className={styles.pickup}>
                      <Icon name={orderType} /> {orderType}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 18 }}></div>
                <RenderIf isTrue={orderType === 'Delivery'}>
                  <div className={styles.pickAddBx}>
                    <div className={styles.pickCont} style={{ width: '100%' }}>
                      <div>
                        <Icon name={`location`} />
                      </div>
                      <div style={{ width: '100%' }}>
                        <div className={styles.lineN}>
                          {orderAddress || 'Choose a valid address'}
                        </div>
                        <RenderIf isTrue={user?.address?.length > 0}>
                          <div
                            style={{
                              textAlign: 'center',
                              margin: '5px 0px',
                              fontSize: '14px',
                              fontWeight: '700'
                            }}
                          >
                            OR
                          </div>
                          <div>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              placeholder="Choose from saved addresses"
                              options={user?.address?.map((address) => ({
                                value:
                                  address?.addressLine1 +
                                  '' +
                                  address?.addressLine2 +
                                  ', ' +
                                  address?.city +
                                  ', ' +
                                  address?.State +
                                  ', ' +
                                  address?.zipCode,
                                label:
                                  address?.addressLine1 +
                                  '' +
                                  address?.addressLine2 +
                                  ', ' +
                                  address?.city +
                                  ', ' +
                                  address?.State +
                                  ', ' +
                                  address?.zipCode
                              }))}
                              onChange={(e) => {
                                console.log('react select', e);
                                if (e?.value) {
                                  setOrderAddress(e?.value);
                                } else {
                                  setDeliveryFee(0);
                                  setOrderAddress('');
                                }
                              }}
                            />
                          </div>
                        </RenderIf>
                        {/* <p>
                          {address?.city} - {address?.zipCode}, {address?.country}
                        </p> */}
                      </div>
                    </div>

                    <div></div>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '45%'
                        }}
                      >
                        <PhoneFieldWithoutHookForm
                          label={`${
                            !user
                              ? 'Contact number'
                              : ' Another delivery number (Optional)'
                          } `}
                          name="customerDeliveryNumber"
                          onChange={handleCustomerDeliveryNumber}
                          placeholder={`+1222333444`}
                          required={!user ? true : false}
                          defaultCountry={'US'}
                        />
                        {/* <TextFieldWithoutHookForm
                          label={`${
                            !user
                              ? 'Contact number'
                              : ' Another delivery number (Optional)'
                          } `}
                          name="customerDeliveryNumber"
                          onChange={handleAdditonalFields}
                          placeholder={`+1222333444`}
                          required={!user ? true : false}
                        /> */}
                      </div>
                      <div
                        style={{
                          width: '45%'
                        }}
                      >
                        <TextFieldWithoutHookForm
                          label={` ${
                            !user
                              ? 'Contact name'
                              : 'Another delivery name (Optional)'
                          }`}
                          name="customerDeliveryName"
                          onChange={handleAdditonalFields}
                          placeholder={`John Doe`}
                          required={!user ? true : false}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: '10px'
                      }}
                    >
                      <TextFieldWithoutHookForm
                        label={`Pickup Notes (Optional)`}
                        name="pickup_additional_notes"
                        onChange={handleAdditonalFields}
                        placeholder={`get the order from the main gate etc`}
                      />
                      <TextFieldWithoutHookForm
                        label={`Dropoff Notes (Optional)`}
                        name="dropoff_additional_notes"
                        onChange={handleAdditonalFields}
                        placeholder={`Appartment number, gate code, floor number etc`}
                      />
                    </div>
                  </div>
                </RenderIf>
                <RenderIf isTrue={orderType === 'Pickup'}>
                  <EmbedMap selectedBranch={selectedBranch} />
                </RenderIf>
                <div style={{ marginTop: 18 }}></div>
                {items?.map((item, index) => {
                  return (
                    <div key={index} className={styles.item}>
                      <div className={styles.profile}>
                        <picture>
                          <source
                            srcSet={
                              item?.image ||
                              'https://png.pngtree.com/png-clipart/20220827/ourmid/pngtree-breakfast-menu-with-eggs-bread-sausage-lettuce-tomatoes-and-a-cup-png-image_6126172.png'
                            }
                          />
                          <img
                            src={
                              item?.image ||
                              'https://png.pngtree.com/png-clipart/20220827/ourmid/pngtree-breakfast-menu-with-eggs-bread-sausage-lettuce-tomatoes-and-a-cup-png-image_6126172.png'
                            }
                            alt="categoryType"
                          />
                        </picture>
                        <div>
                          <div className={styles.itemTitle}>{item?.item}</div>
                          <div className={styles.rowF}>
                            <p>
                              Unit Price: <strong>$ {item?.cost}</strong>
                            </p>

                            <button onClick={() => removeItem(item?.id)}>
                              delete item
                            </button>
                          </div>
                          <div>
                            {item?.modifiers?.map((mod, index) => {
                              return (
                                <div style={{ display: 'flex' }} key={index}>
                                  <div>{mod?.modifier}</div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>$ {(item?.cost * item?.quantity)?.toFixed(2)}</div>
                        <div>
                          <div className={styles.qtyBx}>
                            <button
                              type={`button`}
                              onClick={() =>
                                updateItemQuantity(item?.id, item?.quantity - 1)
                              }
                            >
                              <Icon
                                name={`minus`}
                                fillColor={`var(--foreground-color)`}
                              />
                            </button>
                            <div>
                              <strong>{item?.quantity || '-'}</strong>
                            </div>
                            <button
                              type={`button`}
                              onClick={() =>
                                updateItemQuantity(item?.id, item?.quantity + 1)
                              }
                            >
                              <Icon
                                name={`plus`}
                                fillColor={`var(--foreground-color)`}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className={`mt_20`}></div>
                <div className={styles.footer}>
                  <div>
                    <strong>SubTotal:</strong>
                  </div>
                  <div>
                    <span>$ {totalCost?.toFixed(2)}</span>
                  </div>
                </div>
                <div className={styles.footer} style={{ marginTop: '10px' }}>
                  <div>
                    <strong>Tax:</strong>
                  </div>
                  <div>
                    <span>$ {tax}</span>
                  </div>
                </div>
                <RenderIf isTrue={orderType === 'Delivery'}>
                  <div className={styles.footer} style={{ marginTop: '10px' }}>
                    <div>
                      <strong>Delivery charges:</strong>
                    </div>
                    <div>
                      <span>$ {deliveryFee}</span>
                    </div>
                  </div>
                  <div className={styles.footer} style={{ marginTop: '10px' }}>
                    <div>
                      <strong>Delivery tip:</strong>
                    </div>
                    <div style={{ maxWidth: '80px' }}>
                      <TextFieldWithoutHookForm
                        type={'number'}
                        value={tip}
                        onChange={(e) => {
                          setTip(e.target.value);
                        }}
                        placeholder={`minimum $ 0.1`}
                      />
                      {/* <input
                          type="text"
                          value={tip}
                          onChange={(e) => {
                            setTip(e.target.value);
                          }}
                          style={{ width: '100%' }}
                          placeholder="minimum $ 0.1"
                        /> */}
                    </div>
                  </div>
                </RenderIf>
                <div className={styles.footer} style={{ marginTop: '10px' }}>
                  <div>
                    <strong>Other charges:</strong>
                  </div>
                  <div>
                    <span>$ {zewst_charges}</span>
                  </div>
                </div>
                <div className={`mt_20`}></div>
                <hr />
                <div className={`mt_20`}></div>
                <div className={styles.footer} style={{ marginTop: '10px' }}>
                  <div>
                    <strong>Total:</strong>
                  </div>
                  <div>
                    <span className={`fsemi`}>${total}</span>
                  </div>
                </div>
                <div className={`mt_20`}></div>
                <hr />
                <div className={`mt_20`}></div>
                {/* <RenderIf isTrue={user || guestUser}> enabling guest checkout flow thats why commenting  */}
                <div className={`flex item_hcenter`}>
                  <PrimaryButton
                    type={`button`}
                    disabled={
                      checkingoutLoading ||
                      (orderType === 'Delivery' && deliveryFee === 0) ||
                      (orderType === 'Delivery' && !orderAddress) ||
                      (!user &&
                        !deliveryAdditionalFields?.customerDeliveryNumber &&
                        orderType === 'Delivery') ||
                      (!user &&
                        !deliveryAdditionalFields?.customerDeliveryName &&
                        orderType === 'Delivery') ||
                      (!user &&
                        !US_PHONE_NUMBER_REGEX.test(
                          deliveryAdditionalFields?.customerDeliveryNumber
                        ) &&
                        orderType === 'Delivery')
                    }
                    handleClick={handleCheckout}
                    buttonFull={true}
                  >
                    {(orderType === 'Delivery' && deliveryFee === 0) ||
                    (orderType === 'Delivery' && !orderAddress) ||
                    (!user &&
                      !deliveryAdditionalFields?.customerDeliveryNumber &&
                      orderType === 'Delivery') ||
                    (!user &&
                      !deliveryAdditionalFields?.customerDeliveryName &&
                      orderType === 'Delivery') ||
                    (!user &&
                      !US_PHONE_NUMBER_REGEX.test(
                        deliveryAdditionalFields?.customerDeliveryNumber
                      ) &&
                      orderType === 'Delivery') ? (
                      <div>
                        Provide valid contact details & delivery address
                      </div>
                    ) : (
                      <div>Checkout {user ? '' : 'as Guest'}</div>
                    )}
                  </PrimaryButton>
                </div>
                {/* </RenderIf> */}
              </RenderIf>
            </div>
          </div>
        </RenderIf>
        <RenderIf isTrue={items.length === 0}>
          <div className={styles.emptyCartBx}>
            <h4 className={`t_up`}>Cart is empty</h4>
            <p className={`h5`}>
              Your cart is empty. Please choose some food items and added to
              cart first.
            </p>
          </div>
          <div style={{ paddingTop: 50 }}></div>
        </RenderIf>

        <ModalComponent
          modal={showDeliveryTypeModal}
          handleModal={() => setShowDeliveryTypeModal(false)}
        >
          <div className={`mt_40`}></div>
          <div className={styles.buttons}>
            <button
              disabled={checkingoutLoading}
              className={
                orderType === 'Delivery' ? `button2 active` : `button2`
              }
              onClick={() => {
                setOrderType('Delivery');
                handleCheckout('Delivery');
              }}
            >
              Delivery
            </button>
            <button
              disabled={checkingoutLoading}
              className={orderType === 'Pickup' ? `button2 active` : `button2`}
              onClick={() => {
                setOrderType('Pickup');
                handleCheckout('Pickup');
              }}
            >
              Pickup
            </button>
            <button
              disabled={checkingoutLoading}
              className={orderType === 'Dine-in' ? `button2 active` : `button2`}
              onClick={() => {
                setOrderType('Dine-in');
                handleCheckout('Dine-in');
              }}
            >
              Dine In
            </button>
          </div>
          <div className={`mt_15`}></div>
          <RenderIf isTrue={checkingoutLoading}>
            <div
              style={{
                width: `100vw`,
                height: `100vh`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                backgroundColor: `rgba(255, 255, 255, 0.9)`
              }}
            >
              <div className="loading1"></div>
              {/* <Circles
                height="80"
                width="80"
                color="#8d23dd"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> */}
            </div>
            <p> Processing your order </p>
          </RenderIf>
        </ModalComponent>
      </div>
    </article>
  );
};

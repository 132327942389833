// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { RenderIf } from 'helpers';
import styles from './index.module.css';
import { RestaurantStore } from 'store';

export const HomeFigure = ({ title }) => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);

  // const { content, style } = restaurantData?.theme?.landing?.figureSections?.filter(
  //   (sec) => sec?.title === title
  // )[0];

  const content = restaurantData?.theme?.landing?.figureSections?.filter(
    (sec) => sec?.title === title
  )[0]?.content;

  const style = restaurantData?.theme?.landing?.figureSections?.filter(
    (sec) => sec?.title === title
  )[0]?.style;
  
  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage:
            style?.backgroundImage !== ``
              ? `url(${style?.backgroundImage})`
              : ``,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``,
            paddingRight: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout2}
            style={{ color: style?.foregroundColor }}
          >
            <RenderIf isTrue={content?.media}>
              <picture>
                <source srcSet={content?.media} />
                <img src={content?.media} alt={content?.title} />
              </picture>
            </RenderIf>
            <RenderIf isTrue={content?.contentOverlay}>
              <div
                className={styles.overlay}
                style={{
                  background: style?.overlayBackgroundColor,
                  opacity: style?.overlayOpacity / 100
                }}
              ></div>
            </RenderIf>
            <div className={styles.content}>
              <div
                className={!content?.contentInContainer ? `contBx` : ``}
                style={{
                  paddingLeft: !content?.contentInContainer
                    ? `var(--contBxHPadding)`
                    : ``,
                  paddingRight: !content?.contentInContainer
                    ? `var(--contBxHPadding)`
                    : ``
                }}
              >
                <div className={styles.contentInner}>
                  <RenderIf isTrue={content?.title}>
                    <h1 style={{ whiteSpace: `pre-line` }}>{content?.title}</h1>
                  </RenderIf>
                  <RenderIf isTrue={content?.description}>
                    <p style={{ whiteSpace: `pre-line` }}>
                      {content?.description}
                    </p>
                  </RenderIf>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

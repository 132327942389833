import { useCart } from 'react-use-cart';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Toasts } from 'components/toasts';
import { AppStore } from 'store';
import { AXIOS_INSTANCE_ZEWARDS, Client } from 'db';
import styles from './index.module.css';
import { ZewardsStore } from 'store';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { PrimaryButton } from 'components/buttons/primary';
import { RestaurantStore } from 'store';

export const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { items, updateItemQuantity, removeItem } = useCart();
  const [totalCost, setTotalCost] = useState(0);

  const stripePromise = loadStripe(
    'pk_test_51Jk5lYHGLGO7qAwS0yREd2JCrAwLLT7Pnm2GgLqwRJGwdx2fFdDqmY0fBmUctZGmnN9CHjcCAkoatqJ6Ufr8dqCY00nUxiFs72'
  );

  const [stripeOptions, setStripeOptions] = useState({
    //passing the client secret obtained from the server
    clientSecret: location?.state?.clientSecret?.secret
  });

  useEffect(() => {
    if (location?.state?.clientSecret) {
      setStripeOptions({
        clientSecret: location?.state?.clientSecret?.secret
      });
      return;
    } else {
      navigate('/');
    }
  }, [location]);

  useEffect(() => {
    const totalCost = items.reduce((acc, curr) => {
      return acc + curr.cost * curr.quantity;
    }, 0);
    setTotalCost(totalCost);
  }, [items]);

  return (
    <>
      <article className={styles.article}>
        <div className={styles.contain}>
          <div style={{ marginTop: `50px` }}></div>
          <div className={`account_box`}>
            <div className="mt_20"></div>
            <h2 className={`t_up`}>
              <b>Checkout Page</b>
            </h2>
            <div className="mt_30"></div>
            <p className={`h6`}>Please provide your payment details below</p>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                stripeOptions={stripeOptions}
                orderId={location?.state?.orderId}
                tokenId={location?.state?.tokenId}
              />
            </Elements>
          </div>
        </div>
      </article>
    </>
  );
};

const CheckoutForm = ({ stripeOptions, orderId, tokenId }) => {
  const navigate = useNavigate();
  const { user, guestUser } = AppStore((state) => state);
  const { zewardsData, zewardsOrderAmount, zewardsPointsForRedemption } =
    ZewardsStore((state) => state);
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);

  const stripe = useStripe();
  const elements = useElements();

  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const clientSecret = stripeOptions?.clientSecret;
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user?.fullName || guestUser?.fullName
        }
      }
    });
    setPaymentLoading(false);
    if (paymentResult.error) {
      Toasts.error(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === 'succeeded') {
        await Client.post('/orders/token/expire', {
          id: orderId,
          tokenId: tokenId
        });

        if (zewardsPointsForRedemption >= 50) {
          await AXIOS_INSTANCE_ZEWARDS.post(`/customer/redeemPoints`, {
            customerId: zewardsData?.customerId,
            brandId: `${zewardsData?.brandId}`,
            branchId: `${zewardsData?.branchId}`,
            points: `${zewardsPointsForRedemption}`,
            loyaltyId: zewardsData?.loyaltyId,
            clientId: restaurantData?.theme?.theme?.buisnessId
          });
        }

        if (zewardsOrderAmount >= 50) {
          await AXIOS_INSTANCE_ZEWARDS.post(`/customer/addPoints`, {
            customerId: zewardsData?.customerId,
            brandId: `${zewardsData?.brandId}`,
            branchId: `${zewardsData?.branchId}`,
            orderAmount: `${Math.ceil(parseInt(zewardsOrderAmount))}`,
            loyaltyId: zewardsData?.loyaltyId
          });
        }

        Toasts.success('Payment Successful');
        navigate('/ordersuccess', {
          state: { message: 'Payment Successful' }
        });
      }
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={payMoney}>
          <div className={`mt_40`}></div>
          <CardElement
            className={styles.card}
            options={{
              style: {
                base: {
                  backgroundColor: 'var(--accentbg)'
                }
              }
            }}
          />
          <div className={`mt_40`}></div>
          <div className={`flex item_hcenter`}>
            <PrimaryButton type={`submit`} disabled={isPaymentLoading}>
              {isPaymentLoading ? 'Loading...' : 'Pay'}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { Slider } from 'rsuite';
import { RenderIf } from 'helpers';
import styles from './index.module.css';
import { Icon } from 'assets/icons';

export const ZewardBranchCard = ({
  branchLogo,
  title,
  zewards,
  disabled,
  min,
  max,
  defaultValue
}) => {
  const [selectedZewards, setSelectedZewards] = useState(defaultValue);

  return (
    <>
      <article className={disabled ? styles.disabled : ``}>
        <header className={styles.header}>
          <div>
            <div>
              <RenderIf isTrue={branchLogo}>
                <picture>
                  <source srcSet={branchLogo} />
                  <img src={branchLogo} alt={title} />
                </picture>
              </RenderIf>
              <h6>{title}</h6>
            </div>
            <div className={styles.zewards}>
              <Icon name={`zewst_coin`} />
              <div>
                <strong>{zewards}</strong>
              </div>
              <div>($300)</div>
            </div>
            <div>
              <div className={styles.perPoint}>1.5 ¢ per point</div>
            </div>
          </div>
          <div></div>
        </header>
        <div className={styles.body}>
          <div>
            <h3>
              <Icon name={`zewst_coin`} />
              <span className={`flight`}>{selectedZewards}</span>
            </h3>
          </div>
          <div>
            <Slider
              progress
              min={min}
              max={max}
              defaultValue={selectedZewards}
              onChange={(value) => {
                setSelectedZewards(value);
              }}
            />
          </div>
        </div>
      </article>
    </>
  );
};

import { Icon } from 'assets/icons';
import { RenderIf } from 'helpers';

export const ConversationList = ({ messages }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          width: '100%',
          maxHeight: 'calc(100vh - 273.5px)',
          overflowY: `auto`
          //alignItems: 'space-between'
        }}
      >
        {messages
          // ?.sort((a, b) => {
          //   return a?.index - b?.index;
          // })
          ?.filter((message) => message?.body !== '')
          ?.map((message, index) => {
            return (
              <div
                key={Math.random() * 1000000 + index}
                style={{
                  display: 'flex',
                  flexDirection:
                    message?.attributes?.sender !== 'customer'
                      ? 'row'
                      : 'row-reverse',
                  width: 'fit-content',
                  alignItems: 'center',
                  gap: 8,
                  marginLeft:
                    message?.attributes?.sender !== 'customer' ? 0 : 'auto'
                }}
              >
                {/* from here */}
                <div
                  style={{
                    width: 32,
                    height: 32,
                    padding: 3.5,
                    lineHeight: 0,
                    background: `var(--borderColor)`,
                    borderRadius: 32
                  }}
                >
                  <RenderIf isTrue={message?.attributes?.sender === 'agent'}>
                    <img
                      src="https://e7.pngegg.com/pngimages/906/448/png-clipart-silhouette-person-person-with-helmut-animals-logo-thumbnail.png"
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: 25
                        //remove image background
                      }}
                    />
                  </RenderIf>
                  <RenderIf isTrue={message?.attributes?.sender === 'bot'}>
                    <img
                      src="https://w7.pngwing.com/pngs/296/528/png-transparent-internet-bot-computer-icons-chatbot-twitter-bot-license-game-text-smiley.png"
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: 25
                        //remove image background
                      }}
                    />
                  </RenderIf>
                  <RenderIf isTrue={message?.attributes?.sender === 'customer'}>
                    <img
                      src="https://e7.pngegg.com/pngimages/906/448/png-clipart-silhouette-person-person-with-helmut-animals-logo-thumbnail.png"
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: 25
                        //remove image background
                      }}
                    />
                  </RenderIf>
                </div>
                {/* to here */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                    backgroundColor:
                      message?.attributes?.sender !== 'customer'
                        ? 'var(--borderColor)'
                        : 'var(--primary',
                    color:
                      message?.attributes?.sender !== 'customer'
                        ? 'black'
                        : `var(--accentForeGround)`,
                    borderRadius: 8,
                    padding: 8,
                    margin: 0
                  }}
                >
                  <RenderIf isTrue={message?.media}>
                    <img
                      src={message?.media}
                      alt="media"
                      width={300}
                      height={300}
                      style={{
                        borderRadius: 10,
                        margin: 0,
                        border: '1px solid black'
                      }}
                    />
                  </RenderIf>
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      maxWidth: '300px',
                      wordWrap: 'break-word',
                      display: 'flex',
                      flexDirection:
                        message?.attributes?.sender !== 'customer'
                          ? 'row'
                          : 'row-reverse',
                      alignItems: 'center',
                      gap: 8
                    }}
                  >
                    <RenderIf
                      isTrue={message?.attributes?.medium === 'whatsapp'}
                    >
                      <Icon name="whattsapp" />
                    </RenderIf>
                    <RenderIf
                      isTrue={message?.attributes?.medium === 'webchat'}
                    >
                      <img
                        src="https://www.svgrepo.com/show/324163/wechat-brand-assets-messenger-china-chat-oneline.svg"
                        style={{
                          width: 25,
                          height: 25
                        }}
                      />
                    </RenderIf>
                    <span>
                      <Message message={message} />
                    </span>
                  </div>
                  <div style={{ fontSize: '10px' }}>
                    {/* {message?.dateCreated?.toString()} */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

function Message({ message }) {
  const linkStart = message?.body?.indexOf('<a');
  const linkEnd = message?.body?.indexOf('</a>') + 4;
  const linkHTML = message?.body?.slice(linkStart, linkEnd);
  console.log('🚀 ~ Message ~ linkHTML:', linkHTML);
  const restMessage = message?.body?.replace(linkHTML, '');
  console.log('🚀 ~ Message ~ restMessage:', restMessage);

  const linkProps = {};
  if (linkStart !== -1) {
    const hrefStart = linkHTML?.indexOf('href=') + 6;
    const hrefEnd = linkHTML?.indexOf('>', hrefStart) - 1;
    linkProps.href = linkHTML?.slice(hrefStart, hrefEnd);
  }

  return (
    <div>
      {restMessage}
      {linkStart !== -1 && (
        <div
          style={{
            marginTop: '10px'
          }}
        >
          <a target="_blank" rel="noopener noreferrer" href={linkProps?.href}>
            {linkProps?.href}
          </a>
        </div>
      )}
    </div>
  );
}

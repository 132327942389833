import menuBurger from '../assets/images/burger.png';
import beefBurger from '../assets/images/beefburger.png';
import pizza from '../assets/images/pizza.png';
import image2 from '../assets/images/image2.png';
import image3 from '../assets/images/image3.png';
import authPage from '../assets/images/login.png';
import flagMaster from '../assets/images/flag_master.png';
import flagVisa from '../assets/images/flag_visa.png';
import flagAmerican from '../assets/images/flag_american.png';
import mearDukanLogo from '../assets/images/meat_dukan_logo.png';

export const Images = {
  menuBurger,
  beefBurger,
  pizza,
  image2,
  image3,
  authPage,
  flagMaster,
  flagVisa,
  flagAmerican,
  mearDukanLogo
};

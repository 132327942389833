import { useNavigate } from 'react-router-dom';
import { ZewardCard } from 'components/cards/reward';
import { ZewardBranchCard } from 'components/cards/branch';
import { Icon } from 'assets/icons';
import { Images } from 'constant';
import styles from './index.module.css';

export const AdvanceModeModal = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className={styles.header}>
        <div className={styles.contain}>
          <div className={styles.hGrid}>
            <div>
              <button
                className={`alink`}
                onClick={() =>
                  navigate(`/checkout`, {
                    state: {}
                  })
                }
                type={`button`}
              >
                <Icon name={`back_arrow`} />
              </button>
              <h4>Advance mode</h4>
            </div>
            <div>
              <button type={`button`} className={`button1`} onClick={() => {}}>
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className={styles.body}>
        <div className={styles.contain}>
          <div className={styles.bGrid}>
            <div>
              <ZewardCard
                cardColor={`#F0616E`}
                colorOpacity={0.13}
                headerMedia={Images?.mearDukanLogo}
                title={`Meat Dukan`}
              >
                <ZewardBranchCard
                  title={`Current Branch`}
                  zewards={10000}
                  min={0}
                  max={10000}
                  defaultValue={8333}
                />
                <div style={{ marginTop: `12px` }}></div>
                <ZewardBranchCard
                  title={`Current Branch`}
                  zewards={1000}
                  min={0}
                  max={1000}
                  defaultValue={0}
                  disabled
                />
              </ZewardCard>
              <ZewardCard
                cardColor={`#A561D8`}
                colorOpacity={0.13}
                headerMedia={Images?.mearDukanLogo}
                title={`Out Of Network`}
              >
                <ZewardBranchCard
                  title={`KFC`}
                  zewards={9000}
                  min={0}
                  max={9000}
                  defaultValue={0}
                  disabled
                />
                <div style={{ marginTop: `12px` }}></div>
                <ZewardBranchCard
                  title={`McDonalds`}
                  zewards={9000}
                  min={0}
                  max={9000}
                  defaultValue={0}
                  disabled
                />
              </ZewardCard>
            </div>
            <div>
              <div className={styles.totalBox}>
                <div>
                  <div>
                    <strong>Total Bill</strong>
                  </div>
                  <h4>
                    <strong>$125</strong>
                  </h4>
                </div>
                <div>
                  <div>
                    <strong>Total Zewards Redeeming</strong>
                  </div>
                  <div style={{ marginTop: `3px` }}></div>
                  <h3>
                    <Icon name={`zewst_coin`} />
                    <span className={`flight`}>8333</span>
                  </h3>
                  <div style={{ marginTop: `7px` }}></div>
                  <h3>
                    <span>=</span>
                    <span>
                      <Icon name={`dollar_symbol`} />
                    </span>
                    <span className={`flight`}>125</span>
                  </h3>
                </div>
                <div>
                  <p>
                    <i>From</i>
                  </p>
                  <div style={{ marginTop: `3px` }}></div>
                  <p>Current Branch</p>
                  <div style={{ marginTop: `3px` }}></div>
                  <p>
                    <Icon name={`zewst_coin`} />
                    <span>8333</span>
                  </p>
                  <div>$125</div>
                </div>
                <div>
                  <button type={`button`}>Apply Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { useNavigate } from 'react-router-dom';
import { OrderStore } from 'store';
import { PrimaryButton } from 'components/buttons/primary';
import { SecondaryButton } from 'components/buttons/secondary';
import styles from './index.module.css';
import { useEffect, useMemo, useState } from 'react';
import { useCart } from 'react-use-cart';
import { SelectedBranchStore } from 'store';
import { RestaurantStore } from 'store';

export const OrderType = () => {
  const navigate = useNavigate();
  const { setDeliveryType } = OrderStore((state) => state);
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);

  const [dataToRender, setDataToRender] = useState([]);
  const { items } = useCart();
  const { branchWithCartItems } = SelectedBranchStore((state) => state);

  useMemo(() => {
    let tempdata = [];
    tempdata = restaurantData?.brand?.branches?.map((b) => ({
      ...b,
      markup: 0,
      parentBranch: b?._id,
      branchTax: b?.tax
    }));
    setDataToRender(tempdata || []);
  }, [restaurantData]);

  const handleNavigation = () => {
    if (restaurantData?.brand?.brandType === 'CLOUD') {
      navigate('/cloud-brand-branches');
    } else {
      navigate('/brand-orders', {
        state: {
          branches: restaurantData?.brand?.branches?.map((b) => ({
            ...b,
            markup: 0,
            parentBranch: b?._id,
            branchTax: b?.tax
          }))
        }
      });
    }
  };

  useEffect(() => {
    if (
      restaurantData?.brand?.branches?.length > 1 &&
      branchWithCartItems &&
      items.length > 0
    ) {
      navigate(`/brand-menu`, {
        state: branchWithCartItems
      });
    }
  }, [dataToRender, branchWithCartItems, items, restaurantData]);

  return (
    <article className={styles.article}>
      <div className={`box3`}>
        <div className={styles.order_types}>
          <h2 className={`t_up ${styles.title}`}>
            Find a store to get started
          </h2>
          <div className={`mt_20`}></div>
          <div className={`h5`}>
            Choose order type delivery, pickup or dine in, find a restaurant
            near you and enjoy your meal.
          </div>
          <div className={`mt_40`}></div>
          <div className={`pt_10`}></div>
          <div className={styles.buttons}>
            <PrimaryButton
              handleClick={() => {
                setDeliveryType('delivery');
                handleNavigation();
              }}
            >
              Delivery
            </PrimaryButton>
            <SecondaryButton
              handleClick={() => {
                setDeliveryType('pickup');
                handleNavigation();
              }}
            >
              Pick-up
            </SecondaryButton>
          </div>
        </div>
      </div>
      {/* <div className={`mt_40`}></div>
      <div className={`pt_40`}></div> */}
      {/* <RenderIf isTrue={dataToRender?.cloudBrandsInfo?.length > 0}>
        <div className={`box3`}>
          <h2 className={`t_up`}>Product Recommendation</h2>
        </div>
        <div className="mt_15" />
        <Prodscarousel
          prods={dataToRender?.cloudBrandsInfo
            ?.map((cloudBrand, index) => {
              return cloudBrand?.branch?.categories?.map((category, idx) => {
                return category?.categoryTypes?.map((categoryType, ind) => {
                  return categoryType?.items?.map((item, i) => {
                    return item;
                  });
                });
              });
            })
            ?.flat(3)}
        />
        <div className={`mt_40`}></div>
        <div className={`pt_40`}></div>
      </RenderIf> */}
    </article>
  );
};

import Modal from 'react-modal';
import { Icon } from 'assets/icons';
import styles from './index.module.css';

export const ModalComponent = ({
  children,
  modal,
  handleModal,
  contentLabel,
  modalMiddle = false,
  rightModal = true,
  chooseOption = false,
  closeOver = false,
  maxWidth,
  maxWidthAuto = false,
}) => {
  Modal.setAppElement('#root');

  return (
    <>
      <Modal
        isOpen={modal}
        onRequestClose={handleModal}
        contentLabel={contentLabel ? contentLabel : 'Modal'}
        style={{
          overlay: {
            backgroundColor: rightModal
              ? 'var(--bgOverlay1)'
              : 'var(--bgOverlay)',
            display: 'flex',
            justifyContent: rightModal ? 'flex-end' : 'center',
            alignItems: modalMiddle ? 'center' : 'flex-start',
            overflowY: 'auto',
            padding: rightModal ? 0 : '20px 0',
            zIndex: 'var(--modalZIndex)'
          },
          content: {
            inset: 'unset',
            backgroundColor: 'var(--white)',
            border: chooseOption ? '1px solid #edf2f7' : 0,
            borderRadius: rightModal ? 0 : chooseOption ? 10 : 8,
            padding: rightModal ? `20px 40px` : chooseOption ? 32 : 0,
            width: 'calc(100vw - 40px)',
            maxWidth: rightModal ? 620 : chooseOption ? 494 : maxWidthAuto ? 'auto' : maxWidth ? maxWidth : 616,
            minHeight: rightModal ? '100%' : 'auto',
            top: rightModal ? 0 : 'auto',
            color: 'var(--dark)',
            overflow: 'visible'
          }
        }}
      >
        <div
          className={
            rightModal
              ? styles.closeR
              : closeOver
              ? styles.closeOver
              : styles.close
          }
        >
          <button className={styles.close_button} onClick={handleModal}>
            <Icon name={`close`} />
          </button>
        </div>
        <div>{children}</div>
      </Modal>
    </>
  );
};

import PhoneInput from "react-phone-number-input";
import { RenderIf } from "helpers";
import styles from './index.module.css';

export const PhoneField = ({
  label,
  id,
  placeholder,
  field,
  error,
  defaultCountry = `US`,
  info,
  infoChild,
  required = false,
  readOnly = false,
}) => {
  return (
    <>
      <div className={styles.field_box}>
        <RenderIf isTrue={label}>
          <div className={styles.labelBox}>
            <label htmlFor={id} className={styles.label}>
              <strong>{label}</strong>
            </label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  {/* <Ikon info /> */}
                  <div className={styles.infoChild}>
                    {/* <Ikon indicatorArrow /> */}
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </RenderIf>
        <div className={styles.inputbox}>
          <RenderIf isTrue={required}>
            <span className={styles.required}>*</span>
          </RenderIf>
          <PhoneInput
            readOnly={readOnly}
            placeholder={placeholder}
            {...field}
            defaultCountry={defaultCountry}
            numberInputProps={{
              className: 'CustomPhoneInputInput'
            }}
            containerComponentProps={{
              className: 'CustomPhoneInput'
            }}
          />
        </div>
        <RenderIf isTrue={error}>
          <div className={styles.error}>
            {error}
          </div>
        </RenderIf>
      </div>
    </>
  );
}

export const PhoneFieldWithoutHookForm = ({
  label,
  id,
  name,
  placeholder,
  error,
  defaultCountry = `US`,
  onChange,
  info,
  infoChild,
  required = false,
  readOnly = false,
}) => {
  return (
    <>
      <div className={styles.field_box}>
        <RenderIf isTrue={label}>
          <div className={styles.labelBox}>
            <label htmlFor={id} className={styles.label}>
              <strong>{label}</strong>
            </label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  {/* <Ikon info /> */}
                  <div className={styles.infoChild}>
                    {/* <Ikon indicatorArrow /> */}
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </RenderIf>
        <div className={styles.inputbox}>
          <RenderIf isTrue={required}>
            <span className={styles.required}>*</span>
          </RenderIf>
          <PhoneInput
            readOnly={readOnly}
            name={name}
            placeholder={placeholder}
            defaultCountry={defaultCountry}
            onChange={onChange}
            numberInputProps={{
              className: 'CustomPhoneInputInput'
            }}
            containerComponentProps={{
              className: 'CustomPhoneInput'
            }}
          />
        </div>
        <RenderIf isTrue={error}>
          <div className={styles.error}>
            {error}
          </div>
        </RenderIf>
      </div>
    </>
  );
}

import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WebFont from 'webfontloader';
import { Layout } from 'layout';
import { HomePage } from './pages/home';
import { BrandsOrderNowPage } from 'pages/brandorders';
import { BrandMenuPage } from 'pages/brandmenu';
import { MenuDetailPage } from 'pages/brandmenu/detail';
import { CartProvider } from 'react-use-cart';
import { CartPage } from 'pages/cart';
import { AccountPage } from 'pages/account';
import { CheckoutPage } from 'pages/checkout';
import { AdvanceModeModal } from 'pages/checkout/advancemode';
import { PaymentPage } from 'pages/payment';
import { OrderSuccessPage } from 'pages/ordersuccess';
import { CloudBrandsListingPage } from 'pages/cloudbrands/index';
import { CloudBrandMenu } from 'pages/cloudbrands/cloudbrandmenu';
import { OrderType } from 'pages/ordertype';
import Deals from 'pages/deals';
import { MenuPage } from 'pages/menu';
import { AppStore, AuthPageStore } from 'store';
import { ZewardsWallet } from 'pages/zewardswallet';
import { OrderLink } from 'pages/orderlink';
import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { RestaurantStore } from 'store';
import { PageNotFound } from 'pages/oops';
import { Client } from 'db';
import { fetchDeliveryEnabled } from 'helpers';

function App() {
  const { setRestaurantData, setDeliveryEnabled } = RestaurantStore(
    (state) => state
  );

  const { resData } = useFetchRestaurantHook('restaurant', false);

  const { user } = AppStore((state) => state);
  const { setPage } = AuthPageStore((state) => state);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--primary',
      `${resData?.theme?.global?.style?.button?.primary?.backgroundColor}`
    );
    document.documentElement.style.setProperty(
      '--background-color',
      `${resData?.theme?.global?.style?.backgroundColor}`
    );
    document.documentElement.style.setProperty(
      '--foreground-color',
      `${resData?.theme?.global?.style?.foregroundColor}`
    );
    document.documentElement.style.setProperty(
      '--header-font-family',
      `${resData?.theme?.global?.style?.headerFont}`
    );
    document.documentElement.style.setProperty(
      '--body-font-family',
      `${resData?.theme?.global?.style?.bodyFont} || 'Poppins', sans-serif`
    );
    document.documentElement.style.setProperty(
      '--button-font-family',
      `${resData?.theme?.global?.style?.buttonFont}`
    );
  });

  useEffect(() => {
    if (!user) {
      setPage('createAccount');
    } else {
      setPage('account');
    }
  }, [user, setPage]);

  WebFont.load({
    google: {
      families: [
        `${resData?.theme?.global?.style?.headerFont}`,
        `${resData?.theme?.global?.style?.bodyFont}`,
        `${resData?.theme?.global?.style?.buttonFont}`
      ]
    }
  });

  useEffect(() => {
    if (resData) {
      setRestaurantData(resData);
      fetchDeliveryEnabled(
        resData?.brand?.branches?.[0]?._id,
        setDeliveryEnabled
      );
    }
  }, [resData]);

  return (
    <>
      <CartProvider
        id="storecart"
        onItemAdd={(item) => console.log(`Item ${item.id} added!`)}
        onItemUpdate={(item) => console.log(`Item ${item.id} updated.!`)}
        onItemRemove={() => console.log(`Item removed!`)}
      >
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/deals" element={<Deals />} />
              <Route path="/order-type" element={<OrderType />} />
              <Route path="/brand-orders" element={<BrandsOrderNowPage />} />
              <Route path="/brand-menu" element={<BrandMenuPage />} />
              <Route path="/brand-menu/:id" element={<MenuDetailPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/advance-mode" element={<AdvanceModeModal />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/ordersuccess" element={<OrderSuccessPage />} />
              <Route
                path="/cloud-brand-branches"
                element={<CloudBrandsListingPage />}
              />
              <Route
                path="/cloud-brand-branch-menu"
                element={<CloudBrandMenu />}
              />
              <Route path="/zewards-wallet" element={<ZewardsWallet />} />
              <Route path="/order-link" element={<OrderLink />} />
              <Route path="/page-not-found" element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </CartProvider>
      {/* <ToastContainer /> */}
    </>
  );
}

export default App;

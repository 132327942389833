import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import styles from './index.module.css';
import { PrimaryButton } from 'components/buttons/primary';

export const OrderSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [totalCost, setTotalCost] = useState(0);

  const { items, emptyCart } = useCart();

  useEffect(() => {
    const totalCost = items.reduce((acc, curr) => {
      return acc + curr.cost * curr.quantity;
    }, 0);
    setTotalCost(totalCost);
  }, [items]);

  useEffect(() => {
    return () => emptyCart();
  }, []);

  useEffect(() => {
    if (location?.state?.message === 'Payment Successful') {
      return;
    } else {
      navigate('/');
    }

    return () => {
      emptyCart();
    };
  }, [location]);

  return (
    <article className={styles.article}>
      <div className={`box3`}>
        <div className={`mt_40`}></div>
        <div className={`pt_40`}></div>
        <div className={`align_center`}>
          <h1>Thank You</h1>
          <div className={`mt_40`}></div>
          <div className={`h5`}>
            Your following order has been successfully processed, thanks for
            being a part of us!!
          </div>
        </div>
        <div className={`mt_40`}></div>
        {items?.map((item, index) => {
          return (
            <div key={index} className={styles.item1}>
              <picture>
                <source srcSet={item?.image} />
                <img src={item?.image} alt="categoryType" />
              </picture>
              <div className={styles.item1_content}>
                <div className={styles.flex_row1}>
                  <div>
                    <h5 className={`t_up`}>{item?.item}</h5>
                    <div className={`mt_5`}>{item?.description}</div>
                  </div>
                  <div>
                    <div>
                      Price: <b>${item?.cost}</b>
                    </div>
                    <div className={`mt_5`}>
                      Quantity: <b>{item?.quantity}</b>
                    </div>
                  </div>
                </div>
                <div className={`mt_16`}></div>
                <hr />
                <div className={`mt_16`}></div>
                <div>{item?.tags?.join(', ')}</div>
              </div>
            </div>
          );
        })}
        <div className={`align_center h5 mt_40`}>
          Total Cost:{' '}
          <b>
            $
            {parseFloat(
              (
                totalCost +
                parseFloat(
                  (totalCost * (items?.[0]?.branchTax / 100))?.toFixed(2)
                )
              )?.toFixed(2)
            )}
          </b>
        </div>
        <div className={`pt_20`}></div>
        <div className={`flex item_hcenter mt_40`}>
          <PrimaryButton 
            type={`button`} 
            handleClick={() => {
              emptyCart();
              navigate('/');
            }}
          >Continue orders</PrimaryButton>
        </div>
        <div className={`mt_40`}></div>
        <div className={`pt_40`}></div>
      </div>
    </article>
  );
};

import axios from 'axios';

export const AXIOS_INSTANCE_ZEWARDS = axios.create({
  baseURL: 'https://zewards-backend-dev-549aeb29b9c6.herokuapp.com'
});

export const Client = axios.create({
  baseURL: 'https://customer-api.zewst.com' //'https://zewst-customer-service-dev-2ed95c2deba4.herokuapp.com'
});

export const PORTAL_BACKEND_URL = axios.create({
  baseURL: 'https://backend-v3-api.zewst.com'
});

export const DELIVERY_BACKEND_URL = axios.create({
  baseURL: 'https://zewst-delivery-backend-d0ea3625d733.herokuapp.com'
});

export const REACT_APP_RESTAURANT_ID = process.env.REACT_APP_RESTAURANT_ID;

import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { RenderIf, fetchDeliveryPrice } from 'helpers';
import { RestaurantStore, ClientOrderStore } from 'store';
import { Icon } from 'assets/icons';
import { ModalComponent } from 'components/modal';
import { PrimaryButton } from 'components/buttons/primary';
import styles from './index.module.css';
import { AppStore } from 'store';

const GOOGLE_API_KEY = 'AIzaSyBDcTZIYesCZgSyqyOtnjFbT0kFJgt4tns';

export const OrderTypeUserLocation = () => {
  const { restaurantData, deliveryEnabled, setDeliveryFee } = RestaurantStore(
    (state) => state
  );
  const { user } = AppStore((state) => state);

  const { orderType, setOrderType, orderAddress, setOrderAddress } =
    ClientOrderStore((state) => state);
  const style = restaurantData?.theme?.header?.style;
  const globalStyle = restaurantData?.theme?.global?.style;
  const ref = useRef(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const [selection, setSelection] = useState(null);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (deliveryEnabled) {
      if ('geolocation' in navigator) {
        getUsersCurrentLocation();
      } else {
      }
    } else {
      setOrderType('Pickup');
      setDeliveryFee(0);
    }
  }, [deliveryEnabled]);

  const getUsersCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          handleLocationChange({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              'We need your location for this feature. Please enable location permissions for this website in your browser settings.'
            );
          }
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  const getLatLngFromAddress = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setCenter(latLng);
    } catch (error) {
      console.error('🚀 ~ PageNotFound ~ error:', error);
    }
  };

  useEffect(() => {
    if (selection) {
      getLatLngFromAddress(selection);
    }
  }, [selection]);

  const handleLocationChange = async (latLng) => {
    setCenter(latLng);
    const addressResponse = (
      await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${GOOGLE_API_KEY}`
      )
    )?.data;
    setOrderAddress(addressResponse?.results?.[0]?.formatted_address);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setDropdownActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <article className={styles.article}>
        <RenderIf isTrue={orderType === `Delivery`}>
          <div
            className={styles.inputCtrl}
            style={{
              backgroundColor:
                style?.backgroundColor || globalStyle?.backgroundColor
            }}
          >
            <div className={styles.addressIcon}>
              <Icon name={`user-location`} fillColor={`var(--primary)`} />
            </div>
            <PlacesAutocomplete
              value={orderAddress}
              onChange={(address) => {
                setOrderAddress(address);
              }}
              onSelect={(selection) => {
                setSelection(selection);
                setOrderAddress(selection);
                setLocationModalOpen(true);
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <>
                  <input
                    {...getInputProps({
                      placeholder: 'Search Places ...',
                      className: styles.input
                    })}
                    style={{
                      color:
                        style?.foregroundColor || globalStyle?.foregroundColor,
                      backgroundColor: 'transparent'
                    }}
                  />
                  <RenderIf isTrue={suggestions?.length > 0}>
                    <div
                      className={styles.autoCompleteDd}
                      style={{
                        background:
                          style?.foregroundColor ||
                          globalStyle?.foregroundColor,
                        color:
                          style?.backgroundColor || globalStyle?.backgroundColor
                      }}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? {
                              cursor: 'pointer',
                              padding: '8px',
                              background: 'rgba(0, 0, 0, 0.16)'
                            }
                          : {
                              cursor: 'pointer',
                              padding: '8px',
                              background: 'transparent'
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </RenderIf>
                </>
              )}
            </PlacesAutocomplete>
            <div className={styles.locateBtn}>
              <button
                type={`button`}
                className={styles.buttonLocate}
                onClick={() => {
                  getUsersCurrentLocation();
                  setLocationModalOpen(true);
                }}
                title={'Locate me'}
              >
                <Icon
                  name={`locateme`}
                  fillColor={
                    style?.foregroundColor || globalStyle?.foregroundColor
                  }
                />
              </button>
            </div>
          </div>
        </RenderIf>
        <div
          ref={ref}
          className={styles.btnCtrl}
          style={{
            backgroundColor:
              style?.backgroundColor || globalStyle?.backgroundColor
          }}
        >
          <button
            type={'button'}
            className={styles.valButton}
            onClick={() => {
              if (deliveryEnabled) setDropdownActive(!dropdownActive);
            }}
            style={{
              color: style?.foregroundColor || globalStyle?.foregroundColor
            }}
          >
            <div>
              <Icon name={orderType} />
              <div>{orderType}</div>
            </div>
            <RenderIf isTrue={deliveryEnabled}>
              <Icon
                name={'bottom-cert'}
                fillColor={
                  style?.foregroundColor || globalStyle?.foregroundColor
                }
              />
            </RenderIf>
          </button>
          <RenderIf isTrue={dropdownActive}>
            <div className={styles.listBtnDd}>
              <RenderIf isTrue={orderType !== 'Delivery' && deliveryEnabled}>
                <button
                  type={'button'}
                  className={styles.listButton}
                  onClick={() => {
                    setOrderType('Delivery');
                    setDropdownActive(false);
                  }}
                  style={{
                    color:
                      style?.foregroundColor || globalStyle?.foregroundColor,
                    backgroundColor:
                      style?.backgroundColor || globalStyle?.backgroundColor
                  }}
                >
                  <Icon name={'Delivery'} />
                  <div>Delivery</div>
                </button>
              </RenderIf>
              <RenderIf isTrue={orderType !== 'Pickup'}>
                <button
                  type={'button'}
                  className={styles.listButton}
                  onClick={() => {
                    setOrderType('Pickup');
                    setDropdownActive(false);
                    setDeliveryFee(0);
                  }}
                  style={{
                    color:
                      style?.foregroundColor || globalStyle?.foregroundColor,
                    backgroundColor:
                      style?.backgroundColor || globalStyle?.backgroundColor
                  }}
                >
                  <Icon name={'Pickup'} />
                  <div>Pickup</div>
                </button>
              </RenderIf>
            </div>
          </RenderIf>
        </div>
      </article>
      <ModalComponent
        modal={locationModalOpen}
        handleModal={() => {
          setLocationModalOpen(false);
        }}
        rightModal={false}
        modalMiddle={true}
      >
        <div className={styles.modalBx}>
          <header className={styles.mdlHeader}>
            <div>
              <h4>Is this your exact location?</h4>
            </div>
            <div>
              <p>
                Providing your location enables more accurate search and
                delivery ETA, seamless order tracking and personalised
                recommendations.
              </p>
            </div>
          </header>

          <RenderIf isTrue={center?.lat}>
            <div style={{ height: '360px', width: '100%' }}>
              <APIProvider apiKey={GOOGLE_API_KEY}>
                <Map
                  key={center?.lat}
                  center={center}
                  defaultZoom={16}
                  // defaultCenter={center}
                  gestureHandling={'greedy'}
                >
                  <Marker
                    position={center}
                    draggable={true}
                    onDragEnd={(e) => {
                      handleLocationChange(e.latLng.toJSON());
                    }}
                  />
                </Map>
              </APIProvider>
            </div>
          </RenderIf>

          <footer className={styles.mdlFooter}>
            <div>
              <Icon name={`user-location`} fillColor={`var(--primary)`} />
              <PlacesAutocomplete
                value={orderAddress}
                onChange={(address) => {
                  setOrderAddress(address);
                }}
                onSelect={(selection) => {
                  setSelection(selection);
                  setOrderAddress(selection);
                  setLocationModalOpen(true);
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <>
                    <textarea
                      cols={1}
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className: styles.textarea
                      })}
                    >
                      {orderAddress}
                    </textarea>
                    <RenderIf isTrue={suggestions?.length > 0}>
                      <div
                        className={styles.autoCompleteDd}
                        style={{
                          background:
                            style?.foregroundColor ||
                            globalStyle?.foregroundColor,
                          color:
                            style?.backgroundColor ||
                            globalStyle?.backgroundColor
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          const style = suggestion.active
                            ? {
                                cursor: 'pointer',
                                padding: '8px',
                                background: 'rgba(0, 0, 0, 0.16)'
                              }
                            : {
                                cursor: 'pointer',
                                padding: '8px',
                                background: 'transparent'
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </RenderIf>
                  </>
                )}
              </PlacesAutocomplete>
              {/* <h6>{orderAddress}</h6> */}
            </div>
            <div>
              <PrimaryButton
                handleClick={() => {
                  setLocationModalOpen(false);
                }}
                buttonFull={true}
              >
                Confirm
              </PrimaryButton>
            </div>
          </footer>
        </div>
      </ModalComponent>
    </>
  );
};

import { useEffect } from 'react';
import { RenderIf } from 'helpers';
import styles from './index.module.css';

function hexToRGBA(h, a) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')';
}

export const ZewardCard = ({
  cardColor,
  headerMedia,
  title,
  children,
  colorOpacity
}) => {
  let rGBA = hexToRGBA(cardColor, colorOpacity);

  useEffect(() => {
    rGBA = hexToRGBA(cardColor, colorOpacity);
  }, [cardColor, colorOpacity]);

  return (
    <>
      <article className={styles.article}>
        <header className={styles.header} style={{ background: cardColor }}>
          <div>
            <RenderIf isTrue={headerMedia}>
              <picture>
                <source srcSet={headerMedia} />
                <img src={headerMedia} alt={title} />
              </picture>
            </RenderIf>
            <RenderIf isTrue={title}>
              <h4>
                <strong>{title}</strong>
              </h4>
            </RenderIf>
          </div>
          <div></div>
        </header>
        <div
          className={styles.body}
          style={{
            background: rGBA
          }}
        >
          {children}
        </div>
      </article>
    </>
  );
};

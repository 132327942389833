import { TextFieldWithoutHookForm } from 'components/input';
import { useMemo, useState, useEffect } from 'react';
import { RenderIf } from 'helpers';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
// import { RestaurantStore } from 'store';
import { useCart } from 'react-use-cart';
import { Fetching } from 'components/loading';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { SelectedBranchStore } from 'store';
import { OrderStore } from 'store';

export const BrandsOrderNowPage = () => {
  const { items } = useCart();

  const navigate = useNavigate();
  const location = useLocation();
  const { branches } = location.state;

  // const [orderType, setOrderType] = useState('delivery');
  const { deliveryType } = OrderStore((state) => state);
  const [dataToRender, setDataToRender] = useState([]);
  const [fetchingPostalCode, setFetchingPostalCode] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  // const [searchValue, setSearchValue] = useState(false);
  const { branchWithCartItems, setBranchWithCartItems } = SelectedBranchStore(
    (state) => state
  );

  useMemo(() => {
    setDataToRender(branches || []);
  }, [branches]);

  useEffect(() => {
    if (branches?.length === 1) {
      navigate(`/brand-menu`, {
        state: {
          branch: dataToRender?.[0],
          orderType: deliveryType,
          markup: dataToRender?.[0]?.branch?.markup,
          parentBranch: dataToRender?.[0]?.parentBranch,
          branchTax: dataToRender?.[0]?.branch?.tax
        }
      });
    }

    if (branches?.length > 1 && branchWithCartItems && items.length > 0) {
      navigate(`/cloud-brand-branch-menu`, {
        state: branchWithCartItems
      });
    }
  }, [dataToRender, branches, branchWithCartItems, items]);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value.length > 0) {
      // setSearchValue(true);
      const filteredData = branches?.filter((branch) => {
        return branch?.address?.zipCode
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setDataToRender(filteredData);
    } else {
      setDataToRender(branches);
      // setSearchValue(false);
    }
  };

  const searchViaIpAddress = () => {
    setFetchingPostalCode(true);
    let apiKey = 'be0f755b93290b4c100445d77533d291763a417c75524e95e07819ad';
    axios
      .get('https://api.ipdata.co?api-key=' + apiKey)
      .then((response) => {
        const postalCode = response.data.postal;
        const filteredData = branches?.filter((branch) => {
          return branch?.address?.zipCode.toLowerCase().includes(postalCode);
        });
        setDataToRender(filteredData);
        setPostalCode(postalCode);
        setFetchingPostalCode(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <article className={styles.article}>
      <div className={`box3`}>
        <div className={`mt_40`}></div>
        <RenderIf isTrue={items.length > 0}>
          <div className={`t_center ${styles.box1}`}>
            <h2 className={`t_up`}>You already have items in your cart</h2>
            <div className={`mt_40`}></div>
            <p className={`h5 ${styles.c_h5}`}>
              You already have items in your cart from a branch. Please checkout
              first or clear your cart
            </p>
          </div>
        </RenderIf>
        <RenderIf isTrue={items.length === 0}>
          <div className={`align_center ${styles.container_2}`}>
            <h2 className={`t_up`}>Enter zip code to find food near you</h2>
            <div className={`mt_15`}></div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className={`mt_25`}></div>
            {/* <div className={styles.buttons}>
              <button
                className={
                  orderType === 'delivery' ? `button2 active` : `button2`
                }
                onClick={() => setOrderType('delivery')}
              >
                Delivery
              </button>
              <button
                className={
                  orderType === 'pickup' ? `button2 active` : `button2`
                }
                onClick={() => setOrderType('pickup')}
              >
                Pickup
              </button>
              <button
                className={
                  orderType === 'dine-in' ? `button2 active` : `button2`
                }
                onClick={() => setOrderType('dine-in')}
              >
                Dine In
              </button>
            </div>
            <div className={`mt_15`}></div> */}
            <div className={styles.search_box}>
              <div>
                <TextFieldWithoutHookForm
                  placeholder="Enter a zipcode to search branches near you"
                  name="search"
                  onChange={handleSearch}
                />
                <button
                  type={`button`}
                  className={`button7 ${styles.button7}`}
                  onClick={searchViaIpAddress}
                  disabled={fetchingPostalCode}
                >
                  <RenderIf isTrue={fetchingPostalCode}>
                    <span className={`icon`}>
                      <Fetching />
                    </span>
                  </RenderIf>
                  <RenderIf isTrue={!fetchingPostalCode}>GO</RenderIf>
                </button>

                <RenderIf isTrue={postalCode}>
                  <button
                    type={`button`}
                    className={`button7 ${styles.b_close}`}
                    onClick={() => {
                      setDataToRender(branches);
                      setPostalCode('');
                    }}
                  >
                    <span className={`icon`}>
                      <Icon name={`close`} />
                    </span>
                  </button>
                </RenderIf>
              </div>
            </div>
            <RenderIf isTrue={dataToRender?.length === 0}>
              <div className={`mt_20`}>
                <div className={`no_data1`}>
                  <span className={`fsemi`}>No Branches Found</span>
                </div>
              </div>
            </RenderIf>
          </div>
          <div className={`mt_40`}></div>
          <div className={`pt_40`}></div>
          <RenderIf isTrue={dataToRender?.length}>
            <div className={styles.branches_list}>
              {dataToRender
                ?.filter((branch) => branch?.categories?.length > 0)
                ?.map((branch, index) => (
                  <button
                    type={`button`}
                    className={styles.button_item}
                    key={index}
                    onClick={() => {
                      setBranchWithCartItems({
                        branch,
                        orderType: deliveryType,
                        markup: branch?.markup,
                        parentBranch: branch?.parentBranch,
                        branchTax: branch?.tax
                      });
                      navigate(`/brand-menu`, {
                        state: {
                          branch,
                          orderType: deliveryType,
                          markup: branch?.markup,
                          parentBranch: branch?.parentBranch,
                          branchTax: branch?.tax
                        }
                      });
                    }}
                  >
                    <div className={styles.b_header}>
                      <picture>
                        <source srcSet={branch?.branchImage} />
                        <img
                          src={
                            branch?.branchImage ||
                            'https://w1.pngwing.com/pngs/610/214/png-transparent-cafe-coffee-bakery-restaurant-drawing-cafeteria-food-toy.png'
                          }
                          alt="branch"
                        />
                      </picture>
                      <div className={`h5`}>
                        <strong>{branch?.branchName}</strong>
                      </div>
                    </div>
                    <div className={styles.b_content}>
                      <div className={`h6 mt_5`}>
                        {branch?.address?.addressLine1
                          ? branch?.address?.addressLine1?.line1 +
                            ' ' +
                            branch?.address?.addressLine1?.line2
                          : '...'}
                      </div>
                    </div>
                    <div className={styles.b_content}>
                      <div className={`h6`}>
                        <strong>Location </strong>
                        <strong className={styles.c_h5_2}>
                          {branch?.address?.city}
                        </strong>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          </RenderIf>
        </RenderIf>
        <div className={`mt_40`}></div>
        <div className={`pt_40`}></div>
      </div>
    </article>
  );
};

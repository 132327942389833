import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import styles from './index.module.css';
import { PrimaryButton } from 'components/buttons/primary';

export const OrderLink = () => {
  const location = useLocation();
  const { emptyCart } = useCart();

  useEffect(() => {
    return () => emptyCart();
  }, []);

  return (
    <article className={styles.article}>
      <div className={styles.contain}>
        <div style={{ marginTop: 50 }}></div>
        <div className={styles.item}>
          <PrimaryButton
            handleClick={() => {
              emptyCart();
              window.open(location?.state?.url, '_blank');
            }}
          >
            Pay your payment with ZEWST payment
          </PrimaryButton>
        </div>
      </div>
    </article>
  );
};

import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons/secondary';
import styles from './index.module.css';
import ImgPageNotFound from 'assets/images/page_not_found.png';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import { useEffect, useState } from 'react';
import { RenderIf } from 'helpers';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import axios from 'axios';

const GOOGLE_API_KEY = 'AIzaSyBDcTZIYesCZgSyqyOtnjFbT0kFJgt4tns';

export const PageNotFound = () => {
  const navigate = useNavigate();

  const [address, setAddress] = useState('');
  const [selection, setSelection] = useState(null);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if ('geolocation' in navigator) {
      console.log('Available');
      getUsersCurrentLocation();
    } else {
      console.log('Not Available');
    }
  }, []);

  const getUsersCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log('Latitude is :', position.coords.latitude);
          console.log('Longitude is :', position.coords.longitude);
          handleLocationChange({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              'We need your location for this feature. Please enable location permissions for this website in your browser settings.'
            );
          }
        }
      );
    } else {
      console.log('Not Available');
      alert('Geolocation is not supported by your browser.');
    }
  };

  const getLatLngFromAddress = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      console.log('🚀 ~ PageNotFound ~ latLng:', latLng);
      setCenter(latLng);
    } catch (error) {
      console.error('🚀 ~ PageNotFound ~ error:', error);
    }
  };

  useEffect(() => {
    if (selection) {
      getLatLngFromAddress(selection);
    }
  }, [selection]);

  const handleLocationChange = async (latLng) => {
    setCenter(latLng);

    const addressResponse = (
      await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${GOOGLE_API_KEY}`
      )
    )?.data;
    console.log(
      '🚀 ~ handleLocationChange ~ addressResponse:',
      addressResponse?.results
    );

    setAddress(addressResponse?.results?.[0]?.formatted_address);
  };

  return (
    <>
      <article className={styles.article}>
        <div className={`box3`}>
          <div className={styles.bx}>
            <div>
              <h1>Oops!</h1>
              <h2>Something went wrong</h2>
              <p>Don't worry, some nice text.</p>
              <div>
                <SecondaryButton
                  handleClick={() => {
                    navigate('/');
                  }}
                >
                  Go to Home
                </SecondaryButton>
              </div>
            </div>
            <picture>
              <source srcSet={ImgPageNotFound} />
              <img src={ImgPageNotFound} alt="404" />
            </picture>
          </div>
        </div>
      </article>

      <PlacesAutocomplete
        value={address}
        onChange={(address) => {
          console.log('🚀 ~ PageNotFound ~ address:', address);
          setAddress(address);
        }}
        onSelect={(selection) => {
          console.log('🚀 ~ PageNotFound ~ selection:', selection);
          setSelection(selection);
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input'
              })}
              style={{
                width: '700px'
              }}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <button onClick={getUsersCurrentLocation}>Locate me</button>

      <RenderIf isTrue={center?.lat}>
        <div style={{ height: '700px', width: '700px' }}>
          <APIProvider apiKey={GOOGLE_API_KEY}>
            <Map center={center} zoom={16}>
              <Marker
                position={center}
                draggable={true}
                onDragEnd={(e) => {
                  console.log('🚀 ~ PageNotFound ~ e:', e.latLng.toJSON());
                  handleLocationChange(e.latLng.toJSON());
                }}
              />
            </Map>
          </APIProvider>
        </div>
      </RenderIf>
    </>
  );
};

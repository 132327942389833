import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { RenderIf } from 'helpers';
import { RestaurantStore } from 'store';
import { ModalComponent } from 'components/modal';
import { PrimaryButton } from 'components/buttons/primary';
import { SecondaryButton } from 'components/buttons/secondary';
import { NutritionFacts } from 'components/nutritional';
import { Toasts } from 'components/toasts';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { Search } from 'components/search';
import { PORTAL_BACKEND_URL } from 'db';

const calculatePercentage = (value, percentage) => {
  return (value * percentage) / 100;
};

export const CloudBrandMenu = () => {
  const { restaurantData } = RestaurantStore((state) => state);

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  const { addItem, items } = useCart();

  const [dataToRender, setDataToRender] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedModifier, setSelectedModifier] = useState([]);
  const [selectedItemCost, setSelectedItemCost] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBrandTab, setSelectedBrandTab] = useState(-1);
  const [selectedTypeLink, setSelectedTypeLink] = useState('');
  const [searchData, setSearchData] = useState(null);

  useMemo(() => {
    if (locationState) {
      const Object = {
        ...locationState,
        orderType: 'pickup',
        cloudBrandsInfo: locationState?.cloudBrandsInfo
          ?.map((cloudBrand) => ({
            brand: cloudBrand?.brand,
            branch: {
              ...cloudBrand?.branches?.[0]?.branch,
              markup: cloudBrand?.branches?.[0]?.markup
            }
          }))
          ?.filter((cloudBrand) => {
            const categories = cloudBrand?.branch?.categories?.filter(
              (category) => {
                const categoryTypes = category?.categoryTypes?.filter(
                  (categoryType) => {
                    const items = categoryType?.items?.filter((item) => {
                      return item;
                    });
                    return items?.length > 0;
                  }
                );
                return categoryTypes?.length > 0;
              }
            );
            return categories?.length > 0;
          })
      };

      setDataToRender(Object);
      setSelectedTypeLink(
        Object?.cloudBrandsInfo?.[0]?.branch?.categories?.[0]
          ?.categoryTypes?.[0]?.categoryTypeName
      );
    } else {
      navigate('/');
    }
  }, [locationState]);

  const handleSelectModifiers = (modifier) => {
    // commenting for single modifier selection for kulchay
    // const { checked } = e.target;
    // if (checked) {
    //   setSelectedModifier([...selectedModifier, modifier]);
    // } else {
    //   setSelectedModifier(selectedModifier.filter((item) => item !== modifier));
    // }
    setSelectedModifier([modifier]);
  };

  useEffect(() => {
    if (selectedMenuItem) {
      const mainCost = selectedMenuItem?.price;
      const modifierCost = selectedModifier?.reduce((acc, curr) => {
        return acc + curr.additionalPrice;
      }, 0);
      let cost = mainCost + modifierCost;
      if (selectedBranch?.markup) {
        cost = cost + (cost * selectedBranch?.markup) / 100;
      }

      setSelectedItemCost(cost);
    }
  }, [selectedMenuItem, selectedModifier, selectedBranch]);

  const clearSelection = () => {
    setSelectedMenuItem(null);
    setSelectedModifier([]);
    setSelectedItemCost(0);
    handleModifierModal();
  };

  const addToCart = () => {
    const item = {
      id:
        selectedModifier?.map((m) => m?.modifierId)?.length > 0
          ? selectedMenuItem?.menuId +
            '-' +
            selectedModifier?.map((m) => m?.modifierId)?.join('')
          : selectedMenuItem?.menuId,
      ...selectedMenuItem,
      modifiers: selectedModifier,
      cost: selectedItemCost,
      branchId: selectedBranch?._id,
      brandId: selectedBranch?.brand,
      parentBranch: dataToRender?.parentBranch,
      orderType: dataToRender?.orderType,
      markup: selectedBranch?.markup,
      branchTax: location.state?.branchTax
    };

    addItem(item);
  };

  const addToCartWithoutModifiers = (item, branch) => {
    const mainCost = item?.price;
    let cost = mainCost;
    if (branch?.markup) {
      cost = cost + (cost * branch?.markup) / 100;
    }

    cost = parseFloat(cost).toFixed(2);

    const itemObj = {
      id: item?.menuId,
      ...item,
      modifiers: [],
      cost: cost,
      branchId: branch?._id,
      brandId: branch?.brand,
      parentBranch: dataToRender?.parentBranch,
      orderType: dataToRender?.orderType,
      markup: branch?.markup,
      branchTax: location.state?.branchTax
    };

    addItem(itemObj);
  };

  const handleModifierModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const [isModal, setIsModal] = useState(false);

  const handleGetNutrition = async (setSelectedMenuItem, mod, type) => {
    try {
      const response = (
        await PORTAL_BACKEND_URL.post(`/recipe/getRecipeDetailsZewstOnline`, {
          menu_item_id: setSelectedMenuItem?.menuId,
          modifier_id: mod?.modifierId
        })
      )?.data;

      if (type === 'singleModifier') {
        setIsModal(true);
      }
    } catch (error) {
      Toasts.error(`Nutritions data not found error: ${error}`);
    }
  };

  return (
    <>
      <article className={styles.article}>
        <div
          className={styles.brand_banner}
          style={{
            backgroundImage: `url(https://static-content.owner.com/locations/banner-images/j2cq4SKBPn2l.jpg?v=0)`
          }}
        >
          <div className={`box3`}>
            <div className={styles.bb_flex1}>
              <h4 className={`t_up`}>
                {restaurantData?.brand?.brandName}
                {dataToRender?.cloudBrandsInfo
                  ?.filter(
                    (cloudBrand) =>
                      cloudBrand?.brand?.brandId === selectedBrandTab
                  )
                  ?.map((cloudBrand, i) => (
                    <span key={i}>
                      {` ~ `}
                      {cloudBrand?.brand?.brandName}
                    </span>
                  ))}
              </h4>
              <div>
                <RenderIf
                  isTrue={
                    dataToRender?.cloudBrandsInfo?.filter(
                      (cloudBrand) =>
                        cloudBrand?.brand?.brandId === selectedBrandTab
                    )?.length > 0
                  }
                >
                  <Search
                    searchData={searchData}
                    setSearchData={setSearchData}
                    placeholder={`Search...`}
                  />
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
        <div className={`box3`}>
          <div className={`mt_40`}></div>
          <div className={styles.wrap_1}>
            <div className={styles.box_menu1}>
              <RenderIf isTrue={dataToRender?.cloudBrandsInfo?.length > 0}>
                <div className={`mt_20`}></div>
                <div className={`ofx_auto`}>
                  <div
                    className={styles.brand_xwrap}
                    style={{
                      width: `calc(164px * ${
                        dataToRender?.cloudBrandsInfo?.length + 1
                      })`
                    }}
                  >
                    {dataToRender?.cloudBrandsInfo?.map((cloudBrand, index) => (
                      <React.Fragment key={index}>
                        <RenderIf isTrue={index === 0}>
                          <button
                            key={index}
                            type={`button`}
                            className={
                              selectedBrandTab === -1
                                ? `${styles.brand_tab} ${styles.active}`
                                : styles.brand_tab
                            }
                            onClick={() => setSelectedBrandTab(-1)}
                          >
                            <div>
                              <span className={`fsemi`}>Popular</span>
                            </div>
                          </button>
                        </RenderIf>
                        <button
                          key={index}
                          type={`button`}
                          className={
                            selectedBrandTab === cloudBrand?.brand?.brandId
                              ? `${styles.brand_tab} ${styles.active}`
                              : styles.brand_tab
                          }
                          onClick={() =>
                            setSelectedBrandTab(cloudBrand?.brand?.brandId)
                          }
                        >
                          <RenderIf isTrue={cloudBrand?.brand?.brandImage}>
                            <picture>
                              <source srcSet={cloudBrand?.brand?.brandImage} />
                              <img
                                src={cloudBrand?.brand?.brandImage}
                                alt={cloudBrand?.brand?.brandName}
                              />
                            </picture>
                          </RenderIf>
                          <RenderIf isTrue={!cloudBrand?.brand?.brandImage}>
                            <div className={styles.twoCharacter}>
                              <RenderIf
                                isTrue={
                                  cloudBrand?.brand?.brandName.indexOf(' ') >= 0
                                }
                              >
                                {cloudBrand?.brand?.brandName
                                  .split(' ')[0]
                                  .substring(0, 1) +
                                  cloudBrand?.brand?.brandName
                                    .split(' ')[1]
                                    ?.substring(0, 1)}
                              </RenderIf>
                              <RenderIf
                                isTrue={
                                  cloudBrand?.brand?.brandName.indexOf(' ') < 0
                                }
                              >
                                {cloudBrand?.brand?.brandName.substring(0, 1)}
                              </RenderIf>
                            </div>
                          </RenderIf>
                          <div>
                            <span className={`fsemi`}>
                              {cloudBrand?.brand?.brandName}
                            </span>
                          </div>
                        </button>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <RenderIf isTrue={selectedBrandTab !== -1}>
                  <div className={styles.scroll_links}>
                    {dataToRender?.cloudBrandsInfo
                      ?.filter(
                        (cloudBrand) =>
                          cloudBrand?.brand?.brandId === selectedBrandTab
                      )
                      ?.map((cloudBrand) => {
                        return cloudBrand?.branch?.categories?.map(
                          (category) => {
                            return category?.categoryTypes?.map(
                              (categoryType, idx) => (
                                <div
                                  className={
                                    selectedTypeLink ===
                                    categoryType?.categoryTypeName
                                      ? `${styles.scroll_link_item} ${styles.active}`
                                      : styles.scroll_link_item
                                  }
                                  key={idx}
                                  onClick={() => {
                                    const anchor = document.querySelector(
                                      `#${
                                        categoryType?.categoryTypeName?.split(
                                          ' '
                                        )[0]
                                      }_${cloudBrand?.branch?.branchId}`
                                    );
                                    anchor.scrollIntoView({
                                      behavior: 'smooth'
                                    });
                                    setSelectedTypeLink(
                                      categoryType?.categoryTypeName
                                    );
                                  }}
                                >
                                  {categoryType?.categoryTypeName}
                                </div>
                              )
                            );
                          }
                        );
                      })}
                  </div>
                </RenderIf>
              </RenderIf>
              <RenderIf isTrue={selectedBrandTab !== -1}>
                {dataToRender?.cloudBrandsInfo
                  ?.filter(
                    (cloudBrand) =>
                      cloudBrand?.brand?.brandId === selectedBrandTab
                  )
                  ?.map((cloudBrand, index) => (
                    <React.Fragment key={index}>
                      <RenderIf
                        isTrue={cloudBrand?.branch?.categories?.length === 0}
                      >
                        <div className={`mt_40`}></div>
                        <div className={`no_data1`}>
                          <span className={`fsemi`}>No Menu Items found</span>
                        </div>
                      </RenderIf>
                      <RenderIf
                        isTrue={cloudBrand?.branch?.categories?.length > 0}
                      >
                        {cloudBrand?.branch?.categories?.map((category, i) => (
                          <React.Fragment key={i}>
                            <ContentCategory
                              branch={cloudBrand?.branch}
                              category={category}
                              setSelectedMenuItem={setSelectedMenuItem}
                              setSelectedItemCost={setSelectedItemCost}
                              setModalIsOpen={setModalIsOpen}
                              setSelectedBranch={setSelectedBranch}
                              addToCartWithoutModifiers={
                                addToCartWithoutModifiers
                              }
                              selectedMenuItem={selectedMenuItem}
                              modalIsOpen={modalIsOpen}
                              selectedItemCost={selectedItemCost}
                              addToCart={addToCart}
                              clearSelection={clearSelection}
                              navigate={navigate}
                              items={items}
                              handleGetNutrition={handleGetNutrition}
                              setIsModal={setIsModal}
                              selectedBranch={selectedBranch}
                              parentBranch={dataToRender?.parentBranch}
                            />
                          </React.Fragment>
                        ))}
                      </RenderIf>
                    </React.Fragment>
                  ))}
              </RenderIf>
              <RenderIf isTrue={selectedBrandTab === -1}>
                {dataToRender?.cloudBrandsInfo
                  ?.map((cloudBrand, index) => (
                    <React.Fragment key={index}>
                      {cloudBrand?.branch?.categories?.map((category, ind) => {
                        return (
                          <React.Fragment key={ind}>
                            <ContentCategory
                              branch={cloudBrand?.branch}
                              category={category}
                              setSelectedMenuItem={setSelectedMenuItem}
                              setSelectedItemCost={setSelectedItemCost}
                              setModalIsOpen={setModalIsOpen}
                              setSelectedBranch={setSelectedBranch}
                              addToCartWithoutModifiers={
                                addToCartWithoutModifiers
                              }
                              selectedMenuItem={selectedMenuItem}
                              modalIsOpen={modalIsOpen}
                              selectedItemCost={selectedItemCost}
                              addToCart={addToCart}
                              clearSelection={clearSelection}
                              navigate={navigate}
                              items={items}
                              selectedBranch={selectedBranch}
                              handleGetNutrition={handleGetNutrition}
                              setIsModal={setIsModal}
                              parentBranch={dataToRender?.parentBranch}
                            />
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  ))
                  ?.flat(3)}
              </RenderIf>
            </div>
          </div>
          <div className={`mt_40`}></div>
        </div>
      </article>

      <ModalComponent
        modal={isModal}
        handleModal={() => {
          setIsModal(false);
        }}
        rightModal={false}
      >
        <div className={styles.nutritionalModalBx}>
          <div className={styles.modal_header}>
            <div>
              <h4 className={`t_up`}>{selectedMenuItem?.item}</h4>
              <div className={`h5`} style={{ color: `var(--primary)` }}>
                <strong>$ {selectedItemCost}</strong>
              </div>
            </div>
            <div>
              <p>{selectedMenuItem?.description}</p>
            </div>
          </div>
          <NutritionFacts menuItem={selectedMenuItem} />
        </div>
      </ModalComponent>

      <ModalComponent
        modal={modalIsOpen}
        handleModal={clearSelection}
        rightModal={false}
        modalMiddle={true}
      >
        <div className={styles.modalBx}>
          <div className={styles.modal_header}>
            <div>
              <h4>{selectedMenuItem?.item}</h4>
              <div className={`h5`} style={{ color: `var(--primary)` }}>
                <strong>$ {selectedItemCost}</strong>
              </div>
            </div>
            <div>
              <p>{selectedMenuItem?.description}</p>
            </div>
          </div>
          {selectedMenuItem?.groups
            ?.filter((g) => g?.groupName !== 'BASE')
            ?.map((group, i) => {
              return (
                <div key={i}>
                  <h5>
                    <span>{group?.groupName}</span>
                  </h5>
                  <div className={`mt_5`}></div>
                  <div className={`table1`}>
                    {group?.modifiers?.map((item, index) => {
                      return (
                        <div className={styles.addITr} key={index}>
                          <div className={styles.check_name}>
                            <input
                              name={'radio'}
                              type="radio"
                              onChange={() => {
                                handleSelectModifiers(item);
                              }}
                            />
                            <div>
                              <div className={`h6`}>
                                <strong>{item?.modifier}</strong>
                              </div>
                              <RenderIf isTrue={item?.description}>
                                <p>{item?.description}</p>
                              </RenderIf>
                            </div>
                          </div>
                          <div>
                            <strong>
                              ${' '}
                              {parseFloat(
                                item?.additionalPrice +
                                  calculatePercentage(
                                    item?.additionalPrice,
                                    selectedBranch?.markup
                                  )
                              )?.toFixed(2)}
                            </strong>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          <div className={styles.modal_footer}>
            <div className={`flex item_space_btn`}>
              <PrimaryButton handleClick={addToCart}>
                Add to Cart(
                {items?.reduce((a, b) => a + b?.quantity, 0)})
              </PrimaryButton>

              <RenderIf isTrue={items?.length > 0}>
                <SecondaryButton
                  handleClick={() => navigate('/cart')}
                  disabled={items?.length === 0}
                >
                  Go to cart ($
                  {items
                    ?.reduce((a, b) => a + b?.quantity * b?.cost, 0)
                    ?.toFixed(0)}
                  )
                </SecondaryButton>
              </RenderIf>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export const ContentCategory = ({
  branch,
  category,
  setSelectedMenuItem,
  setSelectedItemCost,
  setModalIsOpen,
  setSelectedBranch,
  addToCartWithoutModifiers,
  items,
  handleGetNutrition,
  setIsModal,
  navigate,
  selectedBranch,
  parentBranch
}) => {
  const { updateItemQuantity, getItem } = useCart();

  const selectedItem = (item_id) =>
    items?.filter((it) => it?._id === item_id)?.[0];

  return (
    <article>
      {category?.categoryTypes?.map((categoryType, index) => {
        return (
          <div
            key={index}
            className={styles.type_item}
            id={`${categoryType?.categoryTypeName?.split(' ')[0]}_${
              branch?.branchId
            }`}
          >
            <RenderIf isTrue={categoryType?.items?.length}>
              <h5>
                <span className={`t_up`}>{categoryType?.categoryTypeName}</span>
              </h5>
              <div className={`mt_15`}>
                <div className={styles.xy_wrap}>
                  {categoryType?.items?.map((item, i) => {
                    return (
                      <div key={i} className={styles.button_item}>
                        <div>
                          <picture
                            onClick={() => {
                              navigate(`/brand-menu/${item._id}`, {
                                state: {
                                  item: item,
                                  branch,
                                  parentBranch
                                }
                              });
                            }}
                          >
                            <source
                              srcSet={
                                item?.image ||
                                'https://image.pngaaa.com/803/3788803-middle.png'
                              }
                            />
                            <img
                              src={
                                item?.image ||
                                'https://image.pngaaa.com/803/3788803-middle.png'
                              }
                              alt="categoryType"
                            />
                          </picture>
                          <RenderIf isTrue={selectedItem(item?._id)}>
                            <div className={styles.qtyBx}>
                              <button
                                type={`button`}
                                onClick={() => {
                                  updateItemQuantity(
                                    selectedItem(item?._id)?.id,
                                    selectedItem(item?._id)?.quantity - 1
                                  );
                                }}
                              >
                                <Icon
                                  name={`minus`}
                                  fillColor={`var(--foreground-color)`}
                                />
                              </button>
                              <div>
                                <strong>
                                  {item?.groups
                                    ?.map((group) => {
                                      return group?.modifiers;
                                    })
                                    ?.flat(1)
                                    .filter(
                                      (modifier) =>
                                        modifier?.modifier !== 'BASE'
                                    )?.length > 0
                                    ? items?.reduce((a, b) => {
                                        if (b?._id === item?._id) {
                                          a = a + b?.quantity;
                                        }
                                        return a;
                                      }, 0)
                                    : items?.reduce((a, b) => {
                                        if (b?._id === item?._id) {
                                          a = a + b?.quantity;
                                        }
                                        return a;
                                      }, 0) || '0'}
                                </strong>
                              </div>
                              <button
                                type={`button`}
                                onClick={() => {
                                  let modifiers = 0;
                                  modifiers = item?.groups?.map((group) => {
                                    return group?.modifiers;
                                  });
                                  modifiers = modifiers?.length
                                    ? modifiers?.flat(1)
                                    : [];

                                  modifiers = modifiers?.filter(
                                    (modifier) => modifier?.modifier !== 'BASE'
                                  );

                                  if (modifiers?.length === 0) {
                                    addToCartWithoutModifiers(item, branch);
                                    Toasts.success(
                                      'Item added in cart successfully.'
                                    );
                                  } else {
                                    setSelectedMenuItem(item);
                                    setSelectedItemCost(item?.price);
                                    setModalIsOpen(true);
                                    setSelectedBranch(branch);
                                  }
                                }}
                              >
                                <Icon
                                  name={`plus`}
                                  fillColor={`var(--foreground-color)`}
                                />
                              </button>
                            </div>
                          </RenderIf>
                          <RenderIf isTrue={!selectedItem(item?._id)}>
                            <button
                              className={styles.bAddItem}
                              onClick={() => {
                                let modifiers = 0;
                                modifiers = item?.groups?.map((group) => {
                                  return group?.modifiers;
                                });
                                modifiers = modifiers?.length
                                  ? modifiers?.flat(1)
                                  : [];

                                modifiers = modifiers?.filter(
                                  (modifier) => modifier?.modifier !== 'BASE'
                                );

                                if (modifiers?.length === 0) {
                                  addToCartWithoutModifiers(item, branch);
                                  Toasts.success(
                                    'Item added in cart successfully.'
                                  );
                                } else {
                                  setSelectedMenuItem(item);
                                  setSelectedItemCost(item?.price);
                                  setModalIsOpen(true);
                                  setSelectedBranch(branch);
                                }
                              }}
                            >
                              <span className={`icon`}>
                                <Icon
                                  name={`plus`}
                                  fillColor={`var(--background-color)`}
                                />
                              </span>
                            </button>
                          </RenderIf>
                        </div>
                        <div className={styles.bItemCont}>
                          <div>
                            <div className={styles.bItemTitle}>
                              {item?.item}
                            </div>
                            <div className={styles.bItemPrice}>
                              $
                              {parseFloat(
                                item?.price +
                                  calculatePercentage(
                                    item?.price,
                                    branch?.markup
                                  )
                              )?.toFixed(2)}
                            </div>
                          </div>
                          <div>
                            <button
                              type={`button`}
                              className={styles.buttonNutritional}
                              onClick={() => {
                                let modifiers = 0;
                                modifiers = item?.groups?.map((group) => {
                                  return group?.modifiers;
                                });
                                modifiers = modifiers?.length
                                  ? modifiers?.flat(1)
                                  : [];

                                let BaseModifierId = modifiers?.filter(
                                  (modifier) => modifier?.modifier === 'BASE'
                                )?.[0];

                                modifiers = modifiers?.filter(
                                  (modifier) => modifier?.modifier !== 'BASE'
                                );

                                if (modifiers?.length === 0) {
                                  handleGetNutrition(
                                    item,
                                    BaseModifierId,
                                    'singleModifier'
                                  );
                                } else {
                                  setSelectedMenuItem(item);
                                  setSelectedItemCost(item?.price);
                                  setIsModal(true);
                                }
                              }}
                            >
                              <Icon name={`nutritional`} />
                              Nutrional Facts
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </RenderIf>
          </div>
        );
      })}
    </article>
  );
};

import { JSONData } from 'store/pagesjson';
import styles from './index.module.css';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { RestaurantStore } from 'store';

export const SecondaryButton = ({
  type = `button`,
  buttonReverse = false,
  buttonFull = false,
  handleClick = () => {},
  children,
  disabled
}) => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);


  //const resData = JSONData;
  const secondary = restaurantData?.theme?.global?.style?.button?.secondary;

  return (
    <button
      type={type}
      className={styles.button}
      onClick={handleClick}
      style={{
        width: buttonFull ? `100%` : ``,
        backgroundColor: buttonReverse
          ? secondary?.textColor
          : secondary?.backgroundColor,
        color: buttonReverse
          ? secondary?.backgroundColor
          : secondary?.textColor,
        borderRadius: `${secondary?.roundedCorners}px`
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

import { ThemeTabs } from 'components/tabs';
import { ProfileOverview } from './overview';
import { AccountOrders } from './orders';

export const ProfilePage = () => {
  return (
    <>
      <ThemeTabs
        tabsCenter
        tabs={[{ label: `Profile Overview` }, { label: `Your Orders` }]}
        panels={[
          { content: <ProfileOverview /> },
          { content: <AccountOrders /> }
        ]}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { RenderIf } from 'helpers';
import { Icon } from 'assets/icons';
import styles from './index.module.css';

export const SliderItem = ({ children, width, margin }) => {
  return (
    <>
      <article
        className={styles.item}
        style={{
          position: `relative`,
          width: width,
          display: `inline-flex`,
          flexDirection: `column`,
          margin: margin,
          whiteSpace: `initial`
        }}
      >
        {children}
      </article>
    </>
  );
};

export default function HorizontalSlider({
  children,
  itemWidth = 250,
  spaceBetween = 20,
  showIndicators = true,
  showItems = 4
}) {
  const [activeIndex, setActiveIndex] = useState(3);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < showItems - 1) {
      newIndex = React?.Children?.count(children) - 1;
    } else if (newIndex >= React?.Children?.count(children)) {
      newIndex = showItems - 1;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 2000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <>
      <article
        className={styles.slider}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <RenderIf isTrue={showIndicators}>
          <div className={`${styles.indicators} ${styles.indicatorsLeft}`}>
            <button
              type={`button`}
              onClick={() => {
                updateIndex(activeIndex - 1);
              }}
              style={{
                border: `var(--borderColor1) 1px solid`
              }}
            >
              <Icon
                name={`left-cert`}
                fillColor={`rgba(var(--balck-rgb), 0.76)`}
              />
            </button>
          </div>
        </RenderIf>
        <div
          className={styles.sliderInner}
          style={{
            width: `calc(100% - 56px)`,
            whiteSpace: `nowrap`,
            transform: `translateX(-${
              activeIndex > showItems - 2 &&
              (activeIndex - (showItems - 1)) * (itemWidth + spaceBetween)
            }px)`
          }}
        >
          {React?.Children?.map(children, (child, index) => {
            return React?.cloneElement(child, {
              width: `${itemWidth}px`,
              margin: `0 ${spaceBetween / 2}px`
            });
          })}
        </div>
        <RenderIf isTrue={showIndicators}>
          <div className={`${styles.indicators} ${styles.indicatorsRight}`}>
            <button
              type={`button`}
              onClick={() => {
                updateIndex(activeIndex + 1);
              }}
              style={{
                border: `rgba(var(--balck-rgb), 0.24) 1px solid`
              }}
            >
              <Icon
                name={`right-cert`}
                fillColor={`rgba(var(--balck-rgb), 0.76)`}
              />
            </button>
          </div>
        </RenderIf>
      </article>
    </>
  );
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { RenderIf } from 'helpers';
import styles from './index.module.css';
import { PORTAL_BACKEND_URL } from 'db';

const COLORS = ['#F480B3', '#7A7A7A', '#A5DBD2'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const NutritionItem = ({ menuItem, item, nutritionalData, itemDetailPage }) => {
  const [itemNutritionData, setItemNutritionData] = useState(
    nutritionalData || null
  );

  useEffect(() => {
    if (nutritionalData) {
      setItemNutritionData(nutritionalData);
    } else {
      GetItemNutrition(menuItem, item);
    }
  }, [menuItem, item]);

  useEffect(() => {
    setItemNutritionData(nutritionalData);
  }, [nutritionalData]);

  const GetItemNutrition = async (selectedMenuItem, mod) => {
    try {
      const response = (
        await PORTAL_BACKEND_URL.post(`/recipe/getRecipeDetailsZewstOnline`, {
          menu_item_id: selectedMenuItem?.menuId,
          modifier_id: mod?.modifierId
        })
      )?.data;

      setItemNutritionData(response);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className={styles.niBx}>
      <div className={styles.niHeader}>
        <h5>
          <strong>{item?.modifier}</strong>
        </h5>
        <div className={`h6`}>-</div>
        <RenderIf isTrue={!itemNutritionData?.allergens?.length}>
          <div className={`h6`}>No allergens.</div>
        </RenderIf>
        <RenderIf isTrue={itemNutritionData?.allergens?.length}>
          <div className={`h6`}>Allergens</div>
        </RenderIf>
      </div>
      <RenderIf
        isTrue={
          itemNutritionData?.allergens?.length ||
          itemNutritionData?.productHealthFactor?.length ||
          itemNutritionData?.productNutrientsDetails?.length
        }
      >
        <div className={styles.box_allergens}>
          <div className={styles.allergens}>
            {itemNutritionData?.allergens?.map((item, index) => {
              return (
                <div key={index}>
                  <div className={styles.allergens_item}>
                    <div className={styles.allergens_value}>
                      <b>{item}</b>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={itemDetailPage ? styles.grid_2 : styles.grid_1}>
          <div className={styles.col_nutrional}>
            {/* <div className={`h6`}>
                <b>Nutrional Facts</b>
              </div> */}
            <div className={styles.nutrionals}>
              <RenderIf
                isTrue={itemNutritionData?.productNutrientsDetails?.length}
              >
                {itemNutritionData?.productNutrientsDetails?.map(
                  (item, index) => {
                    return (
                      <div className={styles.nutrional_item} key={index}>
                        <div>
                          <div className={styles.nutritional_icon}>
                            {item?.nutrientName?.charAt(0)}
                          </div>
                          <div>{item?.nutrientName}</div>
                        </div>
                        <div>{item?.value + ' ' + item?.unitName}</div>
                      </div>
                    );
                  }
                )}
              </RenderIf>
            </div>
          </div>
          <div className={styles.col_health}>
            <div className={`h6`}>
              <b>Health score</b>
            </div>
            <div>
              <RenderIf isTrue={itemNutritionData?.productHealthFactor?.length}>
                <PieChart width={300} height={200}>
                  <Pie
                    data={itemNutritionData?.productHealthFactor?.map((hs) => ({
                      name: hs?.nutrientName,
                      value: parseFloat(hs?.percentage)
                    }))}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {itemNutritionData?.productHealthFactor
                      ?.map((hs) => ({
                        name: hs?.nutrientName,
                        value: parseFloat(hs?.percentage)
                      }))
                      .map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                  </Pie>
                  <Legend />
                </PieChart>
              </RenderIf>
              {/* <RenderIf
                isTrue={!itemNutritionData?.productHealthFactor?.length}
              >
                <div className={styles.nutrional_item}>
                  <div>No health score.</div>
                </div>
              </RenderIf> */}
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
};

export const NutritionFacts = ({
  menuItem,
  nutritionalData,
  itemDetailPage = false
}) => {

  return (
    <>
      <div style={{ display: `flex`, flexDirection: `column`, gap: 30 }}>
        {menuItem?.groups
          // ?.filter((g) => g?.groupName !== 'BASE')
          ?.map((group) =>
            group?.modifiers?.map((item, index) => {
              return (
                <>
                  <NutritionItem
                    menuItem={menuItem}
                    item={item}
                    nutritionalData={nutritionalData}
                    itemDetailPage={itemDetailPage}
                    key={index}
                  />
                  <hr />
                </>
              );
            })
          )}
      </div>
    </>
  );
};

import { Images } from 'constant';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { SecondaryButton } from 'components/buttons/secondary';
import styles from './index.module.css';
import { RestaurantStore } from 'store';

export const DealsFooter = () => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  const homeFooterSection = restaurantData?.theme?.homeFooterSection;

  return (
    <>
      <article
        className={styles.article}
        style={{
          backgroundImage: `url(${Images.pizza})`
        }}
      >
        <div className={styles.box}>
          <div className={styles.content}>
            <div>
              <h2
                className={`t_up`}
                style={{
                  color:
                    homeFooterSection?.textClass === '' ||
                    homeFooterSection?.textClass === 'white'
                      ? '#fff'
                      : 'var(--dark)'
                }}
              >
                <b className="t_up">{'Feel good eat better'}</b>
              </h2>
              <p className={styles.para}>
                <strong>
                  Chef-crafted, signature burgers. Prepared fresh with the best
                  ingredients and made just for you!
                </strong>
              </p>
              <div className="mt_40"></div>
              <SecondaryButton buttonReverse>Order now</SecondaryButton>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

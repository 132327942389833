import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { RenderIf } from 'helpers';
import { ModalComponent } from 'components/modal';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { Toasts } from 'components/toasts';
import { NutritionFacts } from 'components/nutritional';
import { PrimaryButton } from 'components/buttons/primary';
import { SecondaryButton } from 'components/buttons/secondary';
import { PORTAL_BACKEND_URL } from 'db';
import { Search } from 'components/search';
import { useFetchWindowDimensions } from 'hooks/windowdimensions/useFetchWindowDimensions';

export const BrandMenuPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useFetchWindowDimensions();
  const { branch, orderType } = location.state;

  const [singleBranchData, setSingleBranchData] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedModifier, setSelectedModifier] = useState([]);
  const [selectedItemCost, setSelectedItemCost] = useState(0);
  const [selectedTypeLink, setSelectedTypeLink] = useState('');

  const { addItem, items, updateItemQuantity } = useCart();

  const [nutritionalData, setNutritionalData] = useState(null);
  const [fetchingNutrition, setFetchingNutrition] = useState(false);

  useEffect(() => {
    if (branch) {
      setSingleBranchData(branch);
      setSelectedTypeLink(
        branch.categories?.[0]?.categoryTypes?.[0]?.categoryTypeName
      );
    } else {
      navigate('/');
      return;
    }
  }, [branch, navigate]);

  const handleSelectModifiers = (modifier) => {
    // commenting for single modifier selection
    // const { checked } = e.target;
    // if (checked) {
    //   setSelectedModifier([...selectedModifier, modifier]);
    // } else {
    //   setSelectedModifier(selectedModifier.filter((item) => item !== modifier));
    // }
    setSelectedModifier([modifier]);
  };

  useEffect(() => {
    if (selectedMenuItem) {
      const mainCost = selectedMenuItem?.price;
      const modifierCost = selectedModifier?.reduce((acc, curr) => {
        return acc + curr.additionalPrice;
      }, 0);
      let cost = mainCost + modifierCost;
      if (branch?.markup) {
        cost = cost + (cost * branch?.markup) / 100;
      }

      setSelectedItemCost(cost);
    }
  }, [branch, selectedMenuItem, selectedModifier]);

  const clearSelection = () => {
    setSelectedMenuItem(null);
    setSelectedModifier([]);
    setSelectedItemCost(0);
    handleModifierModal();
  };

  const addToCart = () => {
    const item = {
      id: selectedMenuItem?.menuId,
      ...selectedMenuItem,
      modifiers: selectedModifier,
      cost: selectedItemCost,
      branchId: branch?._id,
      brandId: branch?.brand,
      parentBranch: branch?.parentBranch,
      orderType,
      markup: branch?.markup,
      branchTax: branch?.branchTax
    };

    const existingItem = items?.find(
      (it) => JSON.stringify(it) === JSON.stringify(item)
    );

    if (existingItem) {
      addItem(item);
      Toasts.success('Item quantity updated successfully.');
    } else {
      item.id = item?.menuId + item?.modifiers?.map((m) => m?.modifierId);
      addItem(item);
      Toasts.success('Item added in cart successfully.');
    }
  };

  const addToCartWithoutModifiers = (item, branch) => {
    const mainCost = item?.price;
    let cost = mainCost;
    if (branch?.markup) {
      cost = cost + (cost * branch?.markup) / 100;
    }

    const itemObj = {
      id: item?.menuId,
      ...item,
      modifiers: [],
      cost: cost,
      branchId: branch?._id,
      brandId: branch?.brand,
      parentBranch: branch?.parentBranch,
      orderType,
      markup: branch?.markup,
      branchTax: branch?.branchTax
    };
    addItem(itemObj);
  };

  const handleModifierModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const [isModal, setIsModal] = useState(false);

  const handleGetNutrition = async (selectedMenuItem, mod, type) => {
    try {
      setFetchingNutrition(true);
      const response = (
        await PORTAL_BACKEND_URL.post(`/recipe/getRecipeDetailsZewstOnline`, {
          menu_item_id: selectedMenuItem?.menuId,
          modifier_id: mod?.modifierId
        })
      )?.data;

      if (type === 'singleModifier') {
        setNutritionalData(response);
        setIsModal(true);
      }
      setFetchingNutrition(false);
    } catch (error) {
      setFetchingNutrition(false);
      Toasts.error('Nutritions data not found');
    }
  };

  const selectedItem = (item_id) =>
    items?.filter((it) => it?._id === item_id)?.[0];

  const calculatePercentage = (value, percentage) => {
    return (value * percentage) / 100;
  };

  return (
    <>
      <article className={styles.article}>
        <div
          className={styles.brand_banner}
          style={{
            backgroundImage: `url(https://static-content.owner.com/locations/banner-images/j2cq4SKBPn2l.jpg?v=0)`
          }}
        >
          <div className={`box3`}>
            <div className={styles.bb_flex1}>
              <h4 className={`t_up`}>
                <span>{branch?.branchName}</span>
              </h4>
              <div>
                <RenderIf isTrue={singleBranchData?.categories?.length > 0}>
                  <Search
                    setSearchValue={setSearchValue}
                    placeholder={`Search...`}
                  />
                </RenderIf>
              </div>
            </div>
          </div>
        </div>
        <div className={`box3`}>
          <div className={styles.wrap_1}>
            <div className={styles.box_menu1}>
              <RenderIf isTrue={singleBranchData?.categories?.length === 0}>
                <div className={`mt_30`}></div>
                <div className={`no_data1`}>
                  <span className={`fsemi`}>No Menu Items found</span>
                </div>
              </RenderIf>

              <RenderIf isTrue={singleBranchData?.categories?.length > 0}>
                <div className={styles.scroll_links}>
                  {singleBranchData?.categories?.map((category) => {
                    return category?.categoryTypes?.map((categoryType, idx) => {
                      if (
                        categoryType?.items?.length > 0 &&
                        categoryType?.items?.filter((ite) =>
                          ite?.item
                            ?.toLowerCase()
                            .includes(searchValue?.toLowerCase())
                        )?.length > 0
                      ) {
                        return (
                          <div
                            className={
                              selectedTypeLink ===
                              categoryType?.categoryTypeName
                                ? `${styles.scroll_link_item} ${styles.active}`
                                : styles.scroll_link_item
                            }
                            key={idx}
                            onClick={() => {
                              const anchor = document.querySelector(
                                `#${
                                  categoryType?.categoryTypeName?.split(' ')[0]
                                }_${singleBranchData?.branchId}`
                              );
                              anchor.scrollIntoView({
                                behavior: 'smooth'
                              });
                              setSelectedTypeLink(
                                categoryType?.categoryTypeName
                              );
                            }}
                          >
                            {categoryType?.categoryTypeName}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    });
                  })}
                </div>
                {singleBranchData?.categories?.map((category, index) => {
                  return (
                    <div key={index}>
                      <RenderIf isTrue={category?.categoryTypes?.length > 0}>
                        {/* <div className={`mt_30`}></div>
                        <h3>{category?.categoryName}</h3> */}
                        {category?.categoryTypes?.map((categoryType, idx) => {
                          return (
                            <div
                              key={idx}
                              className={styles.type_item}
                              id={`${
                                categoryType?.categoryTypeName?.split(' ')[0]
                              }_${singleBranchData?.branchId}`}
                            >
                              <RenderIf
                                isTrue={
                                  categoryType?.items?.length > 0 &&
                                  categoryType?.items?.filter((ite) =>
                                    ite?.item
                                      ?.toLowerCase()
                                      .includes(searchValue?.toLowerCase())
                                  )?.length > 0
                                }
                              >
                                <h5>
                                  <span className={`t_up`}>
                                    {categoryType?.categoryTypeName}
                                  </span>
                                </h5>
                                <div className={`mt_15`}></div>
                                <div className={styles.xy_wrap}>
                                  {categoryType?.items
                                    ?.filter((ite) =>
                                      ite?.item
                                        ?.toLowerCase()
                                        .includes(searchValue?.toLowerCase())
                                    )
                                    ?.map((item, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className={styles.button_item}
                                        >
                                          <div>
                                            <picture
                                              onClick={() => {
                                                navigate(
                                                  `/brand-menu/${item._id}`,
                                                  {
                                                    state: {
                                                      item: item,
                                                      branch,
                                                      parentBranch:
                                                        branch?.parentBranch
                                                    }
                                                  }
                                                );
                                              }}
                                            >
                                              <source
                                                srcSet={
                                                  item?.image ||
                                                  'https://image.pngaaa.com/803/3788803-middle.png'
                                                }
                                              />
                                              <img
                                                src={
                                                  item?.image ||
                                                  'https://image.pngaaa.com/803/3788803-middle.png'
                                                }
                                                alt="categoryType"
                                              />
                                            </picture>
                                            <RenderIf
                                              isTrue={selectedItem(item?._id)}
                                            >
                                              <div className={styles.qtyBx}>
                                                <button
                                                  type={`button`}
                                                  onClick={() =>
                                                    updateItemQuantity(
                                                      selectedItem(item?._id)
                                                        ?.id,
                                                      selectedItem(item?._id)
                                                        ?.quantity - 1
                                                    )
                                                  }
                                                >
                                                  <Icon
                                                    name={`minus`}
                                                    fillColor={`var(--foreground-color)`}
                                                  />
                                                </button>
                                                <div>
                                                  <strong>
                                                    {item?.groups
                                                      ?.map((group) => {
                                                        return group?.modifiers;
                                                      })
                                                      ?.flat(1)
                                                      .filter(
                                                        (modifier) =>
                                                          modifier?.modifier !==
                                                          'BASE'
                                                      )?.length > 0
                                                      ? items?.reduce(
                                                          (a, b) => {
                                                            if (
                                                              b?._id ===
                                                              item?._id
                                                            ) {
                                                              a =
                                                                a + b?.quantity;
                                                            }
                                                            return a;
                                                          },
                                                          0
                                                        )
                                                      : items?.reduce(
                                                          (a, b) => {
                                                            if (
                                                              b?._id ===
                                                              item?._id
                                                            ) {
                                                              a =
                                                                a + b?.quantity;
                                                            }
                                                            return a;
                                                          },
                                                          0
                                                        ) || '0'}
                                                  </strong>
                                                </div>
                                                <button
                                                  type={`button`}
                                                  onClick={() => {
                                                    let modifiers = 0;
                                                    modifiers =
                                                      item?.groups?.map(
                                                        (group, i) => {
                                                          return group?.modifiers;
                                                        }
                                                      );
                                                    modifiers =
                                                      modifiers?.length
                                                        ? modifiers?.flat(1)
                                                        : [];

                                                    modifiers =
                                                      modifiers?.filter(
                                                        (modifier) =>
                                                          modifier?.modifier !==
                                                          'BASE'
                                                      );

                                                    if (
                                                      modifiers?.length === 0
                                                    ) {
                                                      addToCartWithoutModifiers(
                                                        item,
                                                        singleBranchData
                                                      );
                                                      Toasts.success(
                                                        'Item added in cart successfully.'
                                                      );
                                                    } else {
                                                      setSelectedMenuItem(item);
                                                      setSelectedItemCost(
                                                        item?.price
                                                      );
                                                      setModalIsOpen(true);
                                                    }
                                                  }}
                                                >
                                                  <Icon
                                                    name={`plus`}
                                                    fillColor={`var(--foreground-color)`}
                                                  />
                                                </button>
                                              </div>
                                            </RenderIf>
                                            <RenderIf
                                              isTrue={!selectedItem(item?._id)}
                                            >
                                              <button
                                                className={styles.bAddItem}
                                                onClick={() => {
                                                  let modifiers = 0;
                                                  modifiers = item?.groups?.map(
                                                    (group, i) => {
                                                      return group?.modifiers;
                                                    }
                                                  );
                                                  modifiers = modifiers?.length
                                                    ? modifiers?.flat(1)
                                                    : [];

                                                  modifiers = modifiers?.filter(
                                                    (modifier) =>
                                                      modifier?.modifier !==
                                                      'BASE'
                                                  );

                                                  if (modifiers?.length === 0) {
                                                    addToCartWithoutModifiers(
                                                      item,
                                                      singleBranchData
                                                    );
                                                    Toasts.success(
                                                      'Item added in cart successfully.'
                                                    );
                                                  } else {
                                                    setSelectedMenuItem(item);
                                                    setSelectedItemCost(
                                                      item?.price
                                                    );
                                                    setModalIsOpen(true);
                                                  }
                                                }}
                                              >
                                                <span className={`icon`}>
                                                  <Icon
                                                    name={`plus`}
                                                    fillColor={`var(--background-color)`}
                                                  />
                                                </span>
                                              </button>
                                            </RenderIf>
                                          </div>
                                          <div className={styles.bItemCont}>
                                            <div>
                                              <div
                                                className={styles.bItemTitle}
                                              >
                                                {item?.item}
                                              </div>
                                              <div
                                                className={styles.bItemPrice}
                                              >
                                                $
                                                {parseFloat(
                                                  item?.price
                                                )?.toFixed(2)}
                                              </div>
                                            </div>
                                            <div>
                                              <button
                                                type={`button`}
                                                className={
                                                  styles.buttonNutritional
                                                }
                                                disabled={fetchingNutrition}
                                                onClick={() => {
                                                  let modifiers = 0;
                                                  modifiers = item?.groups?.map(
                                                    (group, i) => {
                                                      return group?.modifiers;
                                                    }
                                                  );
                                                  modifiers = modifiers?.length
                                                    ? modifiers?.flat(1)
                                                    : [];
                                                  let BaseModifierId =
                                                    modifiers?.filter(
                                                      (modifier) =>
                                                        modifier?.modifier ===
                                                        'BASE'
                                                    )?.[0];

                                                  modifiers = modifiers?.filter(
                                                    (modifier) =>
                                                      modifier?.modifier !==
                                                      'BASE'
                                                  );

                                                  if (modifiers?.length === 0) {
                                                    setSelectedMenuItem(item);
                                                    handleGetNutrition(
                                                      item,
                                                      BaseModifierId,
                                                      'singleModifier'
                                                    );
                                                  } else {
                                                    setSelectedMenuItem(item);
                                                    setSelectedItemCost(
                                                      item?.price
                                                    );
                                                    setIsModal(true);
                                                  }
                                                }}
                                              >
                                                <Icon name={`nutritional`} />
                                                Nutrional Facts
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </RenderIf>
                            </div>
                          );
                        })}
                      </RenderIf>
                    </div>
                  );
                })}
                <div className={`mt_40`}></div>
              </RenderIf>
            </div>
          </div>

          {/* <RenderIf isTrue={singleBranchData?.categories?.length > 0}>
          <div className={`mt_40`}></div>
          <div className={`pt_40`}></div>
          <div>
            <h2 className={`t_up`}>Product Recommendation</h2>
          </div>
          <div className="mt_15" />
          <Prodscarousel
            prods={singleBranchData?.categories
              ?.map((category, index) => {
                return category?.categoryTypes?.map((categoryType, idx) => {
                  return categoryType?.items?.map((item, i) => {
                    return item;
                  });
                });
              })
              ?.flat(3)}
          />
        </RenderIf> */}
        </div>
        <RenderIf isTrue={width < 992}>
          <footer className={styles.footer}>
            <PrimaryButton
              handleClick={() => {
                navigate('/cart');
              }}
              buttonFull={true}
            >
              Go to Checkout
            </PrimaryButton>
          </footer>
        </RenderIf>
      </article>
      <ModalComponent
        modal={isModal}
        handleModal={() => {
          setIsModal(false);
        }}
        rightModal={false}
        maxWidth={800}
      >
        <div className={styles.nutritionalModalBx}>
          <div className={styles.modal_header}>
            <div>
              <h4 className={`t_up`}>{selectedMenuItem?.item}</h4>
              <div className={`h5`} style={{ color: `var(--primary)` }}>
                <strong>$ {selectedItemCost}</strong>
              </div>
            </div>
            <div>
              <p>{selectedMenuItem?.description}</p>
            </div>
          </div>
          <NutritionFacts
            menuItem={selectedMenuItem}
            nutritionalData={nutritionalData}
          />
        </div>
      </ModalComponent>
      <ModalComponent
        modal={modalIsOpen}
        handleModal={() => {
          setModalIsOpen(false);
        }}
        rightModal={false}
        modalMiddle={true}
      >
        <div className={styles.modalBx}>
          <div className={styles.modal_header}>
            <div>
              <h4>{selectedMenuItem?.item}</h4>
              <div className={`h5`} style={{ color: `var(--primary)` }}>
                <strong>$ {selectedItemCost}</strong>
              </div>
            </div>
            <div>
              <p>{selectedMenuItem?.description}</p>
            </div>
          </div>
          {selectedMenuItem?.groups
            ?.filter((g) => g?.groupName !== 'BASE')
            ?.map((group, i) => {
              return (
                <div key={i}>
                  <h5>
                    <span>{group?.groupName}</span>
                  </h5>
                  <div className={`mt_5`}></div>
                  <div className={`table1`}>
                    {group?.modifiers?.map((item, index) => {
                      return (
                        <div className={styles.addITr} key={index}>
                          <div className={styles.check_name}>
                            <input
                              name={'varientRadio'}
                              type="radio"
                              onChange={() => {
                                handleSelectModifiers(item);
                              }}
                              // defaultChecked={index === 0 ? true : false}
                            />
                            <div>
                              <div className={`h6`}>
                                <strong>{item?.modifier}</strong>
                              </div>
                              <RenderIf isTrue={item?.description}>
                                <p>{item?.description}</p>
                              </RenderIf>
                            </div>
                          </div>
                          <div>
                            <strong>
                              ${' '}
                              {parseFloat(
                                item?.additionalPrice +
                                  calculatePercentage(
                                    item?.additionalPrice,
                                    branch?.markup
                                  ) +
                                  selectedMenuItem?.price
                              )?.toFixed(2)}
                            </strong>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

          <div className={styles.modal_footer}>
            <div className={`flex item_space_btn`}>
              <PrimaryButton handleClick={addToCart}>
                Add to Cart(
                {items?.reduce((a, b) => a + b?.quantity, 0)})
              </PrimaryButton>
              <RenderIf isTrue={items?.length > 0}>
                <SecondaryButton
                  handleClick={() => navigate('/cart')}
                  disabled={items?.length === 0}
                >
                  Go to cart ($
                  {items
                    ?.reduce((a, b) => a + b?.quantity * b?.cost, 0)
                    ?.toFixed(0)}
                  )
                </SecondaryButton>
              </RenderIf>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

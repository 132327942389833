import { Images } from 'constant';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'components/buttons/secondary';
import styles from './index.module.css';

function TopMenu() {
  const navigate = useNavigate();
  return (
    <div className={styles.contaniner}>
      <div className={styles.box}>
        <h2 className="t_up">
          <b>mindful menu, feel good food</b>
        </h2>
        <div className="mt_20"></div>
        <p>
          Chef-crafted, signature burgers. Prepared <b>fresh</b> with the{' '}
          <b>best ingredients</b> and made just for you!
        </p>
        <div className="mt_40"></div>
        <SecondaryButton handleClick={() => navigate('/order-type')}>
          Order now
        </SecondaryButton>
      </div>
      <div className={styles.box}>
        <img src={Images.beefBurger} alt={``} />
      </div>
    </div>
  );
}

export default TopMenu;

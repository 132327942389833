import { RenderIf } from 'helpers';
import styles from './index.module.css';

export const TextField = (props) => {
  const {
    label,
    type,
    id,
    name,
    // value,
    placeholder,
    error,
    register = () => {},
    validation = {},
    info,
    infoChild,
    disabled,
    readOnly = false,
    required = false
  } = props;

  return (
    <div className={styles.field_box}>
      <RenderIf isTrue={label}>
        <div className={styles.labelBox}>
          <label htmlFor={id} className={styles.label}>
            <strong>{label}</strong>
          </label>
          <div>
            {info ? (
              <div className={styles.infobox}>
                {/* <Ikon info /> */}
                <div className={styles.infoChild}>
                  {/* <Ikon indicatorArrow /> */}
                  <div className={styles.infoContent}>{infoChild}</div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </RenderIf>
      <div className={styles.inputbox}>
        <RenderIf isTrue={required}>
          <span className={styles.required}>*</span>
        </RenderIf>
        <input
          {...register(name, validation)}
          type={type}
          name={name}
          className={styles.input}
          id={id}
          // value={value}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ' '}
          readOnly={readOnly}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </div>
  );
};

export const TextFieldWithoutHookForm = (props) => {
  const {
    label,
    type,
    id,
    name,
    value,
    onChange = () => {},
    placeholder,
    error,
    info,
    infoChild,
    disabled,
    readOnly = false,
    required = false
  } = props;

  return (
    <div className={styles.field_box}>
      <RenderIf isTrue={label}>
        <div className={styles.labelBox}>
          <label htmlFor={id} className={styles.label}>
            <strong>{label}</strong>
          </label>
          <div>
            {info ? (
              <div className={styles.infobox}>
                {/* <Ikon info /> */}
                <div className={styles.infoChild}>
                  {/* <Ikon indicatorArrow /> */}
                  <div className={styles.infoContent}>{infoChild}</div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </RenderIf>
      <div className={styles.inputbox}>
        <RenderIf isTrue={required}>
          <span className={styles.required}>*</span>
        </RenderIf>
        <input
          type={type}
          name={name}
          className={styles.input}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ' '}
          readOnly={readOnly}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </div>
  );
};

import { useCart } from 'react-use-cart';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import {
//   CardElement,
//   Elements,
//   useStripe,
//   useElements
// } from '@stripe/react-stripe-js';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { OrderStore, AppStore } from 'store';
import { Toasts } from 'components/toasts';
import { AXIOS_INSTANCE_ZEWARDS, Client } from 'db';
import { PrimaryRadioButton } from 'components/radiobutton';
import { Icon } from 'assets/icons';
import { Images } from 'constant';
import { RenderIf } from 'helpers';
import { Dropdown, Slider } from 'rsuite';
import GearIcon from '@rsuite/icons/Gear';
import styles from './index.module.css';
import { ZewardsStore } from 'store';
import { PrimaryButton } from 'components/buttons/primary';
import { RestaurantStore } from 'store';

export const CheckoutPage = () => {
  const navigate = useNavigate();
  const { items, updateItemQuantity, removeItem } = useCart();
  const { user, guestUser, setGuestUser } = AppStore((state) => state);
  const [totalCost, setTotalCost] = useState(0);
  const [checkingoutLoading, setCheckingoutLoading] = useState(false);
  const [showDeliveryType, setShowDeliveryType] = useState(false);
  const [showDeliveryTypeModal, setShowDeliveryTypeModal] = useState(false);
  const [selectedZewards, setSelectedZewards] = useState(8333);
  const [userPoints, setUserPoints] = useState(null);
  const { deliveryType, setDeliveryType } = OrderStore((state) => state);
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  const {
    zewardsData,
    setZewardsOrderAmount,
    setZewardsPointsForRedemption,
    zewardsPointsForRedemption
  } = ZewardsStore((state) => state);

  const getUserPoints = async () => {
    try {
      const response = (
        await AXIOS_INSTANCE_ZEWARDS.post(`/customer/getPointsDetails`, {
          customerId: user?._id,
          loyaltyId: restaurantData?.theme?.theme?.loyaltyId
        })
      )?.data;
      setUserPoints(response);
      setZewardsPointsForRedemption(response?.totalCustomerPoints?.points);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?._id) {
      getUserPoints();
    }
  }, []);

  useEffect(() => {
    const totalCost = items.reduce((acc, curr) => {
      return acc + curr.cost * curr.quantity;
    }, 0);
    setTotalCost(totalCost);
  }, [items]);

  const handlePayment = async (ordType) => {
    setCheckingoutLoading(true);
    let typeOfOrder = '';

    typeOfOrder =
      deliveryType === 'delivery'
        ? 'DELIVERY'
        : deliveryType === 'pickup'
        ? 'TAKE_AWAY'
        : 'DINE_IN';

    try {
      let totalOrderAmount = parseFloat(
        (
          totalCost +
          parseFloat((totalCost * (items?.[0]?.branchTax / 100))?.toFixed(2))
        )?.toFixed(2)
      );

      if (parseInt(zewardsPointsForRedemption) >= 50) {
        totalOrderAmount =
          totalOrderAmount -
          Number(parseInt(zewardsPointsForRedemption) / 100)?.toFixed(2);
      }

      setZewardsOrderAmount(totalOrderAmount);

      const bodyObj = {
        subTotal: totalCost,
        discount: 0,
        tax: parseFloat(
          (totalCost * (items?.[0]?.branchTax / 100))?.toFixed(2)
        ),
        total: totalOrderAmount,
        brand: restaurantData?.brand?._id,
        branch: items?.[0]?.parentBranch,
        customer: user?._id || guestUser?._id,
        orderVia: 'ZEWST_ONLINE',
        type: typeOfOrder,
        orderRoute:
          restaurantData?.brand?.brandType !== 'CLOUD'
            ? 'BRANCH'
            : 'CLOUD_KITCHEN',
        items: items.map((item) => ({
          itemId: item?._id,
          quantity: item?.quantity,
          unitPrice: item?.cost,
          total: item?.cost * item?.quantity,
          modifiers: item?.modifiers?.map((modifier) => ({
            modifierId: modifier?._id,
            quantity: 1,
            unitPrice: modifier?.additionalPrice,
            total: modifier?.additionalPrice
          })),
          itemOrigin:
            restaurantData?.brand?.brandType === 'CLOUD'
              ? {
                  brand: item?.brandId,
                  branch: item?.branchId
                }
              : null,
          itemOriginMarkup: items?.[0]?.markup
        }))
      };

      let response = await Client.post('/orders/checkout', bodyObj);
      response = response.data?.data;
      Toasts.success('Redirecting to payment gateway...');
      setCheckingoutLoading(false);
      navigate(`/payment`, {
        state: {
          clientSecret: response?.clientSecret?.[0],
          orderId: response?._id,
          tokenId: response?.clientSecret?.[0]?._id
        }
      });
    } catch (error) {
      setCheckingoutLoading(false);
      Toasts.error('Unable to process your order. Please try again later.');
    }
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.contain}>
          <div className={styles.headRow}>
            <h3 className={`fsemi`}>Checkout</h3>
            <div className={styles.headAmount}>
              <div className={styles.icon}>
                <Icon name={`zewst_coin`} />
              </div>
              <div>
                <b>{userPoints?.totalCustomerPoints?.points || 0}</b>
              </div>
              <div className={styles.icon}>
                <Icon name={`help`} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <article className={styles.article}>
        <div className={styles.contain}>
          <RenderIf isTrue={items?.length > 0}>
            <div className={styles.grid}>
              <div>
                <h4 className={`fsemi`}>Payment</h4>
                <div style={{ marginTop: `8px` }}></div>
                <p>Please choose the payment method.</p>
                <div style={{ marginTop: `21px` }}></div>
                <div className={styles.payAbleItem}>
                  <PrimaryRadioButton
                    id={`credit_card`}
                    name={`payment_method`}
                    value={`CREDIT_CARD`}
                    label={`Credit/Debit Card`}
                    defaultChecked
                  ></PrimaryRadioButton>
                  <div className={styles.flags}>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagMaster} />
                        <img src={Images?.flagMaster} alt="Master Card" />
                      </picture>
                    </div>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagVisa} />
                        <img src={Images?.flagVisa} alt="Master Card" />
                      </picture>
                    </div>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagAmerican} />
                        <img src={Images?.flagAmerican} alt="Master Card" />
                      </picture>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: `21px` }}></div>
                <div className={styles.payAbleItem}>
                  <PrimaryRadioButton
                    id={`apple_pay`}
                    name={`payment_method`}
                    value={`APPLE_PAY`}
                    label={`Apple Pay`}
                  ></PrimaryRadioButton>
                  <div className={styles.flags}>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagMaster} />
                        <img src={Images?.flagMaster} alt="Master Card" />
                      </picture>
                    </div>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagVisa} />
                        <img src={Images?.flagVisa} alt="Master Card" />
                      </picture>
                    </div>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagAmerican} />
                        <img src={Images?.flagAmerican} alt="Master Card" />
                      </picture>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: `21px` }}></div>
                <div className={styles.payAbleItem}>
                  <PrimaryRadioButton
                    id={`paypal`}
                    name={`payment_method`}
                    value={`PAYPAL`}
                    label={`PayPal`}
                  ></PrimaryRadioButton>
                  <div className={styles.flags}>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagMaster} />
                        <img src={Images?.flagMaster} alt="Master Card" />
                      </picture>
                    </div>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagVisa} />
                        <img src={Images?.flagVisa} alt="Master Card" />
                      </picture>
                    </div>
                    <div className={styles.flag}>
                      <picture>
                        <source srcSet={Images?.flagAmerican} />
                        <img src={Images?.flagAmerican} alt="Master Card" />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.orderList}>
                  {items?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className={styles.orderItem}>
                          <div className={styles.itemInfo}>
                            <div className={styles.itemImg}>
                              <picture>
                                <source srcSet={item?.image} />
                                <img src={item?.image} alt={``} />
                              </picture>
                            </div>
                            <div className={`fsemi`}>{item?.item}</div>
                          </div>
                          <div className={styles.itemTotal}>
                            <div className={styles.itemPrice}>
                              $ {item?.itemTotal?.toFixed(2)}
                            </div>
                            <div className={styles.itemQuantity}>
                              <button
                                title={`decrement`}
                                type={`button`}
                                className={`alink`}
                                onClick={() =>
                                  updateItemQuantity(
                                    item?.menuId,
                                    item?.quantity - 1
                                  )
                                }
                                disabled={item?.quantity === 1}
                              >
                                <Icon name={`minus_small`} />
                              </button>
                              <div>{item?.quantity}</div>
                              <button
                                title={`increment`}
                                type={`button`}
                                className={`alink`}
                                onClick={() =>
                                  updateItemQuantity(
                                    item?.menuId,
                                    item?.quantity + 1
                                  )
                                }
                              >
                                <Icon name={`plus_small`} />
                              </button>
                            </div>
                            <div>
                              <button
                                type={`button`}
                                className={`alink`}
                                onClick={() => removeItem(item?.menuId)}
                                title={`remove item`}
                              >
                                <Icon name={`delete`} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div style={{ marginTop: `20px` }}></div>
                <div className={styles.calculateRow}>
                  <div>
                    <span>Subtotal</span>
                    <span>$ {totalCost?.toFixed(2)}</span>
                  </div>
                  <div>
                    <span>Tax</span>
                    <span>
                      ${' '}
                      {(totalCost * (items?.[0]?.branchTax / 100))?.toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <span>Delivery Fee</span>
                    <span>$ 0.00</span>
                  </div>
                </div>
                <div style={{ marginTop: `20px` }}></div>
                <hr />
                <div style={{ marginTop: `21px` }}></div>
                <div className={styles.calculateRow}>
                  <div>
                    <span>Total</span>
                    <span className={styles.grandTotal}>
                      $&nbsp;
                      {(
                        totalCost * (items?.[0]?.branchTax / 100) +
                        totalCost
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>
                <RenderIf
                  isTrue={userPoints?.totalCustomerPoints?.points >= 50}
                >
                  <div style={{ marginTop: `21px` }}></div>
                  <hr />
                  <div style={{ marginTop: `17px` }}></div>
                  <div className={styles.calculateRow}>
                    <div className={styles.calculateZeward}>
                      <div>
                        <span className={`fsemi`}>Zewards Redeeming</span>
                        <span>
                          <Icon name={`zewst_coin`} />
                        </span>
                        <span>{zewardsPointsForRedemption}</span>
                      </div>
                      <span className={styles.rewardAmount}>
                        - ${' '}
                        {Number(
                          parseInt(zewardsPointsForRedemption) / 100
                        )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginTop: `47px` }}></div>
                  <div className={styles.zewards}>
                    <header className={styles.header_zeward}>
                      <div>How much you want to redeem?</div>
                      <div>
                        <div>
                          Zewards Left{' '}
                          {parseInt(userPoints?.totalCustomerPoints?.points) -
                            parseInt(zewardsPointsForRedemption)}
                        </div>
                        {/* <div className={`zeward_dd`}>
                          <Dropdown icon={<GearIcon />} placement="bottomEnd">
                            <Dropdown.Item
                              onClick={() => navigate(`/advance-mode`)}
                            >
                              Advance mode
                            </Dropdown.Item>
                          </Dropdown>
                        </div> */}
                      </div>
                    </header>
                    <div style={{ marginTop: `11px` }}></div>
                    <div className={styles.body_zeward}>
                      <div>
                        <div>
                          <Icon name={`zewst_coin`} />
                        </div>
                        <h1 className={styles.h1}>
                          {zewardsPointsForRedemption}
                        </h1>
                      </div>
                      <div>
                        <Slider
                          progress
                          min={50}
                          max={userPoints?.totalCustomerPoints?.points}
                          defaultValue={userPoints?.totalCustomerPoints?.points}
                          onChange={(value) => {
                            setSelectedZewards(value);
                            setZewardsPointsForRedemption(value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: `24px` }}></div>
                    <footer className={styles.footer_zeward}>
                      <div>Zewards Available</div>
                      <div style={{ marginTop: `13px` }}></div>
                      <div className={styles.count_rewards}>
                        <div>
                          <div>
                            {
                              userPoints?.customerOrdersWithBranches?.find(
                                (branch) =>
                                  branch?.branch?.[0]?.externalId ===
                                  `${zewardsData?.branchId}`
                              )?.brand?.[0]?.name
                            }
                          </div>
                          <div>
                            <div className={styles.branchZeward}>
                              <label>Current</label>
                              <span>
                                {userPoints?.customerOrdersWithBranches?.find(
                                  (branch) =>
                                    branch?.branch?.[0]?.externalId ===
                                    `${zewardsData?.branchId}`
                                )?.points || 0}
                              </span>
                              <span>
                                <Icon name={`zeward_check`} />
                              </span>
                            </div>
                            <div className={styles.branchZeward}>
                              <label>In-Network</label>
                              <span>
                                {userPoints?.customerOrdersWithBranches
                                  ?.filter(
                                    (branch) =>
                                      branch?.branch?.[0]?.externalId !==
                                        `${zewardsData?.branchId}` &&
                                      branch?.brand?.[0]?.externalId ===
                                        `${zewardsData?.brandId}`
                                  )
                                  ?.reduce((acc, curr) => {
                                    return acc + curr?.points;
                                  }, 0) || 0}
                              </span>
                              <span>
                                <Icon name={`zeward_check`} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>Out of Network</div>
                          <div>
                            <div className={styles.branchZeward}>
                              <span>
                                {' '}
                                {userPoints?.customerOrdersWithBranches
                                  ?.filter(
                                    (branch) =>
                                      branch?.branch?.[0]?.externalId !==
                                        `${zewardsData?.branchId}` &&
                                      branch?.brand?.[0]?.externalId !==
                                        `${zewardsData?.brandId}`
                                  )
                                  ?.reduce((acc, curr) => {
                                    return acc + curr?.points;
                                  }, 0) || 0}
                              </span>
                              <span>
                                <Icon name={`zeward_check`} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </div>
                  <div style={{ marginTop: `32px` }}></div>
                  <hr />
                  <div style={{ marginTop: `38px` }}></div>
                  <div className={styles.calculateRow}>
                    <div>
                      <span>Total</span>
                      <span className={styles.grandTotal}>
                        $
                        {(
                          totalCost * (items?.[0]?.branchTax / 100) +
                          totalCost
                        )?.toFixed(2) -
                          Number(
                            parseInt(zewardsPointsForRedemption) / 100
                          )?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </RenderIf>
                <div style={{ marginTop: `27px` }}></div>
                <PrimaryButton
                  type={`button`}
                  disabled={checkingoutLoading}
                  handleClick={() => {
                    if (showDeliveryType) {
                      setShowDeliveryTypeModal(true);
                    } else {
                      handlePayment();
                    }
                  }}
                  buttonFull={true}
                >
                  Place your order now
                </PrimaryButton>
              </div>
            </div>
          </RenderIf>
        </div>
      </article>
    </>
  );
};

// const CheckoutForm = ({ stripeOptions, orderId, tokenId }) => {
//   const navigate = useNavigate();
//   const { user, guestUser } = AppStore((state) => state);

//   const stripe = useStripe();
//   const elements = useElements();

//   const [isPaymentLoading, setPaymentLoading] = useState(false);

//   const payMoney = async (e) => {
//     e.preventDefault();
//     if (!stripe || !elements) {
//       return;
//     }
//     setPaymentLoading(true);
//     const clientSecret = stripeOptions?.clientSecret;
//     const paymentResult = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: {
//         card: elements.getElement(CardElement),
//         billing_details: {
//           name: user?.fullName || guestUser?.fullName
//         }
//       }
//     });
//     setPaymentLoading(false);
//     if (paymentResult.error) {
//       Toasts.error(paymentResult.error.message);
//     } else {
//       if (paymentResult.paymentIntent.status === 'succeeded') {
//         await Client.post('/orders/token/expire', {
//           id: orderId,
//           tokenId: tokenId
//         });
//         Toasts.success('Payment Successful');
//         navigate('/ordersuccess', {
//           state: { message: 'Payment Successful' }
//         });
//       }
//     }
//   };

//   return (
//     <div>
//       <div>
//         <form onSubmit={payMoney}>
//           <div className={`mt_40`}></div>
//           <CardElement
//             className={styles.card}
//             options={{
//               style: {
//                 base: {
//                   backgroundColor: 'var(--accentbg)'
//                 }
//               }
//             }}
//           />
//           <div className={`mt_40`}></div>
//           <div className={`flex item_hcenter`}>
//             <button
//               className={`button1 t_up ${styles.button1}`}
//               disabled={isPaymentLoading}
//             >
//               {isPaymentLoading ? 'Loading...' : 'Pay'}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { AppStore, AuthPageStore } from 'store';
import { RenderIf } from 'helpers';
import { useFetchWindowDimensions } from 'hooks/windowdimensions/useFetchWindowDimensions';
import { PrimaryButton } from 'components/buttons/primary';
import { SecondaryButton } from 'components/buttons/secondary';
import { TertiaryButton } from 'components/buttons/tertiary';
import { YourOrder } from 'pages/yourorder';
import { useCart } from 'react-use-cart';
import { SelectedBranchStore } from 'store';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { RestaurantStore } from 'store';
import { OrderStore } from 'store';
import { OrderTypeUserLocation } from 'components/typelocation';

export const Header = () => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const [searchParams] = useSearchParams();
  const clearCart = searchParams.get('clearCart');

  const { setDeliveryType } = OrderStore((state) => state);
  const { restaurantData } = RestaurantStore((state) => state);

  const content = restaurantData?.theme?.header?.content;
  const style = restaurantData?.theme?.header?.style;

  const { width } = useFetchWindowDimensions();
  const { user, setUser } = AppStore((state) => state);
  const { setPage, setAccountStep } = AuthPageStore((state) => state);
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [profilePop, setProfilePop] = useState(false);
  const [cartHasData, setCartHasData] = useState(false);
  const { setBranchWithCartItems } = SelectedBranchStore((state) => state);
  const { items, emptyCart } = useCart();
  const [zewardBar, setZewardBar] = useState(true);
  const [orderType, setOrderType] = useState('pickup');

  useEffect(() => {
    setOrderType(`pickup`);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setProfilePop(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleNavigation = () => {
    if (restaurantData?.brand?.brandType === 'CLOUD') {
      if (items?.length > 0) {
        const cartItemsBranch = restaurantData?.brand?.branches?.filter(
          (branch) => branch?._id === items?.[0]?.parentBranch
        )?.[0];
        navigate(`/cloud-brand-branch-menu`, {
          state: {
            cloudBrandsInfo: cartItemsBranch?.cloudBrands,
            orderType,
            parentBranch: cartItemsBranch?._id,
            branchTax: cartItemsBranch?.tax
          }
        });
      } else {
        navigate('/cloud-brand-branches');
      }
    } else {
      navigate('/brand-orders', {
        state: {
          branches: restaurantData?.brand?.branches?.map((b) => ({
            ...b,
            markup: 0,
            parentBranch: b?._id,
            branchTax: b?.tax
          }))
        }
      });
    }
  };

  const handleDeliverLink = () => {
    window.location.href = `tel:${restaurantData?.brand?.brandPhone}`;
  };

  useEffect(() => {
    if (clearCart) {
      emptyCart();
      setBranchWithCartItems(null);
    }
  }, [clearCart]);

  return (
    <>
      {/* <RenderIf isTrue={user && !user?.address?.length}>
        <div className={styles.top_bar}>
          <div>
            <p>
              Please complete your{' '}
              <button
                type={`button`}
                className={`alink fsemi`}
                onClick={() => {
                  setPage(`account`);
                  navigate('/account');
                }}
              >
                profile
              </button>{' '}
              and <span className={`fsemi`}>Win 500</span> rewards
            </p>
          </div>
        </div>
      </RenderIf> */}
      {/* <RenderIf isTrue={zewardBar}>
        <article className={styles.zewardBar}>
          <div className={styles.zwdContent}>
            <div className={styles.zwdCol}>
              <div className={styles.icon}>
                <Icon name={`zewst_coin`} />
              </div>
              <div>
                Sign Up and Get Free <span>300 Zewards.</span>
              </div>
              <Link to={`/`}>Sign Up Now!</Link>
            </div>
            <div className={styles.zwdCol}>
              <div className={styles.icon}>
                <Icon name={`zewst_coin`} />
              </div>
              <Link to={`/`}>What is Zewards?</Link>
              <TertiaryButton
                handleClick={() => setZewardBar(!zewardBar)}
                mediaOnly={true}
              >
                <Icon name={`close-zwd-bar`} fillColor={`white`} />
              </TertiaryButton>
            </div>
          </div>
        </article>
      </RenderIf> */}
      <header
        id={`Header`}
        className={styles.header}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage:
            style?.backgroundImage !== ``
              ? `url(${style?.backgroundImage})`
              : ``,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`,
          position: content?.sticky ? `sticky` : `relative`,
          top: content?.sticky ? `0` : ``,
          zIndex: content?.sticky ? `var(--headerIndex)` : `var(--headerIndex)`,
          borderRadius: `${content?.roundedCorners}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``,
            paddingRight: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``
          }}
        >
          <RenderIf isTrue={width < 992}>
            <OrderTypeUserLocation />
            <div style={{ marginBottom: 8 }}></div>
          </RenderIf>
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
          >
            <div className={styles.leftBx}>
              <div className={styles.logo}>
                <RenderIf isTrue={content?.showLogo}>
                  <Link to={`/`}>
                    <picture>
                      <source
                        srcSet={restaurantData?.theme?.global?.content?.logo}
                      />
                      <img
                        src={restaurantData?.theme?.global?.content?.logo}
                        alt={`Logo`}
                        style={{
                          width: !content?.logoSizeAsGlobal
                            ? `${content?.logoWidth}px`
                            : `${restaurantData?.theme?.global?.content?.logoWidth}px`,
                          height: `auto`
                        }}
                      />
                    </picture>
                  </Link>
                </RenderIf>
              </div>
              <RenderIf isTrue={width > 991}>
                <div>
                  <OrderTypeUserLocation />
                </div>
              </RenderIf>
            </div>

            <div className={styles.toggleMenu}>
              <button
                onClick={() => {
                  setIsActiveMenu(!isActiveMenu);
                }}
              >
                <Icon
                  name={`mobile-menu`}
                  fillColor={
                    style?.foregroundColor || `var(--foreground-color)`
                  }
                />
              </button>
            </div>

            <RenderIf isTrue={isActiveMenu}>
              <div
                className={
                  isActiveMenu
                    ? `${styles.overlay} ${styles.active}`
                    : styles.overlay
                }
                onClick={() => {
                  setIsActiveMenu(!isActiveMenu);
                }}
              ></div>
            </RenderIf>

            <div
              className={
                isActiveMenu
                  ? `${styles.layoutInner} ${styles.menuActive}`
                  : styles.layoutInner
              }
              style={{
                background: isActiveMenu
                  ? style?.backgroundColor || `var(--background-color)`
                  : ``
              }}
            >
              <RenderIf
                isTrue={
                  restaurantData?.brand?.brandPhone &&
                  restaurantData?.brand?.brandPhone !== null
                }
              >
                <PrimaryButton
                  handleClick={() => {
                    handleDeliverLink();
                  }}
                >
                  CALL TO DELIVER
                </PrimaryButton>
              </RenderIf>

              <PrimaryButton
                handleClick={() => {
                  setDeliveryType('pickup');
                  // navigate('/order-type');
                  handleNavigation();
                  setIsActiveMenu(false);
                }}
              >
                ORDER NOW
              </PrimaryButton>
              <RenderIf isTrue={!user}>
                <SecondaryButton
                  handleClick={() => {
                    setPage(`createAccount`);
                    setAccountStep(0);
                    navigate('/account');
                    setIsActiveMenu(false);
                  }}
                >
                  REGISTER
                </SecondaryButton>
                <TertiaryButton
                  handleClick={() => {
                    setPage(`login`);
                    setAccountStep(0);
                    navigate('/account');
                    setIsActiveMenu(false);
                  }}
                  textColor={
                    style?.foregroundColor || `var(--foreground-color)`
                  }
                >
                  Log in
                </TertiaryButton>
              </RenderIf>
              <RenderIf isTrue={user}>
                <RenderIf isTrue={width > 991}>
                  <div className={styles.profile_pop} ref={ref}>
                    <TertiaryButton
                      handleClick={() => setProfilePop(!profilePop)}
                      mediaOnly={true}
                    >
                      <div
                        className={styles.loggedUser}
                        style={{
                          background:
                            style?.foregroundColor || `var(--foreground-color)`,
                          color:
                            style?.backgroundColor || `var(--background-color)`
                        }}
                      >
                        <Icon
                          name={`user`}
                          fillColor={
                            style?.backgroundColor || `var(--background-color)`
                          }
                        />
                      </div>
                      {/* <div className={styles.loggedUser}>{loggedName}</div> */}
                    </TertiaryButton>
                    <RenderIf isTrue={profilePop || isActiveMenu}>
                      <div
                        className={styles.pop_1}
                        style={{
                          background:
                            style?.foregroundColor || `var(--foreground-color)`,
                          border: `${
                            style?.foregroundColor || `var(--foreground-color)`
                          } 1px solid`
                        }}
                      >
                        <div
                          className={styles.pop_1_before}
                          style={{
                            background:
                              style?.foregroundColor ||
                              `var(--foreground-color)`,
                            borderColor:
                              style?.foregroundColor ||
                              `var(--foreground-color)`
                          }}
                        ></div>
                        <ul className={styles.profile_nav}>
                          <li>
                            <TertiaryButton
                              handleClick={() => {
                                setPage(`account`);
                                navigate('/account');
                                setProfilePop(!profilePop);
                              }}
                              textColor={
                                style?.backgroundColor ||
                                `var(--background-color)`
                              }
                            >
                              Manage My Account
                            </TertiaryButton>
                          </li>
                          <li>
                            <TertiaryButton
                              handleClick={() => {
                                setUser(null);
                                emptyCart();
                                setBranchWithCartItems(null);
                                localStorage.removeItem('user');
                                setProfilePop(!profilePop);
                              }}
                              textColor={
                                style?.backgroundColor ||
                                `var(--background-color)`
                              }
                            >
                              Logout
                            </TertiaryButton>
                          </li>
                        </ul>
                      </div>
                    </RenderIf>
                  </div>
                </RenderIf>
                <RenderIf isTrue={width < 992}>
                  <div className={styles.user_detail_mobile}>
                    <div
                      className={styles.loggedUser}
                      style={{
                        background: style?.foregroundColor,
                        color: style?.backgroundColor
                      }}
                    >
                      <Icon
                        name={`user`}
                        fillColor={
                          style?.backgroundColor || `var(--background-color)`
                        }
                      />
                    </div>
                    <ul className={styles.profile_nav_mobile}>
                      <li>
                        <TertiaryButton
                          handleClick={() => {
                            setPage(`account`);
                            navigate('/account');
                            setProfilePop(!profilePop);
                            setIsActiveMenu(false);
                          }}
                          textColor={
                            style?.foregroundColor || `var(--foreground-color)`
                          }
                        >
                          Manage My Account
                        </TertiaryButton>
                      </li>
                      <li>
                        <TertiaryButton
                          handleClick={() => {
                            setUser(null);
                            emptyCart();
                            setBranchWithCartItems(null);
                            localStorage.removeItem('user');
                            setProfilePop(!profilePop);
                            setIsActiveMenu(false);
                          }}
                          textColor={
                            style?.foregroundColor || `var(--foreground-color)`
                          }
                        >
                          Logout
                        </TertiaryButton>
                      </li>
                    </ul>
                    <div
                      className={styles.user_detail_mobile_hr}
                      style={{
                        borderTop: `${
                          style?.foregroundColor || `var(--foreground-color)`
                        } 1px solid`,
                        opacity: 0.24
                      }}
                    ></div>
                  </div>
                </RenderIf>
                <TertiaryButton
                  handleClick={() => {
                    navigate('/zewards-wallet');
                    setIsActiveMenu(false);
                  }}
                  mediaOnly={true}
                >
                  <Icon name="zeward_coin" />
                </TertiaryButton>
              </RenderIf>
              <RenderIf isTrue={width > 991}>
                <div
                  className={styles.cart_control}
                  onMouseLeave={() => {
                    setCartHasData(false);
                  }}
                >
                  <button
                    type={`button`}
                    className={styles.button_cart}
                    onClick={() => {
                      navigate('/cart');
                      setIsActiveMenu(false);
                    }}
                    onMouseOver={() => {
                      setCartHasData(true);
                    }}
                  >
                    <Icon
                      name={`cart`}
                      fillColor={
                        style?.foregroundColor || `var(--foreground-color)`
                      }
                    />
                    <span
                      style={{
                        background:
                          style?.foregroundColor || `var(--foreground-color)`,
                        color:
                          style?.backgroundColor || `var(--background-color)`
                      }}
                    >
                      {items?.reduce((a, b) => a + b?.quantity, 0)}
                    </span>
                  </button>
                  <RenderIf isTrue={cartHasData}>
                    <div className={styles.cart_popup}>
                      <YourOrder />
                    </div>
                  </RenderIf>
                </div>
              </RenderIf>
              <RenderIf isTrue={width < 992}>
                <button
                  type={`button`}
                  className={styles.button_cart}
                  onClick={() => {
                    navigate('/cart');
                    setIsActiveMenu(false);
                  }}
                >
                  <Icon
                    name={`cart`}
                    fillColor={
                      style?.foregroundColor || `var(--foreground-color)`
                    }
                  />
                  <span
                    style={{
                      background:
                        style?.foregroundColor || `var(--foreground-color)`,
                      color: style?.backgroundColor || `var(--background-color)`
                    }}
                  >
                    {items?.reduce((a, b) => a + b?.quantity, 0)}
                  </span>
                </button>
              </RenderIf>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

// export const WebMenu = ({ user, setUser, isActiveMenu }) => {
//   const navigate = useNavigate();
//   const ref = useRef(null);
//   const { resData?.theme } = useFetchRestaurantHook();
//   const { setPage } = AuthPageStore((state) => state);
//   const [cartHasData, setCartHasData] = useState(false);
//   const [profilePop, setProfilePop] = useState(false);
//   const { setBranchWithCartItems } = SelectedBranchStore((state) => state);

//   const { items, emptyCart } = useCart();

//   useEffect(() => {
//     const handleClickOutside = (e) => {
//       if (ref.current && !ref.current.contains(e.target)) {
//         setProfilePop(false);
//       }
//     };
//     document.addEventListener('click', handleClickOutside, true);
//     return () => {
//       document.removeEventListener('click', handleClickOutside, true);
//     };
//   }, []);

//   return (
//     <>
//       <RenderIf isTrue={resData?.theme?.brand?.brandType === 'CLOUD'}>
//         <PrimaryButton
//           handleClick={() => {
//             navigate('/order-type');
//           }}
//         >
//           Order now
//         </PrimaryButton>
//       </RenderIf>
//       <RenderIf isTrue={resData?.theme?.brand?.brandType !== 'CLOUD'}>
//         <PrimaryButton
//           handleClick={() => {
//             navigate('/order-type');
//           }}
//         >
//           Order now
//         </PrimaryButton>
//       </RenderIf>
//       <RenderIf isTrue={user}>
//         <button
//           type={`button`}
//           className={styles.button_zeward}
//           onClick={() => {
//             navigate('/zewards-wallet');
//           }}
//         >
//           <Icon name="zeward_coin" />
//         </button>
//       </RenderIf>
//       <RenderIf isTrue={!user}>
//         <TertiaryButton
//           handleClick={() => {
//             setPage(`login`);
//             navigate('/account');
//           }}
//           color={`#2A2F31`}
//         >
//           <Icon name="login" />
//           <span>Log in</span>
//         </TertiaryButton>
//         <QuaternaryButton
//           handleClick={() => {
//             setPage(`createAccount`);
//             navigate('/account');
//           }}
//         >
//           <span>Sign up</span>
//         </QuaternaryButton>
//       </RenderIf>
//       <div
//         className={styles.cart_control}
//         onMouseLeave={() => {
//           setCartHasData(false);
//         }}
//       >
//         <button
//           type={`button`}
//           className={styles.button_cart}
//           onClick={() => navigate('/cart')}
//           onMouseOver={() => {
//             setCartHasData(true);
//           }}
//         >
//           <Icon name="cart" />
//           <span>{items?.reduce((a, b) => a + b?.quantity, 0)}</span>
//         </button>
//         <RenderIf isTrue={cartHasData}>
//           <div className={styles.cart_popup}>
//             <YourOrder />
//           </div>
//         </RenderIf>
//       </div>
//       <RenderIf isTrue={user}>
//         <div className={styles.profile_pop} ref={ref}>
//           <TertiaryButton
//             handleClick={() => setProfilePop(!profilePop)}
//             mediaOnly
//           >
//             <div className={styles.loggedUser}>
//               <Icon name={`user`} />
//             </div>
//           </TertiaryButton>
//           <RenderIf isTrue={profilePop || isActiveMenu}>
//             <div className={styles.pop_1}>
//               <ul className={styles.profile_nav}>
//                 <li>
//                   <TertiaryButton
//                     handleClick={() => {
//                       setPage(`account`);
//                       navigate('/account');
//                       setProfilePop(!profilePop);
//                     }}
//                   >
//                     Manage My Account
//                   </TertiaryButton>
//                 </li>
//                 <li>
//                   <TertiaryButton
//                     handleClick={() => {
//                       setUser(null);
//                       emptyCart();
//                       setBranchWithCartItems(null);
//                       localStorage.removeItem('user');
//                       setProfilePop(!profilePop);
//                     }}
//                   >
//                     Logout
//                   </TertiaryButton>
//                 </li>
//               </ul>
//             </div>
//           </RenderIf>
//         </div>
//       </RenderIf>
//     </>
//   );
// };

// export const MobileMenu = ({ user, setUser, setIsActiveMenu }) => {
//   const navigate = useNavigate();
//   const ref = useRef(null);
//   const { resData?.theme } = useFetchRestaurantHook();
//   const { setPage } = AuthPageStore((state) => state);
//   const [profilePop, setProfilePop] = useState(false);
//   const { setBranchWithCartItems } = SelectedBranchStore((state) => state);

//   const { items, emptyCart } = useCart();

//   useEffect(() => {
//     const handleClickOutside = (e) => {
//       if (ref.current && !ref.current.contains(e.target)) {
//         setProfilePop(false);
//       }
//     };
//     document.addEventListener('click', handleClickOutside, true);
//     return () => {
//       document.removeEventListener('click', handleClickOutside, true);
//     };
//   }, []);

//   return (
//     <>
//       <RenderIf isTrue={resData?.theme?.brand?.brandType === 'CLOUD'}>
//         <PrimaryButton
//           handleClick={() => {
//             navigate('/order-type');
//             setIsActiveMenu(false);
//           }}
//           buttonFull={true}
//         >
//           Order now
//         </PrimaryButton>
//       </RenderIf>
//       <RenderIf isTrue={resData?.theme?.brand?.brandType !== 'CLOUD'}>
//         <PrimaryButton
//           handleClick={() => {
//             navigate('/order-type');
//             setIsActiveMenu(false);
//           }}
//           buttonFull={true}
//         >
//           Order now
//         </PrimaryButton>
//       </RenderIf>
//       <RenderIf isTrue={user}>
//         <button
//           type={`button`}
//           className={styles.button_zeward_mobile}
//           onClick={() => {
//             navigate('/zewards-wallet');
//             setIsActiveMenu(false);
//           }}
//           buttonFull={true}
//         >
//           <span>My Zewards</span>
//           <Icon name="zeward_coin" />
//         </button>
//       </RenderIf>
//       <RenderIf isTrue={!user}>
//         <TertiaryButton
//           handleClick={() => {
//             setPage(`login`);
//             navigate('/account');
//             setIsActiveMenu(false);
//           }}
//           color={`#2A2F31`}
//           buttonFull={true}
//         >
//           <Icon name="login" />
//           <span>Log in</span>
//         </TertiaryButton>
//         <QuaternaryButton
//           handleClick={() => {
//             setPage(`createAccount`);
//             navigate('/account');
//             setIsActiveMenu(false);
//           }}
//           buttonFull={true}
//         >
//           <span>Sign up</span>
//         </QuaternaryButton>
//       </RenderIf>
//       <button
//         type={`button`}
//         className={styles.button_cart_mobile}
//         onClick={() => {
//           navigate('/cart');
//           setIsActiveMenu(false);
//         }}
//         buttonFull={true}
//       >
//         <div>My Cart</div>
//         <Icon name="cart" />
//         <span>{items?.reduce((a, b) => a + b?.quantity, 0)}</span>
//       </button>
//       <RenderIf isTrue={user}>
//         <div className={styles.user_detail_mobile_hr}></div>
//         <div className={styles.user_detail_mobile}>
//           <div className={styles.loggedUser}>
//             <Icon name={`user`} />
//           </div>
//           <ul className={styles.profile_nav_mobile}>
//             <li>
//               <TertiaryButton
//                 handleClick={() => {
//                   setPage(`account`);
//                   navigate('/account');
//                   setProfilePop(!profilePop);
//                   setIsActiveMenu(false);
//                 }}
//               >
//                 Manage My Account
//               </TertiaryButton>
//             </li>
//             <li>
//               <TertiaryButton
//                 handleClick={() => {
//                   setUser(null);
//                   emptyCart();
//                   setBranchWithCartItems(null);
//                   localStorage.removeItem('user');
//                   setProfilePop(!profilePop);
//                   setIsActiveMenu(false);
//                 }}
//               >
//                 Logout
//               </TertiaryButton>
//             </li>
//           </ul>
//         </div>
//       </RenderIf>
//     </>
//   );
// };

import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

export const RestaurantStore = create(
  persist(
    immer((set) => ({
      restaurantData: null,
      setRestaurantData: (data) =>
        set((state) => {
          state.restaurantData = data;
        }),
      openedRewardModal: false,
      setOpenedRewardModal: (val) =>
        set((state) => {
          state.openedRewardModal = val;
        }),
      deliveryEnabled: false,
      setDeliveryEnabled: (val) =>
        set((state) => {
          state.deliveryEnabled = val;
        }),
      deliveryFee: 0,
      setDeliveryFee: (val) =>
        set((state) => {
          state.deliveryFee = val;
        })
    })),
    {
      name: 'zewst-online-restaurant-data'
    }
  )
);

export const AppStore = create(
  persist(
    immer((set) => ({
      user: null,
      setUser: (data) =>
        set((state) => {
          state.user = data;
          state.guestUser = null;
        }),
      guestUser: null,
      setGuestUser: (data) =>
        set((state) => {
          state.user = null;
          state.guestUser = data;
        }),
      openChatModal: false,
      setOpenChatModal: (val) =>
        set((state) => {
          state.openChatModal = val;
        }),
      messages: [],
      setMessages: (val) =>
        set((state) => {
          if (
            state?.messages?.find((message) => message?.index === val?.index)
          ) {
            return;
          } else {
            state.messages.push(val);
          }
        }),
      clearMessages: () =>
        set((state) => {
          state.messages = [];
        })
    })),
    {
      name: 'zewst-online-store',
      getStorage: () => localStorage
    }
  )
);

export const OrderStore = create(
  immer((set) => ({
    deliveryType: 'Delivery',
    setDeliveryType: (type) =>
      set((state) => {
        state.deliveryType = type;
      })
  }))
);

export const ClientOrderStore = create(
  persist(
    immer((set) => ({
      orderType: 'Delivery',
      setOrderType: (type) =>
        set((state) => {
          state.orderType = type;
        }),
      orderAddress: '',
      setOrderAddress: (address) =>
        set((state) => {
          state.orderAddress = address;
        })
    })),
    {
      name: 'client-order-data'
    }
  )
);

export const AuthPageStore = create(
  immer((set) => ({
    page: '',
    setPage: (val) =>
      set((state) => {
        state.page = val;
      }),
    accountStep: 0,
    setAccountStep: (val) =>
      set((state) => {
        state.accountStep = val;
      })
  }))
);

export const SelectedBranchStore = create(
  persist(
    immer((set) => ({
      branchWithCartItems: null,
      setBranchWithCartItems: (val) =>
        set((state) => {
          state.branchWithCartItems = val;
        })
    })),
    {
      name: 'selected-branch',
      getStorage: () => localStorage
    }
  )
);

export const ZewardsStore = create(
  persist(
    immer((set) => ({
      zewardsData: null,
      setZewardsData: (data) =>
        set((state) => {
          state.zewardsData = data;
        }),
      zewardsOrderAmount: null,
      setZewardsOrderAmount: (amount) =>
        set((state) => {
          state.zewardsOrderAmount = amount;
        }),
      zewardsPointsForRedemption: 0,
      setZewardsPointsForRedemption: (points) =>
        set((state) => {
          state.zewardsPointsForRedemption = points;
        })
    })),
    {
      name: 'zewst-online-zewards'
      //getStorage: () => localStorage
    }
  )
);

import { JSONData } from 'store/pagesjson';
import styles from './index.module.css';
import { RestaurantStore } from 'store';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';

export const TertiaryButton = ({
  type = `button`,
  textColor = ``,
  mediaOnly = false,
  handleClick = () => {},
  children,
  buttonFull = false
}) => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);

  //const resData = JSONData;
  const primary = restaurantData?.theme?.global?.style?.button?.primary;

  return (
    <button
      type={type}
      className={styles.button}
      onClick={handleClick}
      style={{
        width: buttonFull ? `100%` : ``,
        color: textColor === `` ? primary?.backgroundColor : textColor,
        backgroundColor: `transparent`,
        borderRadius: `0px`,
        lineHeight: mediaOnly ? 0 : `normal`
      }}
    >
      {children}
    </button>
  );
};

import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Client } from 'db';
import { TextField } from 'components/input';
import { PhoneField } from 'components/phone';
import { Toasts } from 'components/toasts';
import { RenderIf } from 'helpers';
import { AppStore, AuthPageStore } from 'store';
import { ProgressSteps } from 'components/progress/steps';
import { ProfilePage } from './profile';
import { Images } from 'constant';
import styles from './index.module.css';
import { PrimaryButton } from 'components/buttons/primary';

export const AccountPage = ({ rewardsPopup = false, loginPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, setUser } = AppStore((state) => state);
  const { page, setPage, accountStep, setAccountStep } = AuthPageStore(
    (state) => state
  );

  const [formData, setFormData] = useState({
    email: '',
    contact: '',
    first_name: user?.fullName?.split(' : ')?.[0] || '',
    last_name: user?.fullName?.split(' : ')?.[1] || ''
  });

  useEffect(() => {
    const handleVerification = async (token) => {
      try {
        let response = await Client.post(
          `/auth/confirm-account?token=${token}`
        );
        response = response.data?.user;
        setUser(response);
        //   reset();
        Toasts.success('Account created successfully');
      } catch (error) {
        Toasts.error(error?.response?.data?.message);
      }
    };

    if (searchParams.get('token')) {
      handleVerification(searchParams.get('token'));
      searchParams.delete('token');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, setUser]);

  useEffect(() => {
    if (user) {
      setPage('account');
    }
  }, [user]);

  const AccountStepsController = () => {
    return (
      <>
        <RenderIf isTrue={page === 'createAccount'}>
          <div>
            <h5 className={`t_up`}>Join ZEWST Rewards, Win $500!</h5>
            <div className={`h6 fsemi`}>
              Be entered in a drawing to win on 3/1
            </div>
            <div className="mt_16"></div>
            <p>
              Earn rewards points to get free items, birthday gifts, discounts,
              updates on special events and more.
            </p>
            <div className={`mt_25`}></div>
            <RenderIf isTrue={accountStep === 0}>
              <AccountStepOne
                accountStep={accountStep}
                setAccountStep={setAccountStep}
                formData={formData}
                setFormData={setFormData}
              />
            </RenderIf>

            <RenderIf isTrue={accountStep === 1}>
              <AccountStepTwo
                rewardsPopup={rewardsPopup}
                accountStep={accountStep}
                setAccountStep={setAccountStep}
                formData={formData}
                setFormData={setFormData}
              />
            </RenderIf>

            <RenderIf isTrue={accountStep === 2}>
              <AccountStepThree
                rewardsPopup={rewardsPopup}
                accountStep={accountStep}
                setAccountStep={setAccountStep}
                formData={formData}
                setFormData={setFormData}
              />
            </RenderIf>

            <RenderIf isTrue={accountStep === 3}>
              <AccountStepFour
                accountStep={accountStep}
                setAccountStep={setAccountStep}
                formData={formData}
                setFormData={setFormData}
              />
            </RenderIf>
          </div>
        </RenderIf>
        <RenderIf isTrue={page === 'login'}>
          <div>
            <h5 className={`t_up`}>Sign in</h5>
            {/* <div className={`h6 fsemi`}>
              Be entered in a drawing to win on 3/1
            </div>
            <div className="mt_16"></div> */}
            <p>Please enter your log in email address below.</p>
            <div className={`mt_25`}></div>
            <RenderIf isTrue={accountStep === 0}>
              <AccountStepOne
                accountStep={accountStep}
                setAccountStep={setAccountStep}
                formData={formData}
                setFormData={setFormData}
              />
            </RenderIf>

            <RenderIf isTrue={accountStep === 1}>
              <AccountStepFour
                accountStep={accountStep}
                setAccountStep={setAccountStep}
                formData={formData}
                setFormData={setFormData}
              />
            </RenderIf>
          </div>
        </RenderIf>
      </>
    );
  };

  return (
    <>
      <RenderIf isTrue={rewardsPopup}>
        <article className={styles.article}>
          <div className={styles.banner_1}>
            <picture>
              <source srcSet={Images?.authPage} />
              <img src={Images?.authPage} alt="Auth page banner" />
            </picture>
          </div>
          <div className={styles.box_1}>
            <div className={styles.box_steps}>
              <RenderIf isTrue={page === 'createAccount'}>
                <ProgressSteps
                  activeStep={accountStep}
                  steps={[
                    { label: `Step 1`, badge: `33%` },
                    { label: `Step 2`, badge: `66%` },
                    { label: `Step 3`, badge: `99%` }
                  ]}
                />
              </RenderIf>
              <RenderIf isTrue={page === 'login'}>
                <ProgressSteps
                  activeStep={accountStep}
                  steps={[
                    { label: `Step 1`, badge: `50%` },
                    { label: `Step 2`, badge: `100%` }
                  ]}
                />
              </RenderIf>
            </div>
            <div className={`mt_25`}></div>
            <AccountStepsController />
          </div>
        </article>
      </RenderIf>
      <RenderIf isTrue={!rewardsPopup}>
        <header
          className={styles.header}
          style={{ backgroundImage: `url(${Images?.authPage})` }}
        >
          <div>
            <div className={styles.header_box_steps}>
              <RenderIf isTrue={page === 'createAccount'}>
                <ProgressSteps
                  activeStep={accountStep}
                  steps={[
                    { label: `Step 1`, badge: `33%` },
                    { label: `Step 2`, badge: `66%` },
                    { label: `Step 3`, badge: `99%` }
                  ]}
                />
              </RenderIf>
              <RenderIf isTrue={page === 'login'}>
                <ProgressSteps
                  activeStep={accountStep}
                  steps={[
                    { label: `Step 1`, badge: `50%` },
                    { label: `Step 2`, badge: `100%` }
                  ]}
                />
              </RenderIf>
            </div>
          </div>
        </header>
        <div className={`box`}>
          <div className="mt_40"></div>
          <div className={`flex item_hcenter`}>
            <RenderIf isTrue={page !== 'account'}>
              <article className={styles.article}>
                <AccountStepsController />
              </article>
            </RenderIf>
          </div>
          <RenderIf isTrue={page === 'account'}>
            <ProfilePage user={user} setUser={setUser} />
          </RenderIf>
          <div className="mt_40"></div>
        </div>
      </RenderIf>
    </>
  );
};

const AccountStepOne = ({
  accountStep,
  setAccountStep,
  formData,
  setFormData
}) => {
  const { page, setPage } = AuthPageStore((state) => state);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    }
  });

  const handleStep = async (data) => {
    try {
      if (page === 'login') {
        await Client.post('/auth/sendOtp', {
          email: data?.email
        });
      }

      setFormData({
        ...formData,
        email: data?.email
      });
      setAccountStep(accountStep + 1);
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleStep)}>
        <TextField
          placeholder="Email Address"
          name="email"
          required
          error={errors?.email?.type === 'required' ? 'Email is required' : ''}
          register={register}
          validation={{
            required: true
          }}
        />
        <div className={`mt_30`}></div>
        <PrimaryButton type={`submit`} buttonFull>
          Next
        </PrimaryButton>
        <div className={`mt_15`}></div>
        <RenderIf isTrue={page === 'createAccount'}>
          <p className={styles.para_agree1}>
            By signing up, you agree to receive email and SMS marketing
            communications from us and our technology partner zewst.com and
            consent to our Platform Terms and Privacy Policy .
          </p>
          <div className={`mt_25`}></div>
        </RenderIf>
        <p className={`t_center`}>
          {page === 'createAccount'
            ? ' Already have an account? '
            : " Don't have an account? "}

          <button
            type={`button`}
            className={`alink t_underline`}
            onClick={() => {
              if (page === 'createAccount') {
                setPage('login');
              } else {
                setPage('createAccount');
              }
            }}
          >
            {page === 'createAccount' ? ' Log in' : ' Create an account'}
          </button>
        </p>
      </form>
    </>
  );
};

const AccountStepTwo = ({
  accountStep,
  setAccountStep,
  formData,
  setFormData
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  });

  const handleStep = async (data) => {
    setFormData({
      ...formData,
      first_name: data?.firstName,
      last_name: data?.lastName,
    });
    setAccountStep(accountStep + 1);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleStep)}>
        <TextField
          placeholder="First Name"
          name="firstName"
          required
          error={
            errors?.fullName?.type === 'required'
              ? 'First Name is required'
              : ''
          }
          register={register}
          validation={{
            required: true
          }}
        />
        <TextField
          placeholder="Last Name"
          name="lastName"
          required
          error={
            errors?.fullName?.type === 'required' ? 'Last Name is required' : ''
          }
          register={register}
          validation={{
            required: true
          }}
        />
        <div className={`mt_40`}></div>
        <PrimaryButton type={`submit`} buttonFull>
          Next
        </PrimaryButton>
      </form>
    </>
  );
};

const AccountStepThree = ({
  accountStep,
  setAccountStep,
  formData,
  setFormData
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      contactNumber: ''
    }
  });

  const [submitting, setSubmitting] = useState(false);

  const handleAccountSubmit = async (data) => {
    try {
      setSubmitting(true);
      await Client.post('/auth/registerViaOtp', {
        email: formData?.email,
        contact: {
          contactNumber: data?.contactNumber,
          primary: true
        },
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        // fullName: formData?.fullName
      });

      setFormData({
        ...formData,
        contact: data?.contactNumber
      });

      setAccountStep(accountStep + 1);
      setSubmitting(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleAccountSubmit)}>
        <Controller
          name="contactNumber"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <PhoneField
                label="Phone Number"
                placeholder={"Phone Number"}
                field={field}
                error={
                  errors?.contactNumber?.type === 'required'
                    ? 'Phone Number is required'
                    : ''
                }
                defaultCountry={'US'}
              />
            );
          }}
        />
        <div className={`mt_40`}></div>
        <PrimaryButton type={`submit`} disabled={submitting} buttonFull>
          Next
        </PrimaryButton>
      </form>
    </>
  );
};

const AccountStepFour = ({ setAccountStep, formData }) => {
  const navigate = useNavigate();
  const { setUser } = AppStore((state) => state);
  const { page, setPage } = AuthPageStore((state) => state);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      otp: ''
    }
  });

  const [submitting, setSubmitting] = useState(false);

  const handleOtpSubmit = async (data) => {
    try {
      setSubmitting(true);
      let response;

      if (page === 'login') {
        response = (
          await Client.post('/auth/loginViaOtp', {
            email: formData?.email,
            otp: data?.otp ? parseInt(data?.otp) : null
          })
        )?.data;
        Toasts.success('You are loggedIn successfully');
      } else {
        response = (
          await Client.post('/auth/verifyOtp', {
            email: formData?.email,
            otp: data?.otp ? parseInt(data?.otp) : null
          })
        )?.data;
        Toasts.success('Account created successfully');
      }
      setUser(response?.data);
      setPage('account');
      setSubmitting(false);
      setAccountStep(0);
      // window.location.reload();
    } catch (error) {
      setSubmitting(false);
      Toasts.error(error?.response?.data?.message);
    }
  };

  const handleResendOtp = async () => {
    try {
      setSubmitting(true);
      await Client.post('/auth/sendOtp', {
        email: formData?.email
      });
      setSubmitting(false);
      //setResendOtpSeconds(60);
      Toasts.success('OTP sent successfully');
    } catch (error) {
      setSubmitting(false);
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleOtpSubmit)}>
        <TextField
          placeholder="Verification Pin"
          name="otp"
          required
          error={
            errors?.otp?.type === 'required'
              ? 'OTP is required'
              : errors?.otp?.type === 'minLength'
              ? 'OTP must be 6 digits'
              : errors?.otp?.type === 'maxLength'
              ? 'OTP must be 6 digits'
              : errors?.otp?.type === 'pattern'
              ? 'OTP must be 6 digits'
              : ''
          }
          register={register}
          validation={{
            required: true,
            minLength: 6,
            maxLength: 6,
            pattern: /^[0-9]*$/
          }}
        />
        <div className={`mt_40`}></div>
        <p>
          Didn't receive a code?{' '}
          <span
            className={`fsemi`}
            onClick={() => {
              if (!submitting) {
                handleResendOtp();
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            {/* {resendOtpSeconds > 0
              ? 'Resend in ' + resendOtpSeconds + 's'
              : 'Resend Code'} */}
            Resend Code
          </span>
        </p>
        <div className={`mt_40`}></div>
        <PrimaryButton type={`submit`} disabled={submitting} buttonFull>
          Verify
        </PrimaryButton>
      </form>
    </>
  );
};

import { Images } from 'constant';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/buttons/primary';
import { TertiaryButton } from 'components/buttons/tertiary';
import styles from './index.module.css';
import { OrderStore } from 'store';
import { RestaurantStore } from 'store';

const data = [
  {
    title: 'Vegetarian Italian Chopped Salad',
    dis: 'This make-ahead friendly salad is packed with crunchy veggies and tossed in a garlicky oregano    ',
    img: Images.image2
  },
  {
    title: 'Perfect Italian Pizza Hut',
    dis: 'This make-ahead friendly salad is packed with crunchy veggies and tossed in a garlicky oregano    ',
    img: Images.image3
  },
  {
    title: 'Vegetarian Italian Chopped Salad',
    dis: 'This make-ahead friendly salad is packed with crunchy veggies and tossed in a garlicky oregano    ',
    img: Images.image2
  },
  {
    title: 'Perfect Italian Pizza Hut',
    dis: 'This make-ahead friendly salad is packed with crunchy veggies and tossed in a garlicky oregano    ',
    img: Images.image3
  }
];

function FoodItems() {
  return (
    <div className={styles.article}>
      <div className="mt_20"></div>

      <div className={styles.head}>
        <h2>
          <b className="t_up">More sustainable food items</b>
          <div className={styles.line}></div>
        </h2>
      </div>
      <div className="mt_40"></div>
      <div className={styles.fooditems}>
        {data.map((item, index) => (
          <div key={`fooditem${index}`} className={styles.box}>
            <MenuItem item={item} />
          </div>
        ))}
        <div className="mt_40"></div>
      </div>
      <div className="mt_40"></div>
    </div>
  );
}

const MenuItem = ({ item }) => {
  const navigate = useNavigate();

  const { setDeliveryType } = OrderStore((state) => state);
  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  const handleNavigation = () => {
    if (restaurantData?.brand?.brandType === 'CLOUD') {
      navigate('/cloud-brand-branches');
    } else {
      navigate('/brand-orders', {
        state: {
          branches: restaurantData?.brand?.branches?.map((b) => ({
            ...b,
            markup: 0,
            parentBranch: b?._id,
            branchTax: b?.tax
          }))
        }
      });
    }
  };

  return (
    <>
      <div className="mt_40"></div>

      <img src={item.img} alt={``} />
      <div className="mt_10"></div>
      <h2>
        <strong className="t_up">{item.title}</strong>
      </h2>
      <div className="mt_15"></div>
      <p>
        <span>{item.dis}</span>
      </p>
      <div className="mt_20"></div>
      <div className={styles.btn_div}>
        <PrimaryButton
          handleClick={() => {
            setDeliveryType('pickup');
            // navigate('/order-type');
            handleNavigation();
          }}
        >
          Order now
        </PrimaryButton>
        <TertiaryButton>View more</TertiaryButton>
      </div>
    </>
  );
};
export default FoodItems;

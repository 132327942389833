import { RenderIf } from 'helpers';
import styles from './index.module.css';

export const PrimaryRadioButton = ({
  id,
  name,
  value,
  label,
  defaultChecked = false
}) => {
  return (
    <>
      <div className={styles.grid}>
        <div className={styles.radio}>
          <input
            type={`radio`}
            id={id}
            name={name}
            value={value}
            defaultChecked={defaultChecked}
          />
          <span></span>
        </div>
        <RenderIf isTrue={label}>
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        </RenderIf>
      </div>
    </>
  );
};

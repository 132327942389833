import { Client, REACT_APP_RESTAURANT_ID } from 'db';
import { useQuery } from 'react-query';

export const useFetchCustomerOrders = (keyName, customerId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, customerId],
    () => getData(customerId),
    {
      enabled: !!customerId,
      refetchInterval: 60000
    }
  );

  return {
    orderStatus: status,
    orderData: data,
    orderError: error,
    orderFetching: isFetching,
    orderLoading: isLoading,
    orderRefetch: refetch
  };
};

const getData = async (customerId) => {
  let result = await Client.get(
    `/orders/brand/${REACT_APP_RESTAURANT_ID}/${customerId}`
  );
  result = result.data?.data;

  return result;
};

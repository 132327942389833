import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import axios from 'axios';
import { RenderIf } from 'helpers';
import { RestaurantStore } from 'store';
import { TextFieldWithoutHookForm } from 'components/input';
import { PrimaryButton } from 'components/buttons/primary';
import { TertiaryButton } from 'components/buttons/tertiary';
import styles from './index.module.css';

export const CloudBrandsListingPage = () => {
  const navigate = useNavigate();
  const { items } = useCart();

  const {
    restaurantData: { brand }
  } = RestaurantStore((state) => state);

  const [orderType, setOrderType] = useState('delivery');
  const [dataToRender, setDataToRender] = useState([]);
  const [fetchingPostalCode, setFetchingPostalCode] = useState(false);
  const [postalCode, setPostalCode] = useState('');

  useMemo(() => {
    setDataToRender(
      brand?.branches?.filter((branch) => branch?.cloudBrands?.length > 0)
    );
  }, [brand]);

  useEffect(() => {
    setOrderType(`pickup`);
  }, []);

  useEffect(() => {
    if (dataToRender?.length === 1) {
      navigate(`/cloud-brand-branch-menu`, {
        state: {
          cloudBrandsInfo: dataToRender?.[0]?.cloudBrands,
          orderType,
          parentBranch: dataToRender?.[0]?._id,
          branchTax: dataToRender?.[0]?.tax
        }
      });
    }
  }, [dataToRender, navigate, orderType]);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      const filteredData = brand?.branches?.filter((branch) => {
        return branch?.address?.zipCode
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setDataToRender(filteredData);
    } else {
      setDataToRender(brand?.branches);
    }
  };

  const searchViaIpAddress = () => {
    setFetchingPostalCode(true);
    let apiKey = 'be0f755b93290b4c100445d77533d291763a417c75524e95e07819ad';
    axios
      .get('https://api.ipdata.co?api-key=' + apiKey)
      .then((response) => {
        const postalCode = response.data.postal;
        const filteredData = brand?.branches?.filter((branch) => {
          return branch?.address?.zipCode.toLowerCase().includes(postalCode);
        });
        setDataToRender(filteredData);
        setPostalCode(postalCode);
        setFetchingPostalCode(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <article className={styles.article}>
      <div className={`box3`}>
        <div className={`mt_40`}></div>
        <div className={`pt_40`}></div>
        <RenderIf isTrue={items.length > 0}>
          <div className={`t_center ${styles.box1}`}>
            <h2 className={`t_up`}>You already have items in your cart</h2>
            <div className={`mt_40`}></div>
            <p className={`h5 ${styles.c_h5}`}>
              You already have items in your cart from a branch. Please checkout
              first or clear your cart
            </p>
          </div>
        </RenderIf>
        <RenderIf isTrue={items.length === 0}>
          <div className={`align_center`}>
            <h2 className={`t_up`}>Find a store to get started</h2>
            <div className={`mt_20`}></div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className={`mt_40`}></div>
            {/* <div className={styles.buttons}>
              <button
                className={
                  orderType === 'delivery' ? `button2 active` : `button2`
                }
                onClick={() => setOrderType('delivery')}
              >
                Delivery
              </button>
              <button
                className={
                  orderType === 'pickup' ? `button2 active` : `button2`
                }
                onClick={() => setOrderType('pickup')}
              >
                Pickup
              </button>
              <button
                className={
                  orderType === 'dine-in' ? `button2 active` : `button2`
                }
                onClick={() => setOrderType('dine-in')}
              >
                Dine In
              </button>
            </div> */}
            <div className={`mt_15`}></div>
            <div className={styles.search_box}>
              <TextFieldWithoutHookForm
                placeholder="Enter a zipcode to search branches near you"
                name="search"
                onChange={handleSearch}
              />
              <PrimaryButton
                handleClick={searchViaIpAddress}
                disabled={fetchingPostalCode}
              >
                <strong>
                  {fetchingPostalCode ? 'Fetching' : 'Fetch near you'}
                </strong>
              </PrimaryButton>

              <RenderIf isTrue={postalCode}>
                <TertiaryButton
                  handleClick={() => {
                    setDataToRender(brand?.branches);
                    setPostalCode('');
                  }}
                  textColor={`var(--foreground-color)`}
                >
                  <strong>Clear Search</strong>
                </TertiaryButton>
              </RenderIf>
            </div>
            <RenderIf isTrue={dataToRender?.length === 0}>
              <div className={`mt_20`}>
                <div className={`no_data1`}>
                  <span className={`fsemi`}>No Branches Found</span>
                </div>
              </div>
            </RenderIf>
          </div>
          <div className={`mt_40`}></div>
          <RenderIf isTrue={dataToRender?.length}>
            <div className={`ofx_auto`}>
              <div
                className={styles.xwrap}
                style={{ width: `calc(273px * ${dataToRender?.length})` }}
              >
                {dataToRender?.map((branch, index) => (
                  <button
                    key={index}
                    type={`button`}
                    className={styles.button_item}
                    onClick={() => {
                      navigate(`/cloud-brand-branch-menu`, {
                        state: {
                          cloudBrandsInfo: branch?.cloudBrands,
                          orderType,
                          parentBranch: branch?._id,
                          branchTax: branch?.tax
                        }
                      });
                    }}
                  >
                    <picture>
                      <source srcSet={branch?.branchImage} />
                      <img
                        src={
                          branch?.branchImage ||
                          'https://w1.pngwing.com/pngs/610/214/png-transparent-cafe-coffee-bakery-restaurant-drawing-cafeteria-food-toy.png'
                        }
                        alt="branch"
                      />
                    </picture>
                    <div className={styles.b_content}>
                      <div className={`h5`}>
                        <b>{branch?.branchName}</b>
                      </div>
                      <div className={`h6`}>
                        {branch?.address?.addressLine1
                          ? branch?.address?.addressLine1?.line1 +
                            ' ' +
                            branch?.address?.addressLine1?.line2
                          : '...'}
                      </div>
                      <div className={`h6`} style={{ color: `var(--primary)` }}>
                        <strong>{branch?.address?.city}</strong>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </RenderIf>
        </RenderIf>
        <div className={`mt_40`}></div>
        <div className={`pt_40`}></div>
      </div>
    </article>
  );
};

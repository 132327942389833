import { Client, REACT_APP_RESTAURANT_ID } from 'db';

import { useQuery } from 'react-query';

export const useFetchRestaurantHook = (keyName = 'restaurant', enabled) => {

  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, enabled],
    () => getRestData(),
    {
      enabled: enabled ? false : true
    }
  );

  return {
    restatus: status,
    resData: data,
    resError: error,
    resFetching: isFetching,
    resLoading: isLoading,
    resRefetch: refetch
  };
};

const getRestData = async () => {
  let result = await Client.get(`/brands/${REACT_APP_RESTAURANT_ID}`);
  result = result.data?.data;

  return result;
};

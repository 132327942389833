import { useState } from 'react';
import { RenderIf } from 'helpers';
import { Icon } from 'assets/icons';
import styles from './index.module.css';

export const AccordionEle = ({
  title = '',
  selectedTypeLink = '',
  children,
  defaultActive = false,
  setSelectedTypeLink = () => {}
}) => {
  const [isActive, setIsActive] = useState(defaultActive);
  return (
    <>
      <article className={styles.acc_ele}>
        <button
          type="button"
          className={styles.acc_button}
          onClick={() => {
            setSelectedTypeLink(title);
            setIsActive(!isActive);
          }}
        >
          <h5 className={`t_up`}>{title}</h5>
          <div className="flex item_vcenter">
            <span className={styles.icon_acc}>
              <Icon name={isActive ? `arrow_up` : `arrow_down`} />
            </span>
          </div>
        </button>
        <RenderIf isTrue={isActive}>
          <div className={styles.children_acc}>{children}</div>
        </RenderIf>
      </article>
    </>
  );
};

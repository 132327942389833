import React from 'react';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { HomeProduct } from './product';
import { HomeCarousel } from './carousel';
import { HomeFeatured } from './featured';
import { HomeFigure } from './figure';
import { RestaurantStore } from 'store';

export const HomePage = () => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  const renderOrder = restaurantData?.theme?.landing?.renderOrder;
  // console.log(
  //   '🚀 ~ file: index.jsx:16 ~ HomePage ~ renderOrder:',
  //   renderOrder,
  //   resData
  // );
  // const { restaurantData } = RestaurantStore((state) => state);

  // const { previewQueryData } = useFetchPreviewQueryData();
  // const [themeDataToUse, setThemeDataToUse] = useState(null);

  // useEffect(() => {
  //   if (restaurantData || previewQueryData) {
  //     setThemeDataToUse(restaurantData || previewQueryData);
  //   }
  // }, [restaurantData, previewQueryData]);

  return (
    <>
      {renderOrder?.map((ord, index) => (
        <React.Fragment key={index}>
          {restaurantData?.theme?.landing?.productSections
            ?.filter((item) => item?.title === ord?.title)
            ?.map((sec, i) => (
              <HomeProduct title={sec?.title} key={i} />
            ))}
          {restaurantData?.theme?.landing?.carouselSections
            ?.filter((item) => item?.title === ord?.title)
            ?.map((sec, i) => (
              <HomeCarousel title={sec?.title} key={i} />
            ))}
          {restaurantData?.theme?.landing?.featuredSections
            ?.filter((item) => item?.title === ord?.title)
            ?.map((sec, i) => (
              <HomeFeatured title={sec?.title} key={i} />
            ))}
          {restaurantData?.theme?.landing?.figureSections
            ?.filter((item) => item?.title === ord?.title)
            ?.map((sec, i) => (
              <HomeFigure title={sec?.title} key={i} />
            ))}
        </React.Fragment>
      ))}
    </>
  );
};

import { AXIOS_INSTANCE_ZEWARDS } from 'db';
import { RenderIf } from 'helpers';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { useEffect, useState } from 'react';
import { AppStore } from 'store';
import styles from './index.module.css';
import { Icon } from 'assets/icons';
import { ThemeTabs } from 'components/tabs';
import { Aqcuired } from './aqcuired';
import { Redeemed } from './redeemed';
import { RestaurantStore } from 'store';

export const ZewardsWallet = () => {
  const { user } = AppStore((state) => state);
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );
  const [loading, setLoading] = useState(false);

  const [userPointDetails, setUserPointDetails] = useState(null);
  const [userRedeemLogs, setUserRedeemLogs] = useState(null);

  const getUserRedeemLogs = async () => {
    try {
      setLoading(true);
      const response = (
        await AXIOS_INSTANCE_ZEWARDS.post(`/customer/redeemLogs`, {
          customerId: user?._id,
          loyaltyId: restaurantData?.theme?.loyaltyId
        })
      )?.data;
      setUserRedeemLogs(response?.logs);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserPoints = async () => {
    try {
      const response = (
        await AXIOS_INSTANCE_ZEWARDS.post(`/customer/getPointsDetails`, {
          customerId: user?._id,
          loyaltyId: restaurantData?.theme?.loyaltyId
        })
      )?.data;
      setUserPointDetails(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?._id) {
      setLoading(true);
      getUserPoints();
      getUserRedeemLogs();
    }
  }, []);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.contain}>
          <div className={styles.headRow}>
            <h4>
              <b>Your Zewards Wallet</b>
            </h4>
            <div className={styles.totalRow}>
              <h4 className={`fsemi`}>Total</h4>
              <div className={styles.countRow}>
                <Icon name={`zeward_coin`} />
                <div>
                  <h3 color="primary">
                    <b>
                      <RenderIf isTrue={userPointDetails}>
                        {userPointDetails?.totalCustomerPoints?.points}
                      </RenderIf>
                      <RenderIf isTrue={!userPointDetails}>0</RenderIf>
                    </b>
                  </h3>
                  <div color="primary" className={`fsemi ${styles.small}`}>
                    Zewards
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={styles.contain}>
        <RenderIf isTrue={loading}>
          <div>Loading...</div>
        </RenderIf>
        <RenderIf isTrue={!loading && userPointDetails}>
          <ThemeTabs
            tabs={[{ label: `Aqcuired all` }, { label: `Redeemed logs` }]}
            panels={[
              { content: <Aqcuired userPointDetails={userPointDetails} /> },
              { content: <Redeemed userRedeemLogs={userRedeemLogs} /> }
            ]}
          />
        </RenderIf>
      </div>
    </>
  );
};

// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { useFetchWindowDimensions } from 'hooks/windowdimensions/useFetchWindowDimensions';
import { RenderIf } from 'helpers';
import HorizontalSlider, { SliderItem } from 'components/horizontalslider';
import styles from './index.module.css';
import { RestaurantStore } from 'store';

export const HomeProduct = ({ title }) => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);
  
  // const { content, style } = restaurantData?.theme?.landing?.productSections?.filter(
  //   (sec) => sec?.title === title
  // )[0];

  const content = restaurantData?.theme?.landing?.productSections?.filter(
    (sec) => sec?.title === title
  )[0]?.content;

  const style = restaurantData?.theme?.landing?.productSections?.filter(
    (sec) => sec?.title === title
  )[0]?.style;

  const { width } = useFetchWindowDimensions();

  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage:
            style?.backgroundImage !== ``
              ? `url(${style?.backgroundImage})`
              : ``,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``,
            paddingRight: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
          >
            <div
              className={!content?.contentInContainer ? `contBx` : ``}
              style={{
                paddingLeft: !content?.contentInContainer
                  ? `var(--contBxHPadding)`
                  : ``,
                paddingRight: !content?.contentInContainer
                  ? `var(--contBxHPadding)`
                  : ``
              }}
            >
              <RenderIf isTrue={content?.title}>
                <h1 style={{ whiteSpace: `pre-line` }}>{content?.title}</h1>
              </RenderIf>
              <RenderIf isTrue={content?.description}>
                <div style={{ marginTop: 10 }}></div>
                <p style={{ whiteSpace: `pre-line` }}>{content?.description}</p>
              </RenderIf>
            </div>
            <HorizontalSlider
              spaceBetween={16}
              showItems={
                width < 560
                  ? 1
                  : width > 559 && width < 768
                  ? 2
                  : width > 767 && width < 1060
                  ? 3
                  : 4
              }
              itemWidth={
                width < 560
                  ? 324
                  : width > 559 && width < 768
                  ? width / 2.5
                  : width > 767 && width < 1060
                  ? width / 3.5
                  : width / 4.4
              }
            >
              {content?.products?.map((product, index) => (
                <SliderItem key={index}>
                  <picture>
                    <source srcSet={product?.media} />
                    <img src={product?.media} alt={product?.title} />
                  </picture>
                  <h5 style={{ color: style?.foregroundColor }}>
                    {product?.title}
                  </h5>
                  <div className={styles.price}>
                    ${` `}
                    {product?.price}
                  </div>
                  <p>{product?.description}</p>
                </SliderItem>
              ))}
            </HorizontalSlider>
          </div>
        </div>
      </article>
    </>
  );
};

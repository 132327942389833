import { HomeFeatured } from './featured';
import TopMenu from './topmenu';

export const MenuPage = () => {
  return (
    <>
      <TopMenu />
      <HomeFeatured />
    </>
  );
};

import styles from './index.module.css';

export const ProgressSteps = ({ activeStep = 0, steps }) => {
  return (
    <>
      <div className={styles.steps_1}>
        <div className={styles.steps_hbar1}></div>
        <div className={styles.steps_progress}>
          {steps?.map((step, idx) => (
            <div
              key={idx}
              className={
                activeStep === idx
                  ? `${styles.steps_indicator} ${styles.active}`
                  : styles.steps_indicator
              }
            >
              <label>{step?.label}</label>
              <div className={styles.indicator_circle}></div>
              <div className={styles.indicator_badge}>
                <div className={styles.indicator_uptriangle}></div>
                {step?.badge}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

import { useNavigate } from 'react-router-dom';
// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { RenderIf } from 'helpers';
import Carousel, { CarouselItem } from 'components/carousel';
import styles from './index.module.css';
import { PrimaryButton } from 'components/buttons/primary';
import { RestaurantStore } from 'store';
import { OrderStore } from 'store';

export const HomeFeatured = ({ title }) => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  const navigate = useNavigate();
  // const { primary } = restaurantData?.theme?.global?.style?.button;
  // const { content, style } = restaurantData?.theme?.landing?.featuredSections?.filter(
  //   (sec) => sec?.title === title
  // )[0];

  const primary = restaurantData?.theme?.global?.style?.button?.primary;

  const content = restaurantData?.theme?.landing?.featuredSections?.filter(
    (sec) => sec?.title === title
  )[0]?.content;

  const style = restaurantData?.theme?.landing?.featuredSections?.filter(
    (sec) => sec?.title === title
  )[0]?.style;

  const { setDeliveryType } = OrderStore((state) => state);

  const handleNavigation = () => {
    if (restaurantData?.brand?.brandType === 'CLOUD') {
      navigate('/cloud-brand-branches');
    } else {
      navigate('/brand-orders', {
        state: {
          branches: restaurantData?.brand?.branches?.map((b) => ({
            ...b,
            markup: 0,
            parentBranch: b?._id,
            branchTax: b?.tax
          }))
        }
      });
    }
  };

  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage:
            style?.backgroundImage !== ``
              ? `url(${style?.backgroundImage})`
              : ``,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``,
            paddingRight: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout2}
            style={{ color: style?.foregroundColor }}
          >
            <RenderIf isTrue={content?.layout === 1}>
              <RenderIf isTrue={content?.subTitle}>
                <div className={styles.subTitleOuter}>
                  <div className={styles.subTitleInner}>
                    <h2
                      className={styles.subTitle}
                      style={{ whiteSpace: `pre-line` }}
                    >
                      {content?.subTitle}
                    </h2>
                    <div
                      className={styles.hr}
                      style={{
                        background: style?.foregroundColor
                          ? style?.foregroundColor
                          : `rgba(0, 0, 0, 1)`,
                        opacity: 0.24
                      }}
                    ></div>
                  </div>
                </div>
              </RenderIf>
              <div className={styles.layout1Inner}>
                <div>
                  <Carousel
                    showIndicators={false}
                    pageLHorizontalCenter={true}
                    pageLRight={`0`}
                    pageLBottom={`0`}
                    pageLLeft={`0`}
                    pageLColor={style?.foregroundColor}
                  >
                    {content?.slides?.map((item, index) => (
                      <CarouselItem key={index}>
                        <div className={styles.slideOuter}>
                          <RenderIf isTrue={item?.category}>
                            <div className={styles.categoryOuter}>
                              <div className={styles.category}>
                                <div>{item?.category}</div>
                                <div
                                  style={{
                                    borderBottom: `${primary?.backgroundColor} 3px dotted`
                                  }}
                                ></div>
                              </div>
                            </div>
                          </RenderIf>
                          <div className={styles.slide}>
                            <div>
                              <RenderIf isTrue={item?.title}>
                                <h1 style={{ whiteSpace: `pre-line` }}>
                                  {item?.title}
                                </h1>
                              </RenderIf>
                              <RenderIf isTrue={item?.description}>
                                <div style={{ whiteSpace: `pre-line` }}>
                                  {item?.description}
                                </div>
                              </RenderIf>
                              <div>
                                <PrimaryButton
                                  handleClick={() => {
                                    setDeliveryType('pickup');
                                    // navigate('/order-type');
                                    handleNavigation();
                                  }}
                                >
                                  ORDER NOW
                                </PrimaryButton>
                              </div>
                            </div>
                            <div>
                              <RenderIf isTrue={item?.media}>
                                <picture>
                                  <source srcSet={item?.media} />
                                  <img src={item?.media} alt={item?.title} />
                                </picture>
                              </RenderIf>
                            </div>
                          </div>
                        </div>
                      </CarouselItem>
                    ))}
                  </Carousel>
                </div>
                <div>
                  <RenderIf isTrue={content?.title}>
                    <h1 style={{ whiteSpace: `pre-line` }}>{content?.title}</h1>
                  </RenderIf>
                  <RenderIf isTrue={content?.description}>
                    <p style={{ whiteSpace: `pre-line` }}>
                      {content?.description}
                    </p>
                  </RenderIf>
                </div>
              </div>
            </RenderIf>
            <RenderIf isTrue={content?.layout === 2}>
              <div>
                <RenderIf isTrue={content?.title}>
                  <h1 style={{ whiteSpace: `pre-line` }}>{content?.title}</h1>
                </RenderIf>
              </div>
              <div>
                <RenderIf isTrue={content?.subTitle}>
                  <div className={styles.subTitleOuter}>
                    <div className={styles.subTitleInner}>
                      <h2
                        className={styles.subTitle}
                        style={{ whiteSpace: `pre-line` }}
                      >
                        {content?.subTitle}
                      </h2>
                    </div>
                  </div>
                </RenderIf>
                <RenderIf isTrue={content?.description}>
                  <div style={{ marginTop: 20 }}></div>
                  <p style={{ whiteSpace: `pre-line` }}>
                    {content?.description}
                  </p>
                </RenderIf>
              </div>
              <div>
                <Carousel
                  showIndicators={false}
                  pageLHorizontalCenter={true}
                  pageLRight={`0`}
                  pageLBottom={`0`}
                  pageLLeft={`0`}
                  pageLColor={style?.foregroundColor}
                >
                  {content?.slides?.map((item, index) => (
                    <CarouselItem key={index}>
                      <div className={styles.slideOuter}>
                        <RenderIf isTrue={item?.category}>
                          <div className={styles.categoryOuter}>
                            <div className={styles.category}>
                              <div style={{ padding: `0 20px` }}>
                                {item?.category}
                              </div>
                              <div
                                style={{
                                  borderBottom: `${primary?.backgroundColor} 2px solid`
                                }}
                              ></div>
                            </div>
                          </div>
                        </RenderIf>
                        <div className={styles.slide}>
                          <div
                            className={styles.slideOverlay}
                            style={{
                              backgroundColor: primary?.backgroundColor,
                              opacity: 0.08
                            }}
                          ></div>
                          <div>
                            <RenderIf isTrue={item?.media}>
                              <picture>
                                <source srcSet={item?.media} />
                                <img src={item?.media} alt={item?.title} />
                              </picture>
                            </RenderIf>
                          </div>
                          <div>
                            <RenderIf isTrue={item?.title}>
                              <h1 style={{ whiteSpace: `pre-line` }}>
                                {item?.title}
                              </h1>
                            </RenderIf>
                            <RenderIf isTrue={item?.description}>
                              <div style={{ whiteSpace: `pre-line` }}>
                                {item?.description}
                              </div>
                            </RenderIf>
                            <div>
                              <PrimaryButton
                                handleClick={() => {
                                  setDeliveryType('pickup');
                                  // navigate('/order-type');
                                  handleNavigation();
                                }}
                              >
                                ORDER NOW
                              </PrimaryButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CarouselItem>
                  ))}
                </Carousel>
              </div>
            </RenderIf>
          </div>
        </div>
      </article>
    </>
  );
};

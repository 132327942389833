import { Images } from 'constant';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/buttons/primary';
import styles from './index.module.css';
import { OrderStore } from 'store';
import { RestaurantStore } from 'store';

function TopMenu() {
  const navigate = useNavigate();

  const { setDeliveryType } = OrderStore((state) => state);
  const { restaurantData, setRestaurantData } = RestaurantStore(
    (state) => state
  );

  const handleNavigation = () => {
    if (restaurantData?.brand?.brandType === 'CLOUD') {
      navigate('/cloud-brand-branches');
    } else {
      navigate('/brand-orders', {
        state: {
          branches: restaurantData?.brand?.branches?.map((b) => ({
            ...b,
            markup: 0,
            parentBranch: b?._id,
            branchTax: b?.tax
          }))
        }
      });
    }
  };

  return (
    <div className={styles.contaniner}>
      <div className={styles.box}>
        <h2>
          <b>Menu</b>
        </h2>
        <div className="mt_20"></div>
        <p>
          Chef-crafted, signature burgers. Prepared <b>fresh</b> with the{' '}
          <b>best ingredients</b> and made just for you!
        </p>
        <div className="mt_40"></div>
        <PrimaryButton
          handleClick={() => {
            setDeliveryType('pickup');
            // navigate('/order-type');
            handleNavigation();
          }}
          buttonReverse
        >
          Order now
        </PrimaryButton>
      </div>
      <div className={styles.box} style={{ backgroundColor: '#fff' }}>
        <img src={Images.menuBurger} alt={``} />
      </div>
    </div>
  );
}

export default TopMenu;

import { Toasts } from 'components/toasts';
import { Client } from 'db';

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export const getFaviconEl = () => {
  return document?.getElementById('favicon');
};

export const fetchDeliveryEnabled = async (branchId, setDeliveryEnabled) => {
  try {
    const response = (
      await Client.get(`/branches/getDetailsDelivery/${branchId}`)
    )?.data;
    setDeliveryEnabled(response?.delivery);
  } catch (error) {
    console.log(error);
    setDeliveryEnabled(false);
  }
};

export const fetchDeliveryPrice = async (
  customerAddress,
  total,
  branch,
  customerNumber,
  setDeliveryFee,
  items,
  setDeliveryEnabled
) => {
  // const deliveryEnabled = await fetchDeliveryEnabled(
  //   branch?._id,
  //   setDeliveryEnabled
  // );
  // if (!deliveryEnabled) return setDeliveryFee(0);
  if (!customerAddress || !total || !branch) return setDeliveryFee(0);
  try {
    const response = (
      await Client.post(`/orders/checkDDFee`, {
        customerAddress: customerAddress,
        total: total,
        branch: branch,
        customerNumber: customerNumber || '+12124567890',
        items: items || [
          {
            name: 'Chicken Tikka',
            quantity: 1
          }
        ]
      })
    )?.data;
    setDeliveryFee(response?.deliveryFee);
  } catch (error) {
    setDeliveryFee(0);
    console.log(error?.response?.data?.error || 'Error fetching delivery fee');

    if (
      error?.response?.data?.error ===
      'allowed distance between addresses exceeded'
    ) {
      return Toasts.info('Delivery address cant be more than 15 miles away');
    }

    if (
      error?.response?.data?.error ===
      'dropoff_address-->dropoff address not within service area'
    ) {
      return Toasts.info('Delivery address cant be more than 15 miles away');
    }
  }
};

// calculate 20 percent of total for tip
export const calculateTip = (total) => {
  return total * 0.2;
};

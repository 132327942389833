import { RenderIf } from 'helpers';
import { useFetchCustomerOrders } from 'hooks/orders/useFetchCustomerOrders';
import { AppStore } from 'store';
import { RestaurantStore } from 'store';
import styles from './index.module.css';
import { DELIVERY_BACKEND_URL } from 'db';

export const AccountOrders = () => {
  const { user } = AppStore((state) => state);
  const { orderData, orderLoading } = useFetchCustomerOrders(
    `orders/${user?._id}`,
    user?._id
  );
  const { restaurantData } = RestaurantStore((state) => state);

  const primary = restaurantData?.theme?.global?.style?.button?.primary;

  const trackOrder = async (orderId) => {
    try {
      const response = (
        await DELIVERY_BACKEND_URL.get(
          `/doordash/get-delivery-status/${orderId}`
        )
      )?.data;

      window.open(response?.result?.trackingUrl, '_blank');

      console.log('🚀 ~ trackOrder ~ response:', response);
    } catch (error) {
      console.log('🚀 ~ trackOrder ~ error:', error);
    }
  };

  return (
    <>
      <div className={`mt_40`}></div>
      <div className={styles.box1}>
        <RenderIf isTrue={orderLoading}>
          <div>Loading...</div>
        </RenderIf>
        <RenderIf isTrue={orderData}>
          <RenderIf isTrue={orderData?.length === 0}>
            <div className={`no_data1`}>No orders found</div>
          </RenderIf>
          <RenderIf isTrue={orderData?.length > 0}>
            <div className={styles.box2}>
              {orderData
                ?.sort(
                  (a, b) => new Date(b?.orderedOn) - new Date(a?.orderedOn)
                )
                ?.map((order, index) => (
                  <div key={index} className={`${styles.mainItem} mt_10`}>
                    <div className={styles.itemHeader}>
                      <div>
                        <p>
                          Order #{' '}
                          <span color={`accent1`}>
                            <b>{order?.orderCode}</b>
                          </span>
                        </p>
                        <div className={`mt_5`}></div>
                        <div className={styles.orderBrand}>
                          <div>Brand:</div>
                          <div>
                            <b>{order?.brand?.brandName}</b>
                          </div>
                          <RenderIf isTrue={order?.branch?.branchName}>
                            <div>-</div>
                            <div>Branch: {order?.branch?.branchName}</div>
                          </RenderIf>
                        </div>
                      </div>
                      <div>
                        <div className={`badge`}>
                          <span className={`fsemi`}>{order?.type}</span>
                        </div>
                      </div>
                      <div className={styles.alignRight}>
                        <div>
                          Date:{' '}
                          <strong>{order?.orderedOn?.split('T')?.[0]}</strong>
                        </div>
                        <div>
                          Payment: <strong>{order?.payment}</strong>
                        </div>
                      </div>
                    </div>
                    <div className={styles.itemBody}>
                      {order?.items?.map((it, i) => (
                        <div key={i} className={styles.menuItem}>
                          <div>
                            <picture>
                              <source srcSet={it?.itemId?.image} />
                              <img src={it?.itemId?.image} alt="" />
                            </picture>
                            <div>
                              <div className={`h6`}>{it?.itemId?.item}</div>
                              {/* <p className={`mt_5`}>
                                {it?.itemId?.description}
                              </p> */}
                            </div>
                          </div>
                          <div>
                            <p>Qty:</p>
                            <div>{it?.quantity}</div>
                          </div>
                          <div>
                            <p>Price:</p>
                            <div>
                              <strong>{it?.itemId?.price}</strong>
                            </div>
                          </div>
                          <div>
                            <p>Unit Price:</p>
                            <div>
                              <strong>{it?.unitPrice}</strong>
                            </div>
                          </div>
                          <div>
                            <p>
                              Subtotal: <strong>{order?.subTotal}</strong>
                            </p>
                            <p className={`mt_5`}>
                              Tax: <strong>{order?.tax}</strong>
                            </p>
                            <div className={`mt_5`}>
                              Total: <b>{order?.total}</b>
                            </div>
                            <RenderIf isTrue={order?.payment === 'PENDING'}>
                              <div>
                                <button
                                  style={{
                                    padding: '5px 30px',
                                    background: primary?.backgroundColor
                                  }}
                                  onClick={() => {
                                    window.location.href = `https://payments.zewst.com/?orderCode=${order?.orderCode}`;
                                  }}
                                >
                                  PAY NOW
                                </button>
                              </div>
                            </RenderIf>
                            <RenderIf isTrue={order?.type === 'DELIVERY'}>
                              <div>
                                <button
                                  style={{
                                    padding: '5px 30px',
                                    background: primary?.backgroundColor
                                  }}
                                  onClick={() => {
                                    trackOrder('12x4');
                                  }}
                                >
                                  Track Order
                                </button>
                              </div>
                            </RenderIf>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </RenderIf>
        </RenderIf>
      </div>
      <div className={`mt_40`}></div>
    </>
  );
};

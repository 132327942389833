import { useForm } from 'react-hook-form';
import { TextField } from 'components/input';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { useEffect } from 'react';

export const Search = ({
  placeholder = ``,
  searchData,
  setSearchData = () => {},
  setSearchValue = () => {}
}) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: {}
  } = useForm({
    defaultValues: {
      search: ''
    }
  });
  const handleSearch = (data) => {
    const searchItems = searchData?.categoryTypes?.map((categoryType) =>
      categoryType?.items?.filter((it) =>
        it?.item?.toLowerCase().includes(data?.search?.toLowerCase())
      )
    );
    if (searchData) {
      setSearchData(searchItems);
    }
    setSearchValue(data?.search?.toLowerCase());
  };

  useEffect(() => {
    setSearchValue(watch()?.search);
  }, [watch()?.search]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSearch)}>
        <div className={styles.box}>
          <TextField
            type={`text`}
            name={`search`}
            id={`search`}
            placeholder={placeholder}
            register={register}
          />
          <button type={`submit`} className={styles.searchButton}>
            <Icon name={`search`} />
          </button>
        </div>
      </form>
    </>
  );
};

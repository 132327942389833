// import { useFetchRestaurantHook } from 'hooks/restaurant/useFetchRestaurantHook';
import { RenderIf } from 'helpers';
import { Icon } from 'assets/icons';
import styles from './index.module.css';
import { RestaurantStore } from 'store';

export const Footer = () => {
  // const { resData } = useFetchRestaurantHook();

  const { restaurantData, setRestaurantData} = RestaurantStore((state) => state);
  
  const content = restaurantData?.theme?.footer?.content;
  const style = restaurantData?.theme?.footer?.style;
  // const { twitter, discord, instagram, facebook, snapchat, tiktok } =
  // restaurantData?.theme?.global?.content?.social;

  const twitter = restaurantData?.theme?.global?.content?.social?.twitter;
  const discord = restaurantData?.theme?.global?.content?.social?.discord;
  const instagram = restaurantData?.theme?.global?.content?.social?.instagram;
  const facebook = restaurantData?.theme?.global?.content?.social?.facebook;
  const snapchat = restaurantData?.theme?.global?.content?.social?.snapchat;
  const tiktok = restaurantData?.theme?.global?.content?.social?.tiktok;

  return (
    <>
      <footer
        id={`Footer`}
        className={styles.footer}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage:
            style?.backgroundImage !== ``
              ? `url(${style?.backgroundImage})`
              : ``,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`,
          borderRadius: `${content?.roundedCorners}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``,
            paddingRight: content?.contentInContainer
              ? `var(--contBxHPadding)`
              : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
            style={{
              flexDirection: content?.layout === 1 ? `row` : `row-reverse`
            }}
          >
            <div>
              <RenderIf isTrue={content?.socials}>
                <div className={styles.socials}>
                  <RenderIf isTrue={twitter !== ``}>
                    <a href={twitter} rel={`noreferrer`}>
                      <Icon
                        name={`twitter`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={discord !== ``}>
                    <a href={discord} rel={`noreferrer`}>
                      <Icon
                        name={`discord`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={instagram !== ``}>
                    <a href={instagram} rel={`noreferrer`}>
                      <Icon
                        name={`instagram`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={facebook !== ``}>
                    <a href={facebook} rel={`noreferrer`}>
                      <Icon
                        name={`facebook`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={snapchat !== ``}>
                    <a href={snapchat} rel={`noreferrer`}>
                      <Icon
                        name={`snapchat`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={tiktok !== ``}>
                    <a href={tiktok} rel={`noreferrer`}>
                      <Icon
                        name={`tiktok`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                </div>
              </RenderIf>
            </div>
            <div>
              <RenderIf isTrue={content?.showLogo}>
                <picture>
                  <source srcSet={restaurantData?.theme?.global?.content?.logo} />
                  <img
                    src={restaurantData?.theme?.global?.content?.logo}
                    alt={`Logo`}
                    style={{
                      width: content?.logoWidth
                        ? `${content?.logoWidth}px`
                        : `${restaurantData?.global?.content?.logoWidth}px`
                    }}
                  />
                </picture>
              </RenderIf>
            </div>
          </div>
          <div
            style={{
              width: `100%`,
              height: 1,
              background: style?.foregroundColor
                ? style?.foregroundColor
                : `rgba(0, 0, 0, 1)`,
              opacity: 0.16,
              margin: `16px auto`
            }}
          ></div>
          <div
            className={
              content?.layout === 1
                ? `${styles.barBx} ${styles.barBxLayout2}`
                : styles.barBx
            }
          >
            <div>
              <RenderIf isTrue={content?.copyright !== ``}>
                <p style={{ whiteSpace: `pre-line` }}>{content?.copyright}</p>
              </RenderIf>
            </div>
            <div>
              <RenderIf isTrue={content?.email !== ``}>
                <p>{content?.email}</p>
              </RenderIf>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

import { Controller, useForm } from 'react-hook-form';
import { Client } from 'db';
import { AppStore } from 'store';
import { TextField } from 'components/input';
import { PhoneField } from 'components/phone';
import { Toasts } from 'components/toasts';
import { PrimaryButton } from 'components/buttons/primary';
import { AuthPageStore } from 'store';
import { useState } from 'react';
import { RenderIf } from 'helpers';
import { Fetching } from 'components/loading';
import styles from './index.module.css';

export const ProfileOverview = () => {
  const { user, setUser } = AppStore((state) => state);
  const { page, setPage } = AuthPageStore((state) => state);
  const [loading, setLoading] = useState(false);
  // const [edit, setEdit] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    // watch
  } = useForm({
    defaultValues: {
      email: user?.email,
      // password: '',
      // fullName: user?.fullName,
      firstName: user?.fullName?.split(' : ')?.[0],
      lastName: user?.fullName?.split(' : ')?.[1],
      contactNumber: user?.contact?.[0]?.contactNumber,
      addressLine1: user?.address?.[0]?.addressLine1,
      addressLine2: user?.address?.[0]?.addressLine2,
      city: user?.address?.[0]?.city,
      State: user?.address?.[0]?.State,
      zipCode: user?.address?.[0]?.zipCode,
      // country: user?.address?.[0]?.country,
      // confirmPassword: ''
    }
  });

  const handleSubmitSignup = async (data) => {
    setLoading(true);
    try {
      let response = await Client.post('/auth/update', {
        // fullName: data?.firstName.concat(' ', data?.lastName),
        first_name: data?.firstName,
        last_name: data?.lastName,
        // password: data.password,
        email: data?.email,
        guest: false,
        contact: {
          contactNumber: data?.contactNumber,
          primary: true
        },
        address: {
          addressLine1: data?.addressLine1,
          addressLine2: data?.addressLine2,
          zipCode: data?.zipCode,
          city: data?.city,
          State: data?.State,
          // country: data.country,
          // geoLocation: {
          //   latitude: '123456',
          //   longitude: '123456'
          // },
          primary: true
        }
      });

      response = response?.data?.data;
      setUser(response);
      setPage('account');
      // window.location.reload();
      setLoading(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={`account_box2`}>
        <div className="mt_40"></div>
        {/* <div className={`flex item_hcenter`}>
          <button
            type={`button`}
            className={`button1 b_sm`}
            onClick={() => {
              setUser(null);
              localStorage.removeItem('user');
            }}
          >
            Logout{' '}
          </button>
        </div>
        <div className={`mt_10`}></div> */}
        <div className={`t_center`}>
          <h2 className={`t_up`}>Profile Page</h2>
        </div>
        <div className="mt_16"></div>
        <RenderIf isTrue={loading}>
          <div className={styles.loadingBx}>
            <div>
              <Fetching />
            </div>
          </div>
        </RenderIf>
        <RenderIf isTrue={!loading}>
          <form onSubmit={handleSubmit(handleSubmitSignup)}>
            <div className={`grid grid_gap40`}>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  required
                  error={
                    errors?.fullName?.type === 'required'
                      ? 'First Name is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: true
                  }}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  required
                  error={
                    errors?.fullName?.type === 'required'
                      ? 'Last Name is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: true
                  }}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Email Address"
                  placeholder="Email Address"
                  name="email"
                  required
                  error={
                    errors?.email?.type === 'required' ? 'Email is required' : ''
                  }
                  register={register}
                  validation={{
                    required: true
                  }}
                  readOnly
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <Controller
                  name="contactNumber"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <PhoneField
                        label="Phone Number"
                        placeholder={"Phone Number"}
                        field={field}
                        error={
                          errors?.contactNumber?.type === 'required'
                            ? 'Phone Number is required'
                            : ''
                        }
                        readOnly
                        defaultCountry={'US'}
                      />
                    );
                  }}
                />
                {/* <TextField
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="contactNumber"
                  required
                  error={
                    errors?.contactNumber?.type === 'required'
                      ? 'Phone Number is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: true
                  }}
                  readOnly
                /> */}
              </div>
              <div className={`collg12`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  name="addressLine1"
                  error={
                    errors?.addressLine1?.type === 'required'
                      ? 'Address Line 1 is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div>
              <div className={`collg12`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Address Line 2"
                  placeholder="Address Line 2 (Optional)"
                  name="addressLine2"
                  error={
                    errors?.addressLine2?.type === 'required'
                      ? 'Address Line 1 is required'
                      : ''
                  }
                  register={register}
                  validation={{}}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="City"
                  placeholder="City"
                  name="city"
                  error={
                    errors?.city?.type === 'required' ? 'City is required' : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="State"
                  placeholder="State"
                  name="State"
                  error={
                    errors?.State?.type === 'required' ? 'State is required' : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Zip Code"
                  placeholder="Zip Code"
                  name="zipCode"
                  error={
                    errors?.zipCode?.type === 'required'
                      ? 'Zip Code is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div>
              {/* <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Country"
                  placeholder="Country"
                  name="country"
                  error={
                    errors?.country?.type === 'required'
                      ? 'Country is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Password"
                  placeholder="Password"
                  name="password"
                  error={
                    errors?.password?.type === 'required'
                      ? 'Password is required'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div>
              <div className={`collg6`}>
                <div className={`mt_16`}></div>
                <TextField
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  error={
                    errors?.confirmPassword?.type === 'required'
                      ? 'Confirm Password is required'
                      : watch('password') !== watch('confirmPassword')
                      ? 'Password does not match'
                      : ''
                  }
                  register={register}
                  validation={{
                    required: false
                  }}
                />
              </div> */}
            </div>
            <div className="mt_40"></div>
            <div className="pt_10"></div>
            <div className={`flex item_hcenter`}>
              <PrimaryButton type={`submit`}>Save changes</PrimaryButton>
            </div>
            {/* <div className={`flex item_hcenter`}>
            {edit && (
              <button className={`button1 ${styles.b_submit}`} type="submit">
                Update
              </button>
            )}
            <button
              type={`button`}
              className={`button2 ${styles.b_submit}`}
              onClick={() => setEdit(!edit)}
            >
              {edit ? 'Cancel Update' : 'Edit Profile'}
            </button>
          </div> */}
          </form>
        </RenderIf>
        <div className="mt_40"></div>
      </div>
    </>
  );
};
